import React, {
  useContext,
  useState,
  useEffect,
  useCallback,
  useRef,
} from "react";
import { View, Keyboard, FlatList, Pressable, Dimensions, Platform } from "react-native";
import ThemeContext from "@presto-contexts/ThemeContext";
import utils from "../../utils/index";
import _ from "lodash";
import LoginHelper from "@presto-helpers/LoginHelper";
import UserContext from "@presto-contexts/UserContext";
import NavigatorContext from "@presto-contexts/NavigatorContext";
import SearchBar from "@presto-screen-components/SearchBar/SearchBar";
import { alertBox } from "@presto-common/Alert";
import AdvanceSearchBar from "@presto-screen-components/SalesReports/AdvanceSearchBar";
import ProductsTable from "@presto-screen-components/PurchaseRequests/ProductsTable.ziphealthPos.js";
import { useCategories } from "@presto-stores/CategoryStore";
import I18n from "i18n-js";
import SearchManager from "@presto-services/features/search/SearchManager";
import { useCatalog } from "@presto-stores/CatalogStore";
import { usePagination } from "@presto-hooks/pagination";
import PurchaseOrdersManager from "@presto-services/features/purchase_orders/PurchaseOrdersManager";
import MyPurchaseRequests from "@presto-screen-components/PurchaseRequests/MyPurchaseRequests";
import asyncify from "@presto-common/Asyncify";
import ItemPurchaseRequestTable from "@presto-screen-components/PurchaseRequests/ItemPurchaseRequestTable";
import EmployeeManager from "@presto-services/features/employee/EmployeeManager";
import PurchaseOrderHelper from "@presto-helpers/PurchaseOrderHelper";
import { AxiosError } from "axios";
import RNFetchBlob from "@presto-common/RNFetchBlob";
import ModalMobile from "@presto-components/Modals/Modal.bupos";
import PrestoText from "@presto-components/PrestoText";
import CategoryDataSources from "@presto-datasources/CategoryDataSources";
import AsyncStorage from "@react-native-community/async-storage";
import { prestoConfirm } from "../../common/Alert";

import {
  useGlobalStateStore,
  GlobalStoreKeys,
} from "@presto-stores/GlobalStateStore";
import {
  PrestoSolidButton,
  PrestoTextButton,
} from "@presto-components/PrestoButton";
import ItemCardModel from "@presto-component-models/ItemCardModel";
import DBCategoryItemManager from "@presto-db-managers/DBCategoryItemManager";

const AsyncEmployeeManager = asyncify(EmployeeManager);
const AsyncPurchaseOrdersManager = asyncify(PurchaseOrdersManager);
const AsyncSearchManager = asyncify(SearchManager);

export default function PurchaseRequests({
  sideNavigationRef,
  parentRef,
  tabs,
  setTabs,
  parentLoading,
  setParentLoading,
  getPropsForFloatingCart,
  componentProps,
  ...props
}) {
  const isWeb = Platform.OS == 'web'
  const windowWidth = Dimensions.get("window").width;
  let isTabAndAbove = windowWidth > 500 ? true : false;
  const { theme } = useContext(ThemeContext);
  const { user } = useContext(UserContext);
  const { Navigator } = useContext(NavigatorContext);
  const getAllProducts = useCategories((state) => state.getAllProducts);
  const categoryStore = useCategories();
  const merchantId = LoginHelper.getUserMerchantId(user);
  const catalog = useCatalog((state) => state.catalog);
  const globalStateStore = useGlobalStateStore();
  const [purchaseRequests, setPurchaseRequests] = useState([]);
  const [purchaseRequest, setPurchaseRequest] = useState(null);
  const [selectedItemIds, setSelectedItemIds] = useState([]);
  const [activeRow, setActiveRow] = useState(null);
  const [actionHandler, setActionHandler] = useState(null);
  const [itemPurchaseRequests, setItemPurchaseRequests] = useState([]);
  const [items, setItems] = useState([]);
  const [count, setCount] = useState(0);
  const [selectAll, setSelectAll] = useState(false);
  const [selectZip, setSelectZip] = useState(false);
  const [suppliers, setSuppliers] = useState([]);
  const [purchaseOrders, setPurchaseOrders] = useState([]);
  const [purchaseOrder, setPurchaseOrder] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [currentSortDirection, setCurrentSortDirection] = useState("ASC");
  const [sortField, setSortField] = useState("Name");
  const [zipItems, setZipItems] = useState([]);
  const [draftPos, setDraftPo] = useState([]);
  const styles = getStyles(theme);
  const isMobile = utils.isMobile();
  const isDesktop = utils.isDesktop();
  const isMobileWeb = utils.isMobileWeb();
  let promiseItem = null;
  const purchaseRequestRef = useRef(null);
  const [totalCount, setTotalCount] = useState(0);
  const [currentLoaded, setCurrentLoaded] = useState(0);
  const [
    selectedItemPurchaseRequests,
    setSelectedItemPurchaseRequests,
  ] = useState([]);
  const extraOptions = useRef({
    selectedItems: [],
    selectedItemPurchaseRequests: [],
    draftPo: null,
  });
  const [filterOptions, setFilterOptions] = useState({
    searchValue: "",
    tabId: "low_stock",
    ...props.filterOptions,
  });
  const fetchSuppliers = () => {
    const onSuccess = (response) => {
      setSuppliers(response.data);
    };

    const onError = (error) => {
      console.log("PurchaseOrders->fetchSuppliers->error", error);
    };

    const params = {};
    EmployeeManager.suppliers(params, onSuccess, onError);
  };

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    setParentLoading(load);
  }, [load]);

  useEffect(() => {
    if (
      filterOptions.tabId !== "my_requests" &&
      filterOptions !== "zip_orders"
    ) {
      loadFirstPage();
    }
  }, [filterOptions.tabId]);

  useEffect(() => {
    reSyncSelectedItems();
  }, [purchaseRequest]);

  useEffect(() => {
    if (actionHandler) {
      onActionHandle();
    }
  }, [actionHandler, filterOptions, getAllProducts, activeRow]);

  useEffect(() => {
    if (isMobile) {
      let price = 0;
      _.map(itemPurchaseRequests, (i) => {
        let totalPrice =
          parseFloat(i.product.price?.base_price) *
          i.itemPurchaseRequest?.purchase_quantity;
        price += totalPrice;
      });
      let count = itemPurchaseRequests?.length;
      if (getPropsForFloatingCart)
        getPropsForFloatingCart({ count, price, event: "modal" });
    }
  }, [itemPurchaseRequests]);

  const init = () => {
    parentRef.current.onPressTab = (tab) => {
      setActionHandler({ type: "ON_PRESS_TAB", tab });
    };
    parentRef.current.onChangeOfHeaderTabs = (id) => { };
    parentRef.current.onScreenPressEvent = () => {
      Keyboard.dismiss();
    };
    parentRef.current.onPressNetworkToggle = () => { };

    const tabStyles = {
      tabisActiveTextExtraStyles: {
        color: "#212123",
        paddingHorizontal: 10,
      },
    };

    setTabs([
      {
        id: "stocks",
        text: I18n.t("screen_messages.purchases.purchases_text"),
        isActive: true,
        defaultStyles: {
          backgroundColor: theme.primary,
        },
        tabisActiveStyles: {
          backgroundColor: theme.primary,
          minWidth: isMobile ? 100 : 180,
        },
        tabisActiveTextExtraStyles: {
          color: theme.white,
        },
      },
      ...[
        {
          id: "low_stock",
          text: I18n.t("screen_messages.stocks.low_stock"),
          isActive: true,
        },
        {
          id: "regular_purchases",
          text: I18n.t("screen_messages.purchases.regular_purchase"),
          defaultStyles: {
            paddingHorizontal: isMobile ? 5 : 10,
            marginRight: 1,
          },
        },
        {
          id: "all_items",
          text: I18n.t("screen_messages.orders.all_items"),
          defaultStyles: {
            paddingHorizontal: isMobile ? 5 : 10,
            marginRight: 1,
          },
        },
        {
          id: "my_requests",
          text: I18n.t("screen_messages.purchases.shopping_list"),
          defaultStyles: {
            paddingHorizontal: 10,
            marginRight: 1,
          },
        },
        {
          id: "zip_orders",
          text: I18n.t("screen_messages.purchases.sent_to_zip"),
          defaultStyles: {
            paddingHorizontal: 10,
            marginRight: 1,
          },
        },
      ].map((item) => {
        return {
          ...item,
          ...tabStyles,
        };
      }),
    ]);
    fetchSuppliers();
    getOrCreateAndSetPendingDraftPo();
  };

  const hasPermissionToAccess = (key) =>
    _.get(user, `other_data.permissions.${key}`) === true;

  const hasPurchaseOrderManagementPermission = hasPermissionToAccess(
    "purchase_order_management"
  );

  const fetchMyPurchaseRequests = () => {
    purchaseOrderObject.list().then((list) => {
      setPurchaseRequests(list);
    });
  };

  const getOrCreateAndSetPendingDraftPo = (showLoader = true) => {
    if (showLoader) setParentLoading(true);
    const draftPo = new PurchaseOrderHelper.DraftPo();
    draftPo.initiatedPo().then(async (obj) => {
      if (_.has(obj, "error")) {
        setParentLoading(false);
        let message =
          error instanceof AxiosError
            ? _.get(error, "response.data.message")
            : error.message;
        return alertBox("", message);
      }
      extraOptions.current.draftPo = !_.isEmpty(obj)
        ? obj
        : await draftPo.create();

      setParentLoading(false);
    });
  };

  const onActionHandle = () => {
    if (actionHandler.type === "LOAD_PAGE") {
      loadFirstPage();
      setActionHandler(null);
    }
    if (actionHandler.type === "NAVIGATE_TO_INTIAL_PURCHASE_REQUEST_DETAIL") {
      const query = {
        initial: getPurchaseRequestDetailInitial(),
        parentLoading,
        setParentLoading,
        onUpdateItem: onItemPurchaseRequestUpdate,
        onRemoveItem: onItemPurchaseRequestUpdateRemove,
        resetPurchaseRequest: resetPurchaseRequest,
        enableDownloadPO: purchaseOrderObject.canDownloadPo(purchaseRequest),
        reloadSideNav: () => {
          setActionHandler({
            type: "NAVIGATE_TO_INTIAL_PURCHASE_REQUEST_DETAIL",
          });
        },
        onSubmitCallback: () => {
          extraOptions.current.selectedItems = [];
          setActiveRow(null);
          if (isMobile) {
            Navigator.emit({ event: "goBack" });
          }
          if (filterOptions.tabId === "my_requests") {
            fetchPurchaseRequestItems();
          }
        },
        onGetTotalPrice: (poItem) => {
          let item = poItem.item;
          let itemPurchaseConfiguration = item.itemPurchaseConfiguration;
          let purchasePrice = Number(
            _.get(item, "supplierPrice.purchase_price", 0)
          );
          let price = purchasePrice * item.quantity;

          if (
            hasPurchaseOrderManagementPermission &&
            itemPurchaseConfiguration
          ) {
            const ratio = itemPurchaseConfiguration.sale_to_purchase_ratio;
            price = (purchasePrice * item.quantity) / ratio;
          }

          return purchasePrice > 0 ? utils.formattedCurrency(price) : "";
        },
      };

      if (
        filterOptions.tabId === "my_requests" &&
        hasPermissionToAccess("purchase_order_management")
      ) {
        query.onSubmitButtonText = I18n.t(
          "screen_messages.purchase_orders.create_po"
        );
        query.onSubmit = async () => {
          let proxyPo = getPurchaseRequestDetailInitial();
          if (proxyPo.items.length) {
            const draftPo = new PurchaseOrderHelper.DraftPo(
              extraOptions.current.draftPo
            );

            setParentLoading(true);

            const result =
              extraOptions.current.draftPo || (await draftPo.create());

            if (result instanceof Error) {
              alertBox("", result.message);
            } else {
              extraOptions.current.draftPo = result;

              let responses = await draftPo.approveItemPurchases(proxyPo.items);
              let errors = _.filter(responses, (e) => _.has(e, "error"));
              setParentLoading(false);
              if (errors.length) {
                return alertBox("", _.get(errors, `0.message`));
              }

              let response = await draftPo.markAsConfirmed();

              if (_.has(response, "error")) {
                return alertBox("", response.message);
              }

              getOrCreateAndSetPendingDraftPo();

              extraOptions.current.selectedItems = [];
              fetchPurchaseRequestItems().then(() => {
                setActionHandler({
                  type: "NAVIGATE_TO_INTIAL_PURCHASE_REQUEST_DETAIL",
                });
              });
            }
          }
        };
      }
      setActionHandler(null);
      if (isMobile) return;
      setTimeout(() => {
        sideNavigationRef.navigate("InitialPurchaseRequestDetail", query);
      }, 300);
    }

    if (actionHandler.type === "ON_PRESS_TAB") {
      onPressTab(actionHandler.tab);
      setActionHandler(null);
    }
  };

  const onPressRow = (query) => {
    Navigator.emit({
      event: "modal",
      params: {
        screenName: "SIDE_NAVIGATION",
        screenParams: {
          forwardProps: componentProps,
          closeModal: () => {
            Navigator.emit({
              event: "goBack",
            });
          },
        },
      },
    });
  };

  const reSyncSelectedItems = () => {
    const items = purchaseRequest
      ? _.map(purchaseRequest.items, (item) => item.item_id)
      : [];

    if (purchaseRequest) {
      purchaseRequestRef.current = purchaseRequest;
    }

    let temp = _.uniq(items);
    setSelectedItemIds(temp);
  };

  const onPressTab = (tab) => {
    if (tab.id === "stocks") {
      return;
    }

    let tempTabs = [];
    setTabs((prev) => {
      tempTabs = _.cloneDeep(prev);
      return prev;
    });

    tempTabs = _.map(tempTabs, (tabItem) => {
      let ifMainAndSelectedTab = _.includes(["stocks", tab.id], tabItem.id);
      let ifOnlyMainTabThanFallback =
        tab.id === "sales" && tabItem.id === "today";
      let isActive = ifMainAndSelectedTab || ifOnlyMainTabThanFallback;
      return {
        ...tabItem,
        isActive,
      };
    });

    setTabs(tempTabs);
    afterPressTab(tab);
  };

  const afterPressTab = (tab) => {
    const id = tab.id;
    setFilterOptions({
      ...filterOptions,
      tabId: id,
    });

    setActionHandler({ type: "LOAD_PAGE" });
    fetchPurchaseRequestItems();
    const isEnabled = false;
    if (isEnabled) {
      resetSideNavigationOnTabPress(tab);
    }
  };

  const resetSideNavigationOnTabPress = (tab) => {
    const prevTab = _.find(tabs, (e) => e.isActive && e.id !== "stocks");
    if (prevTab.id === "my_requests") {
      sideNavigationRef.navigate("InitialPurchaseRequestDetail", {
        initial: null,
        parentLoading,
        setParentLoading,
      });
      setSelectedItemIds([]);
      setActiveRow(null);
    }
  };

  const doAdvanceSearch = () => {
    Keyboard.dismiss();
    loadFirstPage();
  };

  const searchPage = async (pageNumber, success, failure) => {
    console.log("coming here ", pageNumber);
    let currentSortDirection = globalStateStore.getKey("SORT_DIRECTION");
    let text = filterOptions.searchValue?.replace(/\//g, "//");
    let params = {
      category_id: "",
      name_asc: true,
      page: pageNumber,
      //catalog_id: catalog?.id,
      search_string: text,
      size: 25,
      or_reference_id: text,
      or_therapy_area_simple: text,
      or_molecule: text,
      or_company_name: text,
      enableOffline: !isWeb,
      sortOrder: currentSortDirection,
    };

    if (filterOptions.tabId === "low_stock") {
      params.stockCountRange = {
        lte: 10,
      };
    }

    if (filterOptions.tabId === "regular_purchases") {
      let itemIds = await AsyncStorage.getItem("ITEM_IDS");
      if (!itemIds) {
        itemIds = "[]";
      }
      params.ids = JSON.parse(itemIds);
      if (params.ids.length == 0) {
        setTotalCount(0);
        setCurrentLoaded(0);
        success([]);
        return;
      }
    }

    let sortField = globalStateStore.getKey("SORT_FIELD");
    if (currentSortDirection == "DESC" || currentSortDirection == "DESC") {
      if (sortField == "Name") {
        params.name_desc = true;
      } else if (sortField == "Zip") {
        params.zip_desc = true;
      } else if (sortField == "Therapy") {
        params.therapy_desc = true;
      } else if (sortField == "Manufacturer") {
        params.company_name_desc = true;
      } else if (sortField == "Price") {
        params.price_high = true;
      } else if (sortField == "Expiry") {
        params.expiry_desc = true;
      } else if (sortField == "Avgpp") {
        params.avg_pp_desc = true;
      } else if (sortField == "Margin") {
        params.margin_desc = true;
      } else if (sortField == "LastAudit") {
        params.last_audit_desc = true;
      } else if (sortField == "" || sortField == "Stock") {
        params.stock_count_desc = true;
      }
    } else {
      if (sortField == "Name") {
        params.name_asc = true;
      } else if (sortField == "Zip") {
        params.zip_asc = true;
      } else if (sortField == "Therapy") {
        params.therapy_asc = true;
      } else if (sortField == "Manufacturer") {
        params.company_name_asc = true;
      } else if (sortField == "Price") {
        params.price_low = true;
      } else if (sortField == "Expiry") {
        params.expiry_asc = true;
      } else if (sortField == "Avgpp") {
        params.avg_pp_asc = true;
      } else if (sortField == "Margin") {
        params.margin_asc = true;
      } else if (sortField == "LastAudit") {
        params.last_audit_asc = true;
      } else if (sortField == "" || sortField == "Stock") {
        params.stock_count_asc = true;
      }
    }

    CategoryDataSources.fuzzySearchCategoryItem(
      params,
      async (response) => {
        setTotalCount(response.hits?.total?.value !== undefined ? response.hits?.total?.value : response.hits?.total !== undefined ? response.hits?.total : 0 || 0);

        let array = _.map(response.hits.hits, (item) => item?._source);
        if (filterOptions.tabId === "low_stock") {
          //array = _.filter(array, (e) => e.stock_count <= 10);
        }
        setCurrentLoaded(array.length + (pageNumber - 1) * 25);
        let itemPurchaseData = await AsyncStorage.getItem("ITEM_PURCHASE_LIST") || "[]";
        if (!itemPurchaseData) {
          itemPurchaseData = "[]";
        }
        console.log(await AsyncStorage.getItem("ITEM_PURCHASE_LIST"), itemPurchaseData, "response.hits?.totaladsifjsoidfjiajfjkls")
        let itemPurchases = JSON.parse(itemPurchaseData);
        // console.log("Item purchases ==== ");
        // console.log(itemPurchaseData);

        array.forEach((arrayItem) => {
          arrayItem.itemPurchaseConfiguration = {
            sale_unit_display_name: arrayItem.other_data["sale unit"],
            purchase_unit_display_name: arrayItem.other_data["purchase unit"],
            sale_to_purchase_ratio: parseInt(
              arrayItem.other_data["sale to purchase ratio"]
            ),
          };
          if (
            arrayItem.other_data.zip_supplied == "1" ||
            arrayItem.other_data.zip_supplied == 1
          ) {
            arrayItem.itemPurchaseConfiguration.supplier_prices = [
              {
                name: "Zip",
              },
            ];
          } else {
            arrayItem.itemPurchaseConfiguration.supplier_prices = [
              {
                name: "Others",
              },
            ];
          }
          _.forEach(itemPurchases, (itemPurchaseRequest) => {
            if (itemPurchaseRequest.category_item_id == arrayItem.id) {
              arrayItem.itemPurchaseRequest = itemPurchaseRequest;
            }
          });
        });
        console.log(array[0]);
        success(array);
        return;
        let completed = 0;
        let itemIds = [];
        array.forEach((arrayItem) => {
          /*getItemPurchaseConfiguration(arrayItem, true).then(
            (itemPurchaseConfiguration) => {
              completed = completed + 1;
              arrayItem.itemPurchaseConfiguration = itemPurchaseConfiguration;
              if (completed == array.length) {
                success(array);
              }
            }
          );*/
          itemIds.push(arrayItem.id);
        });

        getItemListPurchaseConfiguration(itemIds, true).then(
          (itemListPurchaseConfiguration) => {
            array.forEach((arrayItem) => {
              arrayItem.itemPurchaseConfiguration =
                itemListPurchaseConfiguration[arrayItem.id];
            });
            getItemListPurchaseRequests(itemIds, true).then(
              (itemListPurchaseRequestConfiguration) => {
                console.log("REEEE");
                console.log(itemListPurchaseRequestConfiguration);
                array.forEach((arrayItem) => {
                  itemListPurchaseRequestConfiguration.forEach((itemPR) => {
                    if (itemPR.category_item_id == arrayItem.id) {
                      arrayItem.itemPurchaseRequest = itemPR;
                    }
                  });
                });
                success(array);
              }
            );
            success(array);
          }
        );
        //success(array);
      },
      (error) => {
        failure(error);
      }
    );
  };

  const getPurchaseOrderId = () => {
    return purchaseRequestRef.current?.id;
  };

  function PurchaseOrderObject() {
    const Manager = PurchaseOrdersManager;
    const AsyncManager = AsyncPurchaseOrdersManager;

    this.list = function () {
      const params = {
        purchase_orders: {
          type: "DRAFT",
        },
      };
      return AsyncManager.list(params)
        .then(([error, response]) => {
          if (error) {
            throw error;
          }

          const list = response.data.purchase_orders;
          return list;
        })
        .catch((error) => {
          console.log("PurchaseOrderObject->list->error", error);
          console.log(JSON.stringify(error));
          console.log("-----");
          alertBox(error.message);
        });
    };

    this.create = function () {
      const params = {
        purchase_order: {
          instructions: "",
          other_data: {},
        },
      };
      return AsyncManager.create(params)
        .then(([error, response]) => {
          if (error) {
            throw error;
          }

          const obj = response.data;
          purchaseRequestRef.current = obj;
          setPurchaseRequest(obj);
          return obj;
        })
        .catch((error) => {
          console.log("PurchaseOrderObject->create->error", error);
          alertBox(error.message);
        });
    };

    this.addItem = function (item, quantity) {
      const params = {
        id: getPurchaseOrderId(),
        purchase_order: {
          item_id: item.id,
          quantity: quantity,
        },
      };

      return AsyncManager.addItem(params)
        .then(([error, response]) => {
          if (error) {
            throw error;
          }
          const obj = response.data;
          purchaseRequestRef.current = obj;
          setPurchaseRequest(obj);
          return obj;
        })
        .catch((error) => {
          console.log("PurchaseOrderObject->add->error", error);
          throw error;
        });
    };
    this.removeItem = function (item) {
      const params = {
        id: getPurchaseOrderId(),
        purchase_order: {
          line_item_id: item.id,
        },
      };

      return AsyncManager.removeItem(params)
        .then(([error, response]) => {
          if (error) {
            throw error;
          }
          const obj = response.data;
          purchaseRequestRef.current = obj;
          setPurchaseRequest(obj);
          return obj;
        })
        .catch((error) => {
          console.log("PurchaseOrderObject->removeItem->error", error);
          alertBox(error.message);
          return error;
        });
    };
    this.updateItem = function (item, quantity) {
      const params = {
        id: getPurchaseOrderId(),
        purchase_order: {
          line_item_id: item.id,
          quantity: quantity,
        },
      };

      return AsyncManager.updateItem(params)
        .then(([error, response]) => {
          if (error) {
            throw error;
          }
          const obj = response.data;
          purchaseRequestRef.current = obj;
          setPurchaseRequest(obj);
          return obj;
        })
        .catch((error) => {
          console.log("PurchaseOrderObject->updateItem->error", error);
          alertBox(error.message);
          return error;
        });
    };
    this.exists = function () {
      return !_.isEmpty(purchaseRequest);
    };
    this.reset = function () {
      purchaseRequestRef.current = null;
      setPurchaseRequest(null);
    };
    this.isMarkedForApproval = function (po) {
      return _.get(po, "status") === "AWAITING_APPROVAL";
    };
    this.isMarkedForAsApproved = function (po) {
      return _.get(po, "status") === "APPROVED";
    };
    this.canDownloadPo = function (po) {
      return (
        _.includes(["APPROVED", "DELIVERED", "CLOSED"], _.get(po, "status")) &&
        po.type === "CONFIRMED"
      );
    };

    this.listApproved = () => {
      const params = {
        purchase_orders: {
          status: "APPROVED",
        },
      };
      return AsyncManager.list(params)
        .then(([error, response]) => {
          if (error) {
            throw error;
          }

          const list = response.data.purchase_orders;
          return list;
        })
        .catch((error) => {
          console.log("PurchaseOrderObject->list->error", error);
          alertBox(error.message);
          console.log(JSON.stringify(error));
        });
    };

    this.listDelivered = () => {
      return this.list({ status: "DELIVERED" });
    };

    this.listClosed = () => {
      return this.list({ status: "CLOSED" });
    };

    this.listRejected = () => {
      return this.list({ status: "REJECTED" });
    };
  }

  const getItemPurchaseConfiguration = (item, doNotShowAlert) => {
    return AsyncEmployeeManager.itemPurchaseConfigurationShow({
      id: item.id,
    }).then(([error, response]) => {
      if (error) {
        let message =
          error instanceof AxiosError
            ? _.get(error, "response.data.message")
            : error.message;
        if (!doNotShowAlert) {
          alertBox("", message);
        }
        return null;
      }
      return _.get(response, "data");
    });
  };

  const getItemListPurchaseRequests = (
    itemIds,
    doNotShowAlert,
    isForItems = false
  ) => {
    return AsyncEmployeeManager.itemPurchaseRequestList({
      item_ids: itemIds.join(","),
      forItems: isForItems,
    }).then(([error, response]) => {
      if (error) {
        let message =
          error instanceof AxiosError
            ? _.get(error, "response.data.message")
            : error.message;
        if (!doNotShowAlert) {
          alertBox("", message);
        }
        console.log(message);
        return null;
      }
      return _.get(response, "data");
    });
  };

  const getItemListPurchaseConfiguration = (itemIds, doNotShowAlert) => {
    return AsyncEmployeeManager.itemPurchaseConfigurationList({
      item_ids: itemIds.join(","),
    }).then(([error, response]) => {
      if (error) {
        let message =
          error instanceof AxiosError
            ? _.get(error, "response.data.message")
            : error.message;
        if (!doNotShowAlert) {
          alertBox("", message);
        }
        console.log(message);
        return null;
      }
      console.log(response);
      return _.get(response, "data");
    });
  };

  const pushToSelectedItemAndNavigate = (item, isAddedToBucket) => {
    let temp = _.uniq([...selectedItemIds, item.product.id]);
    if (isAddedToBucket) {
      temp = _.filter(temp, (e) => e !== item.product.id);
    }
    setSelectedItemIds(temp);

    let exists = _.some(
      extraOptions.current.selectedItems,
      (e) => e.product.id === item.product.id
    );

    if (exists) {
      extraOptions.current.selectedItems = _.filter(
        extraOptions.current.selectedItems,
        (selectedItem) => selectedItem.product.id != item.product.id
      );
    } else {
      extraOptions.current.selectedItems.push(item);
    }
    if (isMobile) {
      Navigator.emit({ event: "goBack" });
      return;
    } else {
      setActionHandler({
        type: "NAVIGATE_TO_INTIAL_PURCHASE_REQUEST_DETAIL",
      });
    }
  };

  const onPressActionButton1 = ({ item }) => {
    let isAddedToBucket = _.some(selectedItemIds, (e) => e === item.product.id);
    if (!isAddedToBucket) {
      setParentLoading(true);
      getItemPurchaseConfiguration(item.product)
        .then((itemPurchaseConfiguration) => {
          setParentLoading(false);
          if (itemPurchaseConfiguration) {
            if (!_.isEmpty(itemPurchaseConfiguration?.supplier_prices)) {
              return itemPurchaseConfiguration;
            } else {
              const error = new Error(
                I18n.t("screen_messages.supplier_prices.not_specified")
              );
              throw error;
            }
          }
        })
        .then((itemPurchaseConfiguration) => {
          if (_.isEmpty(itemPurchaseConfiguration)) {
            return {
              itemPurchaseConfiguration: null,
              itemPurchaseRequest: null,
            };
          }

          let params = {
            category_item_id: itemPurchaseConfiguration.category_item_id,
          };
          return AsyncEmployeeManager.itemPurchaseRequests(params).then(
            ([error, response]) => {
              if (error) {
                let message =
                  error instanceof AxiosError
                    ? _.get(error, "response.data.message")
                    : error.message;

                const error = new Error(message);
                throw error;
              }

              return {
                itemPurchaseConfiguration,
                itemPurchaseRequest: _.first(response.data),
              };
            }
          );
        })
        .then(({ itemPurchaseConfiguration, itemPurchaseRequest }) => {
          if (itemPurchaseConfiguration) {
            item = {
              ...item,
              itemPurchaseConfiguration,
            };
            if (itemPurchaseRequest) {
              item = {
                ...item,
                itemPurchaseRequest,
              };
            }

            pushToSelectedItemAndNavigate(item, isAddedToBucket);
          }
        })
        .catch((error) => {
          alertBox("", error.message);
        });
    } else {
      pushToSelectedItemAndNavigate(item, isAddedToBucket);
    }

    let enableAddToPO = false;

    if (enableAddToPO) {
      const quantity = 1;
      const exists = purchaseOrderObject.exists();
      setParentLoading(true);
      if (exists) {
        if (isAddedToBucket) {
          const lineItem = _.find(purchaseRequestRef.current.items, [
            "item_id",
            item.id,
          ]);
          purchaseOrderObject.removeItem(lineItem).then((obj) => {
            setParentLoading(false);
            setActionHandler({
              type: "NAVIGATE_TO_INTIAL_PURCHASE_REQUEST_DETAIL",
            });
            setActiveRow(null);
          });
        } else {
          purchaseOrderObject
            .addItem(item, quantity)
            .then((obj) => {
              setActionHandler({
                type: "NAVIGATE_TO_INTIAL_PURCHASE_REQUEST_DETAIL",
              });
              setActiveRow(null);
            })
            .catch((error) => {
              alertBox("", error.message);
              const tempItems = _.filter(selectedItemIds, (e) => e !== item.id);
              setSelectedItemIds(tempItems);
            })
            .then(() => {
              setParentLoading(false);
            });
        }
      } else {
        purchaseOrderObject
          .create()
          .then((po) => {
            return purchaseOrderObject.addItem(item, quantity);
          })
          .then((addedItem) => {
            setParentLoading(false);
            setActionHandler({
              type: "NAVIGATE_TO_INTIAL_PURCHASE_REQUEST_DETAIL",
            });
            setActiveRow(null);
          });
      }
    }
  };

  const addItemToPurchaseRequest = async (
    { item, purchase_quantity },
    showLoader = true
  ) => {
    // if (showLoader)
    setParentLoading(true);
    let itemPurchaseData = await AsyncStorage.getItem("ITEM_PURCHASE_LIST");
    if (!itemPurchaseData) {
      itemPurchaseData = "[]";
    }
    let itemPurchases = JSON.parse(itemPurchaseData);
    let itemPurchaseRequest = {
      purchase_quantity: item.purchase_quantity,
      quantity:
        parseInt(item.purchase_quantity) *
        parseInt(item.itemPurchaseConfiguration.sale_to_purchase_ratio),
      category_item_id: item.id,
    };
    let mIndex = -1;
    _.forEach(itemPurchases, (ipr, index) => {
      if (ipr.category_item_id == itemPurchaseRequest.category_item_id) {
        mIndex = index;
      }
    });
    if (mIndex == -1 && item.purchase_quantity > 0) {
      itemPurchases.push(itemPurchaseRequest);
      item.itemPurchaseRequest = itemPurchaseRequest;
    } else if (item.purchase_quantity > 0) {
      itemPurchases[mIndex] = itemPurchaseRequest;
      item.itemPurchaseRequest = itemPurchaseRequest;
    } else {
      itemPurchases.splice(mIndex, 1);
      item.itemPurchaseRequest = null;
    }
    await AsyncStorage.setItem(
      "ITEM_PURCHASE_LIST",
      JSON.stringify(itemPurchases)
    );
    if (isMobile) fetchPurchaseRequestItems();
    setParentLoading(false);
    if (mIndex == -1 && item.purchase_quantity > 0) {
      addItemToPurchaseRequestViaNetwork(
        {
          item: item,
          purchase_quantity: itemPurchaseRequest.purchase_quantity,
          showLoader: showLoader,
          itemPurchases: itemPurchases,
        },
        {
          callback: () => {
            draftPoAndAIP(item, showLoader);
          },
        }
      );
    }
  };

  const clearItemPurchaseRequests = async () => {
    prestoConfirm(
      I18n.t("screen_messages.common.confirm"),
      I18n.t("screen_messages.orders.sure_text"),
      [
        {
          text: I18n.t("screen_messages.common.yes_text"),
          onPress: async () => {
            setParentLoading(true);
            let itemPurchaseData = await AsyncStorage.getItem(
              "ITEM_PURCHASE_LIST"
            );
            itemPurchaseData = "[]";
            let itemPurchases = JSON.parse(itemPurchaseData);
            await AsyncStorage.setItem(
              "ITEM_PURCHASE_LIST",
              JSON.stringify(itemPurchases)
            );
            setItemPurchaseRequests([]);
            setParentLoading(false);
          },
        },
        {
          text: I18n.t("screen_messages.common.no_text"),
        },
      ]
    );
  };

  const getDraftOrder = async (success) => {
    await AsyncPurchaseOrdersManager.listDraftPo({ status: "INITIATED" }).then(
      (response) => {
        setDraftPo(response?.[1].data);
        if (!extraOptions?.current?.draftPo?.id) {
          extraOptions.current.draftPo = response[1].data[0];
        }
      }
    );
  };
  useEffect(() => {
    getDraftOrder();
  }, []);

  const addAllItemsToPurchaseRequest = async (
    { selectedItemPurchaseRequests, soldOutItems },
    { callback }
  ) => {
    let itemPurchases = selectedItemPurchaseRequests;
    let totalCount = itemPurchases.length;
    console.log("Total Count:ddd " + totalCount);
    let completed = 0;
    if (!extraOptions?.current?.draftPo?.id) {
      console.log("There are not draft so need to create");
      const draftPo = new PurchaseOrderHelper.DraftPo(
        extraOptions.current.draftPo
      );
      let result = undefined;
      AsyncPurchaseOrdersManager.listDraftPo({ status: "INITIATED" }).then(
        async (res) => {
          if (!res[1].data.length) {
            result = await draftPo.create();
          }
          if (result instanceof Error) {
            console.log(result);
            console.log("----ERR----");
          } else {
            if (result[1].data.length) {
              extraOptions.current.draftPo = result[1].data[0];
            }
          }
        }
      );
    }

    let ids = [];
    _.forEach(itemPurchases, (ipr, index) => {
      ids.push(ipr?.product?.id);
    });

    let notInStocks = [];
    _.forEach(soldOutItems, (ipr, index) => {
      if (!ipr?.product?.stock_out) {
        notInStocks.push(ipr?.product?.id);
      }
    });

    let idsNonAip = [];
    const approvedParams = {
      draft_purchase_order_id: extraOptions.current.draftPo.id,
    };
    await AsyncEmployeeManager.getApprovedItemPurchaseRequest(
      approvedParams
    ).then(([err, res]) => {
      _.forEach(ids, (ele) => {
        if (!res.data.some((item) => item.category_item_id === ele)) {
          idsNonAip.push(ele);
        }
      });

      _.forEach(notInStocks, async (ipr) => {
        console.log("remove aip if no stocks");
        let notInStocksItem = res.data.find(
          (item) => item.category_item_id === ipr
        );
        if (notInStocksItem?.id) {
          await AsyncEmployeeManager.deleteApprovedItemPurchaseRequest({
            id: notInStocksItem.id,
          })
            .then(([error, response]) => {
              setParentLoading(false);
              getDraftOrder();
            })
            .then((obj) => {
              setParentLoading(false);
            });
        }
      });
    });

    if (idsNonAip.length) {
      getItemListPurchaseRequests(idsNonAip, true, true).then((response) => {
        let allHasPurchaseRequests = true;
        let allHasApprovedRequests = true;
        _.forEach(idsNonAip, async (ele, index) => {
          if (response[ele] === undefined) {
            allHasPurchaseRequests = false;
            console.log("No IRP need to create");
            let addPurchases = itemPurchases.find(
              (item) => item.product.id === ele
            );
            if (addPurchases) {
              allHasApprovedRequests = false;
              addItemToPurchaseRequestViaNetwork(
                {
                  item: addPurchases,
                  purchase_quantity: addPurchases.purchase_quantity,
                  showLoader: true,
                  itemPurchases: itemPurchases,
                },
                {
                  callback: () => {
                    draftPoAndAIP(
                      addPurchases,
                      true,
                      index == idsNonAip.length - 1
                    );
                  },
                }
              );
            }
          } else {
            console.log(addPurchases, "item has IPR but no AIP");
            allHasApprovedRequests = false;
            let addPurchases = itemPurchases.find(
              (item) => item.product.id === ele
            );
            console.log(
              "addPurchase has itemPurchaseRequest or not, if not fetch it and assigne it to local"
            );
            if (!addPurchases?.itemPurchaseRequest?.id) {
              let allItemPurchases = await AsyncStorage.getItem(
                "ITEM_PURCHASE_LIST"
              );
              _.forEach(allItemPurchases, (ipr, index) => {
                if (ipr.category_item_id == response[ele].category_item_id) {
                  ipr.id = response.data.id;
                }
              });
              await AsyncStorage.setItem(
                "ITEM_PURCHASE_LIST",
                JSON.stringify(allItemPurchases)
              );
            }
            draftPoAndAIP(addPurchases, true, index == idsNonAip.length - 1);
          }
          // if (index == idsNonAip.length - 1) {
          //   console.log(index, idsNonAip.length, "all has item purchase id")
          //   callback(true)
          // }
        });
        // if (allHasPurchaseRequests) {
        // console.log("all has item purchase id")
        // callback(true)
        // }
      });
    } else {
      console.log("every thing is up to date");
      callback(true);
    }

    // getItemListPurchaseRequests(ids, true, true).then((response) => {
    //   let allHasPurchaseRequests = true;
    //   _.forEach(ids, (ele) => {
    //     if (response[ele] === undefined) {
    //       allHasPurchaseRequests = false
    //       console.log(response, "lkjasfdkjajksdf;jkwe^^^^^^^^^^^^^^^^^^");
    //       let addPurchases = itemPurchases.find((item) => item.product.id === ele);
    //       console.log(addPurchases, "hyy my logic is workingksjfkasdjfk")
    //       if (addPurchases) {
    //         console.log("hyy my logic is workingksjfkasdjfk")
    //         addItemToPurchaseRequestViaNetwork({ item: addPurchases }, { callback: () => { callback(false) } });
    //       }
    //     }
    //   })
    //   if (allHasPurchaseRequests) {
    //     callback(allHasPurchaseRequests)
    //   }

    //   // _.forEach(response, (ele) => {
    //   //   if (ids.includes(ele.category_item_id)) {
    //   //     console.log(response, "lkjasfdkjajksdf;jkwe^^^^^^^^^^^^^^^^^^")
    //   //   }
    //   // })
    // })

    // _.forEach(itemPurchases, (ipr, index) => {
    //   let params = {
    //     item_purchase_request: {
    //       quantity: ipr.itemPurchaseRequest.quantity,
    //       category_item_id: ipr.itemPurchaseRequest.category_item_id,
    //     },
    //   };
    //   AsyncEmployeeManager.itemPurchaseRequestCreate(params).then(
    //     async ([error, response]) => {
    //       let itemIds = await AsyncStorage.getItem("ZIP_ITEM_IDS");
    //       if (!itemIds || itemIds == null || itemIds == "null") {
    //         itemIds = "[]";
    //       }
    //       console.log(itemIds);
    //       console.log("====ITEMIDS====");
    //       let ids = JSON.parse(itemIds);
    //       if (ids.indexOf(ipr.itemPurchaseRequest.category_item_id) == -1) {
    //         ids.push(ipr.itemPurchaseRequest.category_item_id);
    //         await AsyncStorage.setItem("ZIP_ITEM_IDS", JSON.stringify(ids));
    //       }
    //       console.log(ids);
    //       console.log("===ZIP====");
    //       let tempItems = extraOptions.current.selectedItems;
    //       _.forEach(tempItems, (item, index) => {
    //         if (
    //           !item.sold_out &&
    //           item.product.id == ipr.itemPurchaseRequest.category_item_id
    //         ) {
    //           item.itemPurchaseRequest = response?.data;
    //         }
    //       });
    //       completed = completed + 1;
    //       if (totalCount == completed) {
    //         callback();
    //       }
    //     }
    //   );
    // });
  };

  const addItemToPurchaseRequestViaNetwork = (
    { item, purchase_quantity, showLoader = true, itemPurchases },
    { callback }
  ) => {
    //Add to draft po.
    if (showLoader) setParentLoading(true);
    let params = {
      item_purchase_request: {
        quantity:
          item.purchase_quantity ||
          item.itemPurchaseRequest.purchase_quantity *
          item.itemPurchaseConfiguration.sale_to_purchase_ratio,
        category_item_id: item.id || item.itemPurchaseRequest.category_item_id,
      },
    };
    const approvedParams = {
      category_item_id: item.id,
      draft_purchase_order_id: extraOptions.current.draftPo.id,
    };

    AsyncEmployeeManager.getApprovedItemPurchaseRequest(approvedParams).then((response, error) => {
      console.log(response[1].data, "checking if it has aip or not if it has update")
      if (response[1].data.length === 0) {
        AsyncEmployeeManager.itemPurchaseRequestCreate(params)
          .then(async ([error, response]) => {
            console.log(response);
            console.log(error);
            console.log("IPR");
            item.itemPurchaseRequest = response?.data;
            setParentLoading(false);
            callback();
            _.forEach(itemPurchases, async (ipr, index) => {
              if (ipr.category_item_id == response.data.category_item_id) {
                ipr.id = response.data.id;
              }
            });
            await AsyncStorage.setItem(
              "ITEM_PURCHASE_LIST",
              JSON.stringify(itemPurchases)
            );
            // await AsyncStorage.setItem("ZIP_ITEM_IDS", JSON.stringify(ids));
          })
          .then((obj) => {
            setParentLoading(false);
          });
      } else {
        let updateType = AsyncEmployeeManager.itemPurchaseRequestUpdate;
        let deleteType = AsyncEmployeeManager.itemPurchaseRequestDelete;
        params.id = response[1].id;
        updatePurchaseType(
          showLoader,
          item,
          deleteType,
          updateType,
          params,
          callback
        );
      }
    })
  };

  const updateItemToPurchaseRequest = async (
    { item, purchase_quantity },
    showLoader = true
  ) => {
    if (showLoader) setParentLoading(true);
    let itemPurchaseData = await AsyncStorage.getItem("ITEM_PURCHASE_LIST");
    if (!itemPurchaseData) {
      itemPurchaseData = "[]";
    }
    console.log(itemPurchaseData, "itemPurchaseData23i394i93i4>>>>>>>>>");
    let itemPurchases = JSON.parse(itemPurchaseData);
    let itemPurchaseRequest = {
      purchase_quantity: item.purchase_quantity,
      quantity:
        parseInt(item.purchase_quantity) *
        parseInt(item.itemPurchaseConfiguration.sale_to_purchase_ratio),
      category_item_id: item.id,
    };
    console.log(
      itemPurchaseRequest,
      "first237874239487812389##################"
    );
    let mIndex = -1;
    _.forEach(itemPurchases, (ipr, index) => {
      if (ipr.category_item_id == itemPurchaseRequest.category_item_id) {
        mIndex = index;
        itemPurchaseRequest.id = ipr.id;
      }
    });
    if (mIndex == -1 && item.purchase_quantity > 0) {
      itemPurchases.push(itemPurchaseRequest);
      item.itemPurchaseRequest = itemPurchaseRequest;
    } else if (item.purchase_quantity > 0) {
      itemPurchases[mIndex] = itemPurchaseRequest;
      item.itemPurchaseRequest = itemPurchaseRequest;
    } else {
      itemPurchases.splice(mIndex, 1);
      item.itemPurchaseRequest = null;
    }
    await AsyncStorage.setItem(
      "ITEM_PURCHASE_LIST",
      JSON.stringify(itemPurchases)
    );
    if (item.purchase_quantity >= 0) {
      updateItemToPurchaseRequestNetwork(
        {
          item: item,
          purchase_quantity: itemPurchaseRequest.purchase_quantity,
          showLoader: showLoader,
        },
        {
          callback: () => {
            draftPoAndAIP(item, showLoader);
          },
        }
      );
    }
    setParentLoading(false);
  };

  const updateItemToPurchaseRequestNetwork = (
    { item, purchase_quantity, showLoader },
    { callback }
  ) => {
    //Add to draft po.
    if (!extraOptions?.current?.draftPo?.id) {
      console.log("fetch draft purchase order and assign it to ref");
      getDraftOrder();
    }
    if (showLoader) setParentLoading(true);
    let params = {
      // id: item?.itemPurchaseRequest?.category_item_id || item.id,
      id: item?.itemPurchaseRequest?.id || item.id,
      quantity:
        item.purchase_quantity *
        item.itemPurchaseConfiguration.sale_to_purchase_ratio,
    };
    console.log("Params");
    console.log(params, item);

    let updateType = AsyncEmployeeManager.itemPurchaseRequestUpdate;
    let deleteType = AsyncEmployeeManager.itemPurchaseRequestDelete;
    const approvedParams = {
      category_item_id: item.id,
      draft_purchase_order_id: extraOptions.current.draftPo.id,
    };
    AsyncEmployeeManager.getApprovedItemPurchaseRequest(approvedParams)
      .then((response, error) => {
        updateType = AsyncEmployeeManager.updateApprovedItemPurchaseRequest;
        deleteType = AsyncEmployeeManager.deleteApprovedItemPurchaseRequest;
        if (response[1].data.length === 0) {
          getItemListPurchaseRequests([item.id], true, true).then(
            (response) => {
              _.forEach([item.id], (ele) => {
                if (response[ele] === undefined) {
                  console.log("add item purchase request if not");
                  // need to add condition to check if it has to delete or add item purchase request based on quantity
                  if (item.purchase_quantity) {
                    console.log("ipr is not there need to add");
                    addItemToPurchaseRequestViaNetwork(
                      { item: item },
                      {
                        callback: () => {
                          callback(false);
                        },
                      }
                    );
                  } else {
                    console.log(
                      "item does not has ipr but in this i make my quantity as 0 so need to delete"
                    );
                    updatePurchaseType(
                      showLoader,
                      item,
                      deleteType,
                      updateType,
                      params,
                      callback
                    );
                  }
                } else {
                  updateType = AsyncEmployeeManager.itemPurchaseRequestUpdate;
                  deleteType = AsyncEmployeeManager.itemPurchaseRequestDelete;
                  params.id = response[ele].id;
                  updatePurchaseType(
                    showLoader,
                    item,
                    deleteType,
                    updateType,
                    params,
                    callback
                  );
                }
              });
            }
          );
        } else {
          params.id = response[1].data[0].id;
          params.quantity = item.purchase_quantity;
          updatePurchaseType(
            showLoader,
            item,
            deleteType,
            updateType,
            params,
            callback
          );
        }
      })
      .catch((err) => {
        // updatePurchaseType(showLoader, item, deleteType, updateType, params, callback);
      });
  };

  const updatePurchaseType = (
    showLoader,
    item,
    deleteType,
    updateType,
    params,
    callback
  ) => {
    if (item.purchase_quantity == 0) {
      deleteType(params)
        .then(([error, response]) => {
          //item.itemPurchaseRequest = response.data;
          if (item.purchase_quantity == 0) {
            item.itemPurchaseRequest = null;
          }
          setParentLoading(false);
          getDraftOrder();
          fetchPurchaseRequestItems(showLoader);
        })
        .then((obj) => {
          setParentLoading(false);
          fetchPurchaseRequestItems(showLoader);
        });
    } else {
      updateType(params)
        .then(([error, response]) => {
          //item.itemPurchaseRequest = response.data;
          if (item.purchase_quantity == 0) {
            item.itemPurchaseRequest = null;
          }
          setParentLoading(false);
          // if (!error.error) {
          callback();
          // }
          fetchPurchaseRequestItems(showLoader);
        })
        .then((obj) => {
          setParentLoading(false);
          fetchPurchaseRequestItems(showLoader);
        });
    }
  };

  const isZipSupplied = function (purchaseConfig) {
    let zipFound = false;
    purchaseConfig?.supplier_prices?.forEach((sp) => {
      console.log("SP>" + sp.supplier_id + "::" + sp?.name);
      if (
        sp.supplier_id ||
        sp?.name?.indexOf("Zip") >= 0 ||
        sp.supplier_id == "64c8ff28179fb06bfbe3b6f9" ||
        sp.supplier_id == "65845fdd179fb0a4e0de327e" ||
        sp.supplier_id == "65cc70fa179fb01ce6ad8745" ||
        sp.supplier_id == "65cda2dc179fb02ff0a27377" ||
        sp.supplier_id == "65cddd19179fb03655ed4c75" ||
        sp.supplier_id == "65cdd88d179fb03655ed2eea" ||
        sp.supplier_id == "65cd9e96179fb02ff0a255ef"
      ) {
        zipFound = true;
      }
    });
    return zipFound;
  };
  const onPressDownload = (selectedItemPurchaseRequests) => {
    let values = selectedItemPurchaseRequests;
    if (!values.length) {
      alert(I18n.t("screen_messages.orders.select_item"));
      return;
    }

    // construct csvString
    const headerString =
      "Sl No,Product Name,In Stock,Purchase Qty, Available at Zip\n";
    const rowString = values
      .map(
        (d, index) =>
          `${index + 1},${d.product.name},${d.product.stock_count} ${d.itemPurchaseConfiguration.sale_unit_display_name
          },${d.itemPurchaseRequest.quantity} ${d.itemPurchaseConfiguration.purchase_unit_display_name
          }(${d.itemPurchaseConfiguration.sale_to_purchase_ratio} ${d.itemPurchaseConfiguration.sale_unit_display_name
          }),${isZipSupplied(d.itemPurchaseConfiguration) ? "Yes" : ""}\n`
      )
      .join("");
    const csvString = `${headerString}${rowString}`;

    let d = new Date();
    // write the current list of answers to a local csv file
    if (Platform.OS === 'web') {
      // Web-specific implementation
      const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });
      const url = URL.createObjectURL(blob);

      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'shoppingList.csv'); // Specify the file name
      document.body.appendChild(link);
      link.click();

      // Clean up
      link.parentNode.removeChild(link);
      URL.revokeObjectURL(url);
    } else {
      const pathToWrite =
        Platform.OS == "ios"
          ? `${RNFetchBlob.fs.dirs.DocumentDir}/shoppingList.csv`
          : `${RNFetchBlob.fs.dirs.DownloadDir}/shoppingList${d.getTime()}.csv`;
      console.log("pathToWrite", pathToWrite);
      // pathToWrite /storage/emulated/0/Download/data.csv
      RNFetchBlob.fs
        .writeFile(pathToWrite, csvString, "utf8")
        .then(() => {
          console.log(`wrote file ${pathToWrite}`);
          // wrote file /storage/emulated/0/Download/data.csv
          if (Platform.OS === "ios") {
            RNFetchBlob.ios.previewDocument(pathToWrite);
          } else {
            RNFetchBlob.android.actionViewIntent(pathToWrite);
          }
        })
        .catch((error) => console.error(error));
    }
  };

  const selectAllItems = function (val, items) {
    setSelectAll(val);
    if (val) {
      //setSelectZip(false);
      //I need to select all now
    }
  };

  const selectAllZip = function (val) {
    setSelectZip(val);
    if (val) {
      //setSelectAll(false);
      //I need to select all now
    }
  };

  const tableColumns = [
    {
      title: I18n.t("screen_messages.common.Items"),
      width: "50%",
      textAlign: "left",
      thType: "highlighted",
      viewStyles: {
        alignItems: "center",
      },
    },
    {
      title: I18n.t("screen_messages.common.quantity"),
      width: "25%",
      textAlign: "center",
      thType: "highlighted",
      viewStyles: {
        alignItems: "center",
      },
    },
    {
      title: I18n.t("screen_messages.common.price"),
      width: "25%",
      textAlign: "center",
      thType: "highlighted",
      viewStyles: {
        alignItems: "center",
      },
    },
  ];

  const renderItem = ({ item, index }) => {
    item.sold_out = true;
    item.price = 0;
    _.forEach(zipItems, (zItem) => {
      if (zItem.reference_id == item.product.reference_id) {
        if (zItem.sold_out || !zItem.is_available) {
          item.sold_out = true;
          item.price = 0;
        } else {
          item.sold_out = false;
          item.price = zItem.price.base_price;
        }
      }
    });
    const product = new ItemCardModel({ product: item.product });

    let id = product.productId;
    let name = product.name;
    let quantity = item.itemPurchaseRequest.purchase_quantity;

    let lastOrdered = "";
    let soldToday = "";
    let inStock = product.stockCount;
    let purchasePrice = product.purchasePrice / 100;
    let unitPrice = _.get(item, "price.total_price");
    let referenceId = _.get(item, "reference_id");
    let companyName = _.get(item, "other_data.company_name");
    let molecules = _.get(item, "other_data.molecule");
    const isActive = false; // _.get(activeRow, "id") === item.id;
    const isAddedToBucket = _.includes(selectedItemIds, id);
    let bgColor = item.sold_out
      ? "#999999"
      : isActive
        ? "#6D707B"
        : theme.white;
    if (isAddedToBucket) {
      bgColor = theme.primary;
    }
    const textColor1 =
      isActive || isAddedToBucket ? theme.white : theme.primary;
    const textColor2 =
      isActive || isAddedToBucket ? theme.white : theme.darkText;
    const textColor3 = isActive || isAddedToBucket ? theme.white : theme.black;
    const textColor4 = isActive || isAddedToBucket ? theme.white : "#666666";
    return (
      <Pressable
        key={`${item.id}-${index}`}
        style={{
          width: "100%",
          backgroundColor: bgColor,
          borderRadius: 0,
          paddingVertical: 10,
          flexDirection: "row",
          marginBottom: 0,
          borderBottomWidth: 0.25,
          borderBottomColor: "#CCCCCC",
        }}
      >
        <View
          style={{
            width: "50%",
            flexDirection: "column",
            borderRightWidth: 1,
            borderRightColor: "#DBDBDB",
            marginRight: 0,
            paddingHorizontal: theme.primaryPadding,
          }}
        >
          <PrestoText color={textColor2} fontStyle="400.normal">
            {name}{" "}
            {item.sold_out
              ? I18n.t("screen_messages.products.sold_out_bracket")
              : ""}
          </PrestoText>

          <PrestoText size={10} color={textColor4} fontStyle="400.normal">
            {molecules}
          </PrestoText>
        </View>

        <View
          style={{
            width: "25%",
            flexDirection: "column",
            borderRightWidth: 1,
            borderRightColor: "#DBDBDB",
            marginRight: 0,
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <PrestoText
            extraStyle={{ textAlign: "center" }}
            color={textColor4}
            fontStyle="600.semibold"
          >
            {quantity}{" "}
          </PrestoText>
          <PrestoText size={10} color={textColor4} fontStyle="400.normal">
            {item.itemPurchaseConfiguration.purchase_unit_display_name}
          </PrestoText>
        </View>
        <View
          style={{
            width: "25%",
            flexDirection: "column",
            borderRightColor: "#DBDBDB",
            marginRight: 0,
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <PrestoText
            extraStyle={{ textAlign: "center" }}
            color={textColor4}
            fontStyle="600.semibold"
            size={16}
          >
            {utils.formattedPriceWithoutCurrency(
              (item.price * quantity).toFixed(2)
            )}
          </PrestoText>
          <PrestoText size={13} color={textColor4} fontStyle="400.normal">
            {item?.price
              ? utils.formattedPriceWithoutCurrency(item?.price?.toFixed(2))
              : ""}
          </PrestoText>
        </View>
      </Pressable>
    );
  };

  const renderTotalRow = (items) => {
    var totalQuantity = 0;
    var totalAmount = 0;
    let bgColor = theme.white;
    const isActive = false;
    const isAddedToBucket = false;
    const textColor2 =
      isActive || isAddedToBucket ? theme.white : theme.darkText;
    const textColor4 = isActive || isAddedToBucket ? theme.white : "#666666";
    console.log(JSON.stringify(items));
    console.log("==== ZIP ITEMS =====");
    _.map(items, (item, index) => {
      _.forEach(zipItems, (zItem) => {
        if (zItem.reference_id == item.product.reference_id) {
          if (zItem.sold_out || !zItem.is_available) {
            item.sold_out = true;
            item.price = 0;
          } else {
            item.sold_out = false;
            item.price = zItem.price.base_price;
          }
        }
      });
    });
    _.map(items, (item, index) => {
      if (!item.sold_out || item.sold_out == "0") {
        let quantity = item.itemPurchaseRequest.purchase_quantity;
        let purchasePrice = (item.price || 0) * 100;
        if (item.price > 0) totalQuantity += parseInt(quantity);
        console.log((item.price || 0) + ":: " + quantity);
        totalAmount += parseFloat(purchasePrice) * parseInt(quantity);
      }
    });
    return (
      <Pressable
        style={{
          width: "100%",
          backgroundColor: bgColor,
          borderRadius: 0,
          paddingVertical: 10,
          flexDirection: "row",
          marginBottom: 0,
          borderBottomWidth: 0.25,
          borderBottomColor: "#CCCCCC",
        }}
      >
        <View
          style={{
            width: "50%",
            flexDirection: "column",
            borderRightWidth: 1,
            borderRightColor: "#DBDBDB",
            marginRight: 0,
            textAlign: "right",

            paddingHorizontal: theme.primaryPadding,
          }}
        >
          <PrestoText color={textColor2} fontStyle="600.semibold">
            Total
          </PrestoText>
        </View>

        <View
          style={{
            width: "25%",
            flexDirection: "column",
            borderRightWidth: 1,
            borderRightColor: "#DBDBDB",
            marginRight: 0,
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <PrestoText
            extraStyle={{ textAlign: "center" }}
            color={textColor4}
            fontStyle="600.semibold"
          >
            {count == 1 ? totalQuantity : ""}
          </PrestoText>
        </View>
        <View
          style={{
            width: "25%",
            flexDirection: "column",
            borderRightColor: "#DBDBDB",
            marginRight: 0,
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <PrestoText
            extraStyle={{ textAlign: "center" }}
            color={textColor4}
            fontStyle="600.semibold"
            size={16}
          >
            {utils.formattedPriceWithoutCurrency(
              (totalAmount / 100).toFixed(2)
            )}
          </PrestoText>
        </View>
      </Pressable>
    );
  };

  const RenderItemHeader = () => {
    return (
      <View
        style={{
          width: "100%",
          height: 60,
          paddingVertical: 5,
          flexDirection: "row",
          justifyContent: "space-between",
          backgroundColor: theme.screenBackgroundColor,
        }}
      >
        {_.map(tableColumns, (column, index) => {
          let viewStyles = {
            height: 50,
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            ...column?.viewStyles,
          };

          let textProps = {
            extraStyle: { paddingLeft: 20 },
            color: theme.paragraph,
            fontStyle: "400.bold",
            size: 12,
          };

          if (column.thType === "highlighted") {
            viewStyles = {
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            };
            textProps = {
              extraStyle: {
                paddingLeft: theme.primaryPadding,
                textAlgin: column.textAlign,
                width: "100%",
              },
              color: theme.black,
              fontStyle: "400.bold",
              size: 12,
            };
          }

          return (
            <View
              key={index}
              style={{
                ...viewStyles,
                width: column.width,
              }}
            >
              <PrestoText
                {...textProps}
                extraStyle={{
                  textAlign: textProps.extraStyle.textAlgin,
                  width: "100%",
                }}
              >
                {column.title}
              </PrestoText>
            </View>
          );
        })}
      </View>
    );
  };

  const renderModal = () => {
    let modalHeight = (isMobile || isMobileWeb) ? "55%" : "70%";
    let modalWidth = (isMobile || isMobileWeb) ? "90%" : "65%";
    return (
      <ModalMobile
        ModelHeight={modalHeight}
        ModelWidth={modalWidth}
        visible={modalVisible}
        onDismiss={() => setModalVisible(false)}
        modalBackgroundColor="#FFF"
        modalContainerStyles={styles.modalContainer}
        content={
          <>
            <View
              style={{
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <PrestoText size={28} color={theme.black} fontStyle={"600.bold"}>
                {I18n.t("screen_messages.rate_and_review_screen.review")}
              </PrestoText>
              <PrestoText
                size={28}
                color={theme.black}
                fontStyle={"600.bold"}
              ></PrestoText>
              <View>
                <View
                  style={{
                    backgroundColor: theme.screenBackgroundColor,
                    flex: 1,
                    flexDirection: "column",
                    height: "100%",
                    padding: 10,
                  }}
                >
                  <FlatList
                    stickyHeaderIndices={[0]}
                    keyExtractor={(item, index) => `${item.id}-${index}`}
                    data={items}
                    renderItem={renderItem}
                    ListHeaderComponent={() => {
                      return <RenderItemHeader />;
                    }}
                  />
                  <>{renderTotalRow(items)}</>
                </View>
              </View>
              <View
                style={{
                  width: isMobile || isMobileWeb ? 100 : 200,
                  marginTop: 20,
                  marginLeft: (isMobile || isMobileWeb) ? "auto" : "75%",
                }}
              >
                <PrestoSolidButton
                  size="extra-large"
                  buttonStyle="secondary"
                  titleColor={theme.white}
                  title={I18n.t("screen_messages.common.confirm")}
                  paddingLeft="20"
                  titleExtraStyle={{
                    textTransform: "capitalize",
                  }}
                  onPress={() => {
                    let unsoldItems = [];
                    let soldOutItems = [];
                    _.forEach(items, (item) => {
                      if (!item.sold_out) {
                        unsoldItems.push(item);
                      } else {
                        soldOutItems.push(item);
                      }
                    });
                    console.log("Sending these");
                    console.log(unsoldItems);
                    sendToZip(unsoldItems, soldOutItems);
                    setModalVisible(false);
                  }}
                />
              </View>
            </View>
          </>
        }
      />
    );
  };

  const getItemPurchaseRequest = (item) =>
    _.find(_.map(items, "itemPurchaseRequest"), ["category_item_id", item.id]);

  const isProductSelected = (product) =>
    _.some(selectedItemPurchaseRequests, (e) => e.product.id === product.id);

  const supplierDetails = async (items) => {
    return new Promise((resolve) => {
      let zipSuppliedItems = [];
      _.map(items, (itemObject, index) => {
        let product = itemObject.product;
        if (
          product.other_data.zip_supplied == 1 ||
          product.other_data.zip_supplied == "1"
        ) {
          zipSuppliedItems.push(itemObject);
        }
      });
      let tempItems = [];
      _.map(zipSuppliedItems, (itemObject, index) => {
        if (parseInt(itemObject?.itemPurchaseRequest?.purchase_quantity) != 0) {
          let item = itemObject.product;
          let product = itemObject.product;
          AsyncEmployeeManager.itemPurchaseConfigurationShow({
            id: item.id,
          }).then(([error, response]) => {
            let itemPurchaseConfiguration = _.get(response, "data", null);
            let supplierPrices = _.get(
              itemPurchaseConfiguration,
              "supplier_prices",
              []
            );
            if (supplierPrices) {
              let options = _.map(supplierPrices, (supplierPrice) => {
                return {
                  label: `${supplierPrice.name} - ${supplierPrice.purchase_price}`,
                  supplierPrice,
                };
              });
              let supplierPrice = options[0];
              options.forEach((opt) => {
                if (
                  opt.supplierPrice.name?.indexOf("Zip") >= 0 ||
                  opt.supplierPrice.supplier_id == "64c8ff28179fb06bfbe3b6f9" ||
                  opt.supplierPrice.supplier_id == "65845fdd179fb0a4e0de327e"
                ) {
                  supplierPrice = opt;
                }
              });
              tempItems = _.concat(tempItems, {
                supplierPrice: supplierPrice?.supplierPrice,
                itemPurchaseConfiguration,
                product,
                itemPurchaseRequest: itemObject.itemPurchaseRequest,
              });
            }
            resolve(tempItems);
          });
        }
      });
    });
  };

  const markOrderInZip = async (id, itemPurchases) => {
    let itemIds = await AsyncStorage.getItem("ZIP_ITEM_IDS");
    if (!itemIds || itemIds == null || itemIds == "null") {
      itemIds = "[]";
    }
    let ids = JSON.parse(itemIds);
    _.forEach(itemPurchases, async (ipr, index) => {
      console.log(itemIds);
      console.log("====ITEMIDS====");
      if (ids.indexOf(ipr.itemPurchaseRequest.category_item_id) == -1) {
        ids.push(ipr.itemPurchaseRequest.category_item_id);
      }
      console.log(ids);
      console.log("===ZIP====");
    });
    await AsyncStorage.setItem("ZIP_ITEM_IDS", JSON.stringify(ids));
  };

  const draftPoAndAIP = async (
    item,
    showLoader = true,
    markConfirm = false
  ) => {
    let tempItems = [];
    let itemPurchaseConfiguration = item.itemPurchaseConfiguration;
    let product = item.product
      ? { ...item.product, itemPurchaseRequest: item.itemPurchaseRequest }
      : item;
    let supplierPrices = _.get(
      itemPurchaseConfiguration,
      "supplier_prices",
      []
    );
    let options = _.map(supplierPrices, (supplierPrice) => {
      return {
        label: `${supplierPrice.name} - ${supplierPrice.purchase_price}`,
        supplierPrice,
      };
    });
    let supplierPrice = options[0];
    let zipSupplied = false;
    options.forEach((opt) => {
      if (
        opt.supplierPrice.name?.indexOf("Zip") >= 0 ||
        opt.supplierPrice.supplier_id == "64c8ff28179fb06bfbe3b6f9" ||
        opt.supplierPrice.supplier_id == "65845fdd179fb0a4e0de327e"
      ) {
        supplierPrice = opt;
        zipSupplied = true;
      }
    });

    console.log(zipSupplied);
    console.log(JSON.stringify(options) + "SupplideZip----");
    let isSelected = isProductSelected(product);
    // if (!zipSupplied) {
    tempItems = isSelected
      ? _.filter(tempItems, (e) => e.product.id !== product.id)
      : _.concat(tempItems, {
        supplierPrice: supplierPrice?.supplierPrice,
        itemPurchaseConfiguration,
        product,
        itemPurchaseRequest: product.itemPurchaseRequest,
      });
    // }
    console.log(tempItems);
    console.log("----- newrkqwejr ^^^^^^^^^^^^^^^^");
    // });

    let proxyPo = getPurchaseRequestDetailInitial(tempItems);
    console.log(JSON.stringify(proxyPo));
    let result = undefined;
    AsyncPurchaseOrdersManager.listDraftPo({ status: "INITIATED" }).then(
      async (res) => {
        if (!res[1].data.length) {
          console.log("create draft purchase");
          result = await draftPo.create();
          extraOptions.current.draftPo = result[1]?.data[0];
        } else {
          result = extraOptions.current.draftPo;
        }
      }
    );
    getDraftOrder();
    console.log(" -----");
    // if (proxyPo.items.length) {
    const draftPo = new PurchaseOrderHelper.DraftPo(
      extraOptions.current.draftPo
    );

    console.log(" ----- ");
    console.log(JSON.stringify(draftPo));

    if (showLoader) setParentLoading(true);

    supplierDetails(tempItems).then(async (supplierResponse) => {
      if (result instanceof Error) {
        console.log(result);
        console.log("----ERR----");
        alertBox("", result.message);
        setParentLoading(false);
      } else {
        console.log(result, supplierResponse);
        console.log("item does not have AIP so need to create it");
        console.log("PROXY ITEMS");
        let responses = await draftPo.approveItemPurchases(supplierResponse);
        let errors = _.filter(responses, (e) => _.has(e, "error"));
        setParentLoading(false);
        if (errors.length) {
          return alertBox("", _.get(errors, `0.message`));
        } else {
          let itemPurchaseData = await AsyncStorage.getItem(
            "ITEM_PURCHASE_LIST"
          );
          let itemPurchase = JSON.parse(itemPurchaseData);
          _.forEach(itemPurchase, (ipr, index) => {
            if (ipr.category_item_id == responses[0].category_item_id) {
              delete ipr.id;
            }
          });
          await AsyncStorage.setItem(
            "ITEM_PURCHASE_LIST",
            JSON.stringify(itemPurchase)
          );
        }

        if (markConfirm) {
          let response = await draftPo.markAsConfirmed(
            markOrderInZip,
            tempItems
          );
          console.log(response, "mark as confirmed done response");
          if (_.has(response, "error")) {
            return alertBox("", response.message);
          } else {
            alertBox("", I18n.t("screen_messages.orders.thanks_order"));
            // zip has order
          }
        }

        getOrCreateAndSetPendingDraftPo(false);
        // alertBox(
        //   "",
        //   "Thanks for your order. You can track your order on ZIP App"
        // );
        extraOptions.current.selectedItems = [];
        let itemPurchaseData = await AsyncStorage.getItem("ITEM_PURCHASE_LIST");
        if (!itemPurchaseData) {
          itemPurchaseData = "[]";
        }

        let itemPurchases = JSON.parse(itemPurchaseData);

        _.forEach(selectedItemPurchaseRequests, (iprObj) => {
          let mIndex = -1;
          _.forEach(itemPurchases, (ipr, index) => {
            if (
              ipr.category_item_id ==
              iprObj.itemPurchaseRequest.category_item_id
            ) {
              mIndex = index;
            }
          });
          itemPurchases.splice(mIndex, 1);
        });

        await AsyncStorage.setItem(
          "ITEM_PURCHASE_LIST",
          JSON.stringify(itemPurchases)
        );

        if (markConfirm) {
          fetchPurchaseRequestItems().then(() => {
            setActionHandler({
              type: "NAVIGATE_TO_INTIAL_PURCHASE_REQUEST_DETAIL",
            });
          });
        }
      }
    });
    // }
  };

  const sendToZip = async (selectedItemPurchaseRequests, soldOutItems) => {
    console.log(
      selectedItemPurchaseRequests[0]?.itemPurchaseConfiguration
        ?.supplier_prices[0]
    );
    console.log("---------SSS");
    addAllItemsToPurchaseRequest(
      { selectedItemPurchaseRequests, soldOutItems },
      {
        callback: async (allHasPurchaseRequests) => {
          let proxyPo = getPurchaseRequestDetailInitial();
          console.log(JSON.stringify(proxyPo));
          console.log(" -----");
          if (proxyPo.items.length) {
            const draftPo = new PurchaseOrderHelper.DraftPo(
              extraOptions.current.draftPo
            );

            console.log(" ----- ");
            console.log(JSON.stringify(draftPo));

            setParentLoading(true);

            const result =
              extraOptions.current.draftPo || (await draftPo.create());
            if (result instanceof Error) {
              console.log(result);
              console.log("----ERR----");
              alertBox("", result.message);
              setParentLoading(false);
            } else {
              extraOptions.current.draftPo = result;
              if (!allHasPurchaseRequests) {
                console.log(proxyPo.items);
                console.log("PROXY ITEMS");
                let responses = await draftPo.approveItemPurchases(
                  proxyPo.items
                );
                let errors = _.filter(responses, (e) => _.has(e, "error"));
                setParentLoading(false);
                if (errors.length) {
                  return alertBox("", _.get(errors, `0.message`));
                }
              }
              let response = await draftPo.markAsConfirmed(
                markOrderInZip,
                selectedItemPurchaseRequests
              );

              if (_.has(response, "error")) {
                return alertBox("", response.message);
              }

              getOrCreateAndSetPendingDraftPo();
              alertBox("", I18n.t("screen_messages.orders.thanks_order"));
              // extraOptions.current.selectedItems = [];
              let itemPurchaseData = await AsyncStorage.getItem(
                "ITEM_PURCHASE_LIST"
              );
              if (!itemPurchaseData) {
                itemPurchaseData = "[]";
              }

              let itemPurchases = JSON.parse(itemPurchaseData);

              _.forEach(selectedItemPurchaseRequests, (iprObj) => {
                let mIndex = -1;
                _.forEach(itemPurchases, (ipr, index) => {
                  if (
                    ipr.category_item_id ==
                    iprObj.itemPurchaseRequest.category_item_id
                  ) {
                    mIndex = index;
                  }
                });
                itemPurchases.splice(mIndex, 1);
              });

              await AsyncStorage.setItem(
                "ITEM_PURCHASE_LIST",
                JSON.stringify(itemPurchases)
              );
              extraOptions.current.selectedItems = [];
              fetchPurchaseRequestItems().then(() => {
                setActionHandler({
                  type: "NAVIGATE_TO_INTIAL_PURCHASE_REQUEST_DETAIL",
                });
              });
            }
          }
        },
      }
    );
  };
  const sendSelectedToZip = async (selectedItemPurchaseRequests) => {
    if (!selectedItemPurchaseRequests.length) {
      alert(I18n.t("screen_messages.orders.select_item"));
      return;
    }
    setItems(selectedItemPurchaseRequests);
    setCount(1);
    let allZip = true;
    let refIds = [];
    let categoryItemId = [];
    _.map(selectedItemPurchaseRequests, async (item, index) => {
      if (!isZipSupplied(item.itemPurchaseConfiguration)) {
        allZip = false;
      }
      refIds.push(item.product.reference_id);
      categoryItemId.push(item.itemPurchaseRequest.category_item_id);
    });

    // remove aip from dpo if it is not in selected items
    let updateType = AsyncEmployeeManager.itemPurchaseRequestUpdate;
    let deleteType = AsyncEmployeeManager.deleteApprovedItemPurchaseRequest;
    const approvedParams = {
      draft_purchase_order_id: extraOptions.current.draftPo.id,
    };
    await AsyncEmployeeManager.getApprovedItemPurchaseRequest(approvedParams).then((response) => {
      console.log(JSON.stringify(response), "dskfasdjfiaosjdfoij");
      response[1].data.forEach((ele) => {
        let params = {
          // id: item?.itemPurchaseRequest?.category_item_id || item.id,
          id: ele.id,
          quantity: 0,
        };
        if (!categoryItemId.includes(ele.category_item_id)) {
          console.log("delete these items if it is not in selected items", params)
          updatePurchaseType(
            false,
            { purchase_quantity: 0 },
            deleteType,
            updateType,
            params,
            () => null
          );
        }
      })
    })

    if (!allZip) {
      alertBox("", I18n.t("screen_messages.orders.select_item_zip"));
    } else {
      setParentLoading(true);
      console.log("REF IDS");
      console.log(refIds);
      let params = {
        size: 5000,
        is_avialalble: true,
        enableOffline: false,
        merchant_id:
          user?.other_data?.permissions?.order_merchant_id ||
          "64648cb5180cc982bcad59b7",
        ref_ids: refIds,
      };

      console.log("PARAMS");
      console.log(params);
      SearchManager.searchCategoryItem(
        params,
        (response) => {
          console.log(response.hits.total);
          console.log("Zip Items search");
          setZipItems(_.map(response.hits.hits, (item) => item?._source));
          console.log("Count - " + zipItems.length);
          setTimeout(function () {
            setModalVisible(true);
            setParentLoading(false);
          }, 1000);
        },
        (error) => {
          alertBox("", I18n.t("screen_messages.orders.select_item_zip"));
          setParentLoading(false);
        }
      );
    }

    return;
    let proxyPo = getPurchaseRequestDetailInitial();
    if (proxyPo.items.length) {
      const draftPo = new PurchaseOrderHelper.DraftPo(
        extraOptions.current.draftPo
      );

      setParentLoading(true);

      const result = extraOptions.current.draftPo || (await draftPo.create());

      if (result instanceof Error) {
        alertBox("", result.message);
      } else {
        extraOptions.current.draftPo = result;

        let responses = await draftPo.approveItemPurchases(proxyPo.items);
        let errors = _.filter(responses, (e) => _.has(e, "error"));
        setParentLoading(false);
        if (errors.length) {
          return alertBox("", _.get(errors, `0.message`));
        }

        let response = await draftPo.markAsConfirmed();

        if (_.has(response, "error")) {
          return alertBox("", response.message);
        }

        getOrCreateAndSetPendingDraftPo();

        extraOptions.current.selectedItems = [];
        fetchPurchaseRequestItems().then(() => {
          setActionHandler({
            type: "NAVIGATE_TO_INTIAL_PURCHASE_REQUEST_DETAIL",
          });
        });
      }
    }
  };
  const resetPurchaseRequest = () => {
    setSelectedItemIds([]);
    purchaseRequestRef.current = null;
    setPurchaseRequest(null);
  };

  const fetchPurchaseRequestItems = async (showLoader = true) => {
    if (showLoader) setParentLoading(true);
    let itemPurchaseData = await AsyncStorage.getItem("ITEM_PURCHASE_LIST");
    if (!itemPurchaseData) {
      itemPurchaseData = "[]";
    }
    let itemPurchaseRequests = JSON.parse(itemPurchaseData);
    let ids = _.map(itemPurchaseRequests, "category_item_id");
    let tempItems = ids.length ? await getItemsByLocalIds(ids) : [];
    console.log("Items === ");
    console.log(tempItems);
    if (ids.length == 0) {
      setItemPurchaseRequests(tempItems);
    }
    if (tempItems) {
      tempItems = _.map(tempItems, (tempItem) => {
        let itemPurchaseRequestItem = _.find(itemPurchaseRequests, [
          "category_item_id",
          tempItem.id,
        ]);
        let itemPurchaseConfiguration = {
          sale_unit_display_name: tempItem.other_data["sale unit"],
          purchase_unit_display_name: tempItem.other_data["purchase unit"],
          sale_to_purchase_ratio: parseInt(
            tempItem.other_data["sale to purchase ratio"]
          ),
        };
        if (
          tempItem.other_data.zip_supplied == "1" ||
          tempItem.other_data.zip_supplied == 1
        ) {
          itemPurchaseConfiguration.supplier_prices = [
            {
              name: "Zip",
            },
          ];
        } else {
          itemPurchaseConfiguration.supplier_prices = [
            {
              name: "Others",
            },
          ];
        }
        return {
          itemPurchaseConfiguration: itemPurchaseConfiguration,
          itemPurchaseRequest: itemPurchaseRequestItem,
          product: tempItem,
        };
      });
      /*tempItems.forEach((arrayItem) => {
        arrayItem.itemPurchaseConfiguration = {
          sale_unit_display_name: arrayItem.other_data["sale unit"],
          purchase_unit_display_name: arrayItem.other_data["purchase unit"],
          sale_to_purchase_ratio: parseInt(
            arrayItem.other_data["sale to purchase ratio"]
          ),
        };
        if (
          arrayItem.other_data.zip_supplied == "1" ||
          arrayItem.other_data.zip_supplied == 1
        ) {
          arrayItem.itemPurchaseConfiguration.supplier_prices = [
            {
              name: "Zip",
            },
          ];
        }
        _.forEach(itemPurchaseRequests, (itemPurchaseRequest) => {
          if (itemPurchaseRequest.category_item_id == arrayItem.id) {
            arrayItem.itemPurchaseRequest = itemPurchaseRequest;
          }
        });
      });*/
      setParentLoading(false);
    } else {
      console.log("I am setting -- " + tempItems);
      setItemPurchaseRequests(tempItems);
      setParentLoading(false);
    }
  };
  const fetchPurchaseRequestItemsNetwork = () => {
    setParentLoading(true);
    return AsyncEmployeeManager.itemPurchaseRequests({})
      .then(([error, response]) => {
        return _.get(response, "data", []);
      })
      .then(async (itemPurchaseRequests) => {
        if (itemPurchaseRequests) {
          let ids = _.map(itemPurchaseRequests, "category_item_id");
          let tempItems = ids.length ? await getItemsByIds(ids) : [];

          if (tempItems) {
            tempItems = _.map(tempItems, (tempItem) => {
              let itemPurchaseRequestItem = _.find(itemPurchaseRequests, [
                "category_item_id",
                tempItem.id,
              ]);
              return {
                itemPurchaseRequest: itemPurchaseRequestItem,
                product: tempItem,
              };
            });
          }

          //setItemPurchaseRequests(tempItems);

          setParentLoading(false);

          let completed = 0;
          /*tempItems.forEach((tempItem) => {
            getItemPurchaseConfiguration(tempItem.product, true).then(
              (itemPurchaseConfiguration) => {
                completed = completed + 1;
                tempItem.itemPurchaseConfiguration = itemPurchaseConfiguration;
                if (completed == tempItems.length) {
                  setItemPurchaseRequests(tempItems);
                }
              }
            );
          });*/
          let itemIds = [];
          tempItems.forEach((arrayItem) => {
            /*getItemPurchaseConfiguration(arrayItem, true).then(
            (itemPurchaseConfiguration) => {
              completed = completed + 1;
              arrayItem.itemPurchaseConfiguration = itemPurchaseConfiguration;
              if (completed == array.length) {
                success(array);
              }
            }
          );*/
            itemIds.push(arrayItem.product.id);
          });
          getItemListPurchaseConfiguration(itemIds, true).then(
            (itemListPurchaseConfiguration) => {
              console.log("Response ===");
              console.log(itemListPurchaseConfiguration);
              tempItems.forEach((arrayItem) => {
                arrayItem.itemPurchaseConfiguration =
                  itemListPurchaseConfiguration[arrayItem.product.id];
              });
              setItemPurchaseRequests(tempItems);
            }
          );
        }
      });
  };

  const getItemsByLocalIds = (ids) => {
    let params = {
      merchant_id: merchantId,
      ids: ids,
      size: ids.length,
      enableOffline: !isWeb,
    };
    return CategoryDataSources.fuzzySearchCategoryItem(
      params,
      async (response) => {
        console.log("REEE");
        console.log(response);
        let items = _.get(response, "hits.hits", []);
        let itemPurchaseData = await AsyncStorage.getItem("ITEM_PURCHASE_LIST");
        if (!itemPurchaseData) {
          itemPurchaseData = "[]";
        }
        let itemPurchaseRequests = JSON.parse(itemPurchaseData);
        let tempItems = _.map(items, "_source");
        let prs = [];
        tempItems = _.map(tempItems, (tempItem) => {
          let itemPurchaseRequestItem = _.find(itemPurchaseRequests, [
            "category_item_id",
            tempItem.id,
          ]);
          let itemPurchaseConfiguration = {
            sale_unit_display_name: tempItem.other_data["sale unit"],
            purchase_unit_display_name: tempItem.other_data["purchase unit"],
            sale_to_purchase_ratio: parseInt(
              tempItem.other_data["sale to purchase ratio"]
            ),
          };
          if (
            tempItem.other_data.zip_supplied == "1" ||
            tempItem.other_data.zip_supplied == 1
          ) {
            itemPurchaseConfiguration.supplier_prices = [
              {
                name: "Zip",
              },
            ];
          } else {
            itemPurchaseConfiguration.supplier_prices = [
              {
                name: "Others",
              },
            ];
          }
          prs.push({
            itemPurchaseConfiguration: itemPurchaseConfiguration,
            itemPurchaseRequest: itemPurchaseRequestItem,
            product: tempItem,
          });
        });
        console.log(JSON.stringify(prs));
        console.log("----");
        setItemPurchaseRequests(prs);
        setParentLoading(false);
      }
    );
  };

  const getItemsByIds = (ids) => {
    let params = {
      merchant_id: merchantId,
      ids: ids,
      size: ids.length,
    };
    return AsyncSearchManager.fuzzySearchCategoryItem(params).then(
      ([error, response]) => {
        let items = _.get(response, "hits.hits", []);
        return _.map(items, "_source");
      }
    );
  };

  const getPurchaseRequestDetailInitial = (item) => {
    return PurchaseOrderHelper.getPuchaseOrderObjectAsProxy({
      items: item ? item : extraOptions.current.selectedItems,
    });
  };

  const onItemPurchaseRequestUpdate = (purchaseItem, saleQuantity) => {
    return new Promise((resolve) => {
      let tempItems = extraOptions.current.selectedItems;
      extraOptions.current.selectedItems = _.map(tempItems, (selectedItem) => {
        if (purchaseItem.id === selectedItem.product.id) {
          selectedItem.product.quantity = saleQuantity;
          if (
            hasPurchaseOrderManagementPermission &&
            selectedItem.itemPurchaseRequest &&
            selectedItem.itemPurchaseConfiguration
          ) {
            let ratio = _.get(
              selectedItem.itemPurchaseConfiguration,
              "sale_to_purchase_ratio"
            );
            const fromSaleToPurchaseQty = Math.ceil(
              selectedItem.product.quantity / ratio
            );
            selectedItem.itemPurchaseRequest.purchase_quantity = fromSaleToPurchaseQty;
            selectedItem.itemPurchaseRequest.quantity =
              selectedItem.product.quantity;
          }
        }

        return {
          ...selectedItem,
        };
      });
      resolve(getPurchaseRequestDetailInitial());
    });
  };

  const onItemPurchaseRequestUpdateRemove = (purchaseItem) => {
    return new Promise((resolve) => {
      extraOptions.current.selectedItems = _.filter(
        extraOptions.current.selectedItems,
        (selectedItem) => {
          return selectedItem.product.id !== purchaseItem.id;
        }
      );

      setSelectedItemIds(
        _.filter(selectedItemIds, (e) => e !== purchaseItem.id)
      );

      resolve(getPurchaseRequestDetailInitial());
    });
  };

  const [
    results,
    loadNextPage,
    loadFirstPage,
    load,
    pageLoaded,
  ] = usePagination(searchPage);
  // console.log(results, "badad")

  const purchaseOrderObject = new PurchaseOrderObject();

  const canShowSearchBar = _.includes(
    ["low_stock", "all_items", "regular_purchases"],
    filterOptions.tabId
  );
  const canProductTable = _.includes(
    ["low_stock", "all_items", "regular_purchases"],
    filterOptions.tabId
  );

  const sentToZipStatus = ["DELIVERED", "REJECTED"];

  const isZipOrders = _.includes(["zip_orders"], filterOptions.tabId);
  let fetched = false;
  if (isZipOrders && !fetched) {
    /*const purchaseOrderObject = new PurchaseOrderObject();*/

    promiseItem = purchaseOrderObject.listApproved();
    //setParentLoading(true);
    console.log("Fetching again ");
    promiseItem
      .then((rows) => {
        //setParentLoading(false);
        if (purchaseOrders?.length == 0) {
          let count = 0;
          let completedCount = 0;
          rows.forEach((pOrder) => {
            count += pOrder.items.length;
          });
          rows.forEach((pOrder) => {
            pOrder.items.forEach((item) => {
              const obj = new DBCategoryItemManager();
              let newParams = {
                id: item.item_id,
              };
              obj.filter(newParams).then((items, totalCount) => {
                completedCount += 1;
                const response = obj.mockAsSearchServerAPI(items, totalCount);
                item.sourceItem = response?.data?.hits?.hits[0]?._source;
                if (completedCount >= count) {
                  setPurchaseOrders(rows);
                  console.log("Purchase Orders == ");
                }
              });
            });
          });
        }
        fetched = true;
      })
      .catch((error) => {
        //setParentLoading(false);
        console.log("PurchaseOrders->reloadLeftSideSection->error", error);
        fetched = true;
      });
  }

  return (
    <View
      keyboardShouldPersistTaps="always"
      style={{
        backgroundColor: theme.screenBackgroundColor,
        flex: 1,
      }}
    >
      {canShowSearchBar ? (
        <View style={{ padding: 10 }}>
          <AdvanceSearchBar
            placeHolder={
              !isTabAndAbove
                ? I18n.t("screen_messages.common.search")
                : I18n.t("screen_messages.common.search_by2")
            }
            searchValue={filterOptions.searchValue}
            onChange={(text) => {
              setFilterOptions({
                ...filterOptions,
                searchValue: text,
              });
            }}
            isMobile={isMobile}
            onPressIcon={doAdvanceSearch}
          />
        </View>
      ) : null}

      <View
        style={{
          paddingHorizontal: 10,
          paddingBottom: canProductTable ? 120 : 60,
          height: "100%",
        }}
      >
        {canProductTable ? (
          <>
            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <PrestoText
                extraStyle={{ paddingLeft: 20 }}
                color={theme.black}
                fontStyle={"400.bold"}
                size={16}
              >
                {I18n.t("screen_messages.common.showing")} {currentLoaded}{" "}
                {I18n.t("screen_messages.common.of")} {totalCount}
              </PrestoText>
            </View>
            <ProductsTable
              activeRow={activeRow}
              selectedItemIds={selectedItemIds}
              onPressActionButton={({ item }) => {
                let tempItem = {
                  product: item,
                  supplierPrice: null,
                  itemPurchaseRequest: null,
                };
                onPressActionButton1({ item: tempItem });
              }}
              onAmountChange={({ item, quantity }) => {
                //To store the count which can be used to update
                item.purchase_quantity = quantity;
              }}
              addItemToPurchaseRequest={addItemToPurchaseRequest}
              updateItemToPurchaseRequest={updateItemToPurchaseRequest}
              onPressRow={(product) => {
                let tempItem = null;
                if (product.id !== activeRow?.id) {
                  tempItem = product;
                }
                setActiveRow(tempItem);

                if (tempItem) {
                  let isAddedToBucket = _.some(
                    selectedItemIds,
                    (e) => e === tempItem?.id
                  );
                  if (isMobile) return;
                  setTimeout(() => {
                    sideNavigationRef.navigate("ItemDetailScreen", {
                      selectedItem: tempItem,
                      onClickAdd: () => { },
                      enableAddToStore: false,
                      enableAddOrderToRequest: !isAddedToBucket,
                      onAddOrderToRequest: (item) => {
                        let tempItem = {
                          product: item,
                          supplierPrice: null,
                          itemPurchaseRequest: null,
                        };
                        return onPressActionButton1({
                          item: tempItem,
                        });
                      },
                      enablePurchaseDetailButton: isAddedToBucket,
                      onPressPurchaseDetailButton: (item) => {
                        setActionHandler({
                          type: "NAVIGATE_TO_INTIAL_PURCHASE_REQUEST_DETAIL",
                        });
                      },
                    });
                  }, 300);
                } else {
                  setActionHandler({
                    type: "NAVIGATE_TO_INTIAL_PURCHASE_REQUEST_DETAIL",
                  });
                }
              }}
              onPressDownload={onPressDownload}
              sendSelectedToZip={sendSelectedToZip}
              items={results}
              onEndReached={
                results.length > 0
                  ? () => {
                    console.log("called");
                    loadNextPage();
                  }
                  : undefined
              }
              updateSortDirection={(sField) => {
                console.log(sField);
                setSortField(sField);
                globalStateStore.setKey("SORT_FIELD", sField);
                globalStateStore.setKey("SORT_DIRECTION", currentSortDirection);
                console.log("Got the request here = " + currentSortDirection);
                if (currentSortDirection == "ASC") {
                  setCurrentSortDirection("DESC");
                  globalStateStore.setKey("SORT_DIRECTION", "DESC");
                } else {
                  setCurrentSortDirection("ASC");
                  globalStateStore.setKey("SORT_DIRECTION", "ASC");
                }
                loadFirstPage();
              }}
              currentSortDirection={currentSortDirection}
              sortField={sortField}
              updateSortField={() => {
                setSortField(sortField);
              }}
            />
          </>
        ) : isZipOrders ? (
          <MyPurchaseRequests
            activeRow={activeRow}
            suppliers={suppliers}
            column2Title={
              _.some(tabs, ["id", "awaiting_approval"])
                ? I18n.t("screen_messages.orders.items")
                : I18n.t("screen_messages.suppliers.title2")
            }
            onPressActionButton={() => { }}
            onPressDownload={onPressDownload}
            sendSelectedToZip={sendSelectedToZip}
            onPressRow={(tempPurchaseOrder) => {
              let tempItem = null;
              if (tempPurchaseOrder.id !== activeRow?.id) {
                tempItem = tempPurchaseOrder;
              }
              setActiveRow(tempItem);
              setPurchaseOrder(tempPurchaseOrder);

              setActionHandler({
                type: "NAVIGATE_TO_INTIAL_PURCHASE_REQUEST_DETAIL",
              });
            }}
            items={purchaseOrders?.filter(
              (ele) => !sentToZipStatus.includes(ele?.status)
            )}
            onEndReached={() => { }}
            purchaseOrderObject={purchaseOrderObject}
          />
        ) : (
          <>
            <ItemPurchaseRequestTable
              activeRow={activeRow}
              updateItemToPurchaseRequest={updateItemToPurchaseRequest}
              updateItemToPurchaseRequestNetwork={
                updateItemToPurchaseRequestNetwork
              }
              addItemToPurchaseRequestViaNetwork={
                addItemToPurchaseRequestViaNetwork
              }
              draftPoAndAIP={draftPoAndAIP}
              addItemToPurchaseRequest={addItemToPurchaseRequest}
              setParentLoading={setParentLoading}
              selectedItemIds={selectedItemIds}
              onPressDownload={(selectedItemPurchaseRequests) =>
                onPressDownload(selectedItemPurchaseRequests)
              }
              sendSelectedToZip={(selectedItemPurchaseRequests) =>
                sendSelectedToZip(selectedItemPurchaseRequests)
              }
              selectAll={selectAll}
              selectZip={selectZip}
              onSelectAll={selectAllItems}
              onSelectZip={selectAllZip}
              clearItemPurchaseRequests={clearItemPurchaseRequests}
              onPressRow={(product) => {
                let tempItem = null;
                if (product.id !== activeRow?.id) {
                  tempItem = product;
                }
                setActiveRow(tempItem);

                if (tempItem) {
                  let isAddedToBucket = _.some(
                    selectedItemIds,
                    (e) => e === tempItem?.id
                  );
                  const query = {
                    selectedItem: tempItem,
                    onClickAdd: () => { },
                    enableAddToStore: false,
                    enableAddOrderToRequest: !isAddedToBucket,
                    enableAddOrderToRequest: !isAddedToBucket,
                    onAddOrderToRequest: (item) => {
                      let tempItem = {
                        product: item,
                        supplierPrice: null,
                        itemPurchaseRequest: null,
                      };
                      return onPressActionButton1({
                        item: tempItem,
                      });
                    },
                    enablePurchaseDetailButton: isAddedToBucket,
                    onPressPurchaseDetailButton: (item) => {
                      setActionHandler({
                        type: "NAVIGATE_TO_INTIAL_PURCHASE_REQUEST_DETAIL",
                      });
                    },
                  };
                  if (isMobile) return;
                  setTimeout(() => {
                    sideNavigationRef.navigate("ItemDetailScreen", query);
                  }, 300);
                } else {
                  if (isMobile) return;
                  setActionHandler({
                    type: "NAVIGATE_TO_INTIAL_PURCHASE_REQUEST_DETAIL",
                  });
                }
              }}
              items={itemPurchaseRequests}
              fetchPurchaseRequestItems={fetchPurchaseRequestItems}
              selectedItemPurchaseRequests={extraOptions.current.selectedItems}
              setSelectedItemPurchaseRequests={(val) => {
                extraOptions.current.selectedItems = val;
              }}
              navigateToInitialPurchaseRequestDetail={() => {
                setActionHandler({
                  type: "NAVIGATE_TO_INTIAL_PURCHASE_REQUEST_DETAIL",
                });
              }}
              setActiveRow={setActiveRow}
            />
            <View>{modalVisible && renderModal()}</View>
          </>
        )}
      </View>
    </View>
  );
}
function getStyles(theme) {
  return {
    container: {},
    modalContainer: {
      alignSelf: "center",
      padding: 20,
      justifyContent: "center",
      borderTopLeftRadius: 20,
      borderTopRightRadius: 20,
      borderBottomRightRadius: 20,
      borderBottomLeftRadius: 20,
    },
  };
}
