import React, {
  useContext,
  useState,
  useEffect,
  useCallback,
  useRef,
} from "react";
import DBMetaDataManager from "@presto-db-managers/DBMetaDataManager";
import WebNavigatorContext from "@presto-contexts/WebNavigatorContext";
import { View, Keyboard, Pressable, Dimensions, Platform } from "react-native";
import { ScrollView } from "react-native-gesture-handler";
import ThemeContext from "@presto-contexts/ThemeContext";
import CartContext from "@presto-contexts/CartContext";
import CatalogContext from "@presto-contexts/CatalogContext";
import utils from "../../utils/index";
import _ from "lodash";
import DBOrderManager from "@presto-db-managers/DBOrderManager";
import LoginHelper from "@presto-helpers/LoginHelper";
import UserContext from "@presto-contexts/UserContext";
import SearchBar from "@presto-screen-components/SearchBar/SearchBar";
import SearchedItems from "@presto-screen-components/Home/SearchedItems";
import NetworkContext from "@presto-contexts/NetworkContext";
import { alertBox, prestoConfirm } from "@presto-common/Alert";
import { useFocusEffect } from "@react-navigation/native";
import PrestoText from "@presto-components/PrestoText";
import CartDataSources from "@presto-datasources/CartDataSources";
import ClientDataSource from "@presto-datasources/ClientDataSource";
import I18n from "i18n-js";
import asyncify from "@presto-common/Asyncify";
import { useActionSheet } from "@expo/react-native-action-sheet";
import DBCartManager from "@presto-db-managers/DBCartManager";
import ServerCartManager from "@presto-services/features/server_cart/ServerCartManager";
import NavigatorContext from "@presto-contexts/NavigatorContext";
import CategoryDataSources from "@presto-datasources/CategoryDataSources";
import { TabBlockItem } from "@presto-screen-components/Headers/HeaderWithTabs";
import PrestoIcon from "@presto-components/PrestoIcon";
import { useAssets } from "presto-react-components";
import DBUserManager from "@presto-db-managers/DBUserManager";
import AsyncStorage from "@react-native-community/async-storage";
import moment from "moment";
import EmployeeManager from "@presto-services/features/employee/EmployeeManager";

const AsyncCartDataSources = asyncify(CartDataSources);
const AsyncClientDataSource = asyncify(ClientDataSource);
const AsyncServerCartManager = asyncify(ServerCartManager);

export default function PosManagement({
  sideNavigationRef,
  parentRef,
  tabs,
  setTabs,
  parentLoading,
  setParentLoading,
  componentProps,
}) {
  const { svgs } = useAssets();
  const isWeb = Platform.OS == 'web'
  const isDesktop = utils.isDesktop();
  const { userNetworkState, isOnline, isAppOnline } = !isWeb ? useContext(
    NetworkContext
  ) : { userNetworkState, isOnline: true, isAppOnline: () => false };
  const { theme } = useContext(ThemeContext);
  const { isSync, setIsSync } = useContext(CatalogContext);
  const { Navigator } = useContext(NavigatorContext);
  const { WebNavigator } = useContext(WebNavigatorContext);
  const [searchedItems, setSearchItems] = useState([]);
  const [popularItems, setPopularItems] = useState([]);
  const [accordianSection, setAccordianSection] = useState({
    activeSection: "POPULAR",
  });
  const {
    cart,
    updateItemInCart,
    currentCartData,
    setCurrentCartData,
    createCart,
    refreshCart,
    fetchCarts,
    fetchCart,
    multiCarts,
    clearCart,
  } = useContext(CartContext);
  const { user } = useContext(UserContext);
  const merchantId = LoginHelper.getUserMerchantId(user);
  const [actionHandler, setActionHandler] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [isNoResultsFound, setIsNoResultsFound] = useState(false);
  const isOfflineCartRef = useRef(false);
  const extraOptions = useRef({
    onlineCartExits: false,
    tabs: [],
    cartIds: [],
    doQueryReset: null,
  });
  const { showActionSheetWithOptions } = useActionSheet();
  const isMobile = utils.isMobile();
  const isMobileWeb = utils.isMobileWeb();

  useFocusEffect(
    useCallback(() => {
      if (!isWeb) {
        if (isSync) {
          loadMultipleCarts();
          return;
        }
        setIsSync(true);
        console.log("called in callback when comes online =======================");
        let bBOrderManager = new DBOrderManager();
        DBUserManager.syncToRemote().then(() => {
          bBOrderManager.syncOrders();
          setIsSync(false);
        });
      }
      loadMultipleCarts();
    }, [multiCarts])
  );

  useFocusEffect(
    useCallback(() => {
      if (cart) {
        CartDataSources.existsInOfflineCart(cart.id).then(
          (isOfflineCartValue) => {
            isOfflineCartRef.current = isOfflineCartValue;
          }
        );
      }
    }, [cart])
  );
  const [favId, setFavId] = useState(AsyncStorage.getItem("FREQUENT_IDS"));
  useEffect(() => {
    init();
  }, [favId]);

  useEffect(() => {
    if (multiCarts.length > 0) {
      setTimeout(() => init(), 10000);
    }
  }, [multiCarts.length])

  useEffect(() => {
    updateParentFunctionReferences();
  }, [tabs]);

  useEffect(() => {
    if (actionHandler) {
      handleActionHandler();
    }
  }, [actionHandler, sideNavigationRef]);

  useEffect(() => {
    if (currentCartData.current.id) {
      updateTabForCurrentCart();
    }
  }, [cart]);

  useEffect(() => {
    const params = {
      records_per_page: 300,
      start_time: moment().subtract(29, "days").toISOString(),
      end_time: moment().toISOString(),
    };
    const onSuccess = async (response) => {
      console.log("Success -- V1 Orders");
      console.log(response.orders);
      let itemMap = {};
      _.forEach(response.data.orders, (order) => {
        _.forEach(order.items, (item) => {
          if (item.reference_id && itemMap[item.reference_id]) {
            itemMap[item.reference_id] =
              itemMap[item.reference_id] + item.quantity;
          } else if (item.reference_id) {
            itemMap[item.reference_id] = item.quantity;
          }
        });
      });
      console.log(itemMap);
      const sortedKeys = Object.keys(itemMap).sort(
        (a, b) => itemMap[b] - itemMap[a]
      );
      console.log(sortedKeys);
      setFavId(sortedKeys)
      await AsyncStorage.setItem("FREQUENT_IDS", JSON.stringify(sortedKeys));
      console.log("---- ITEM MAP ------");
    };
    const onError = (error) => {
      console.log("Error ---- V1 Orders");
      console.log(error);
    };
    EmployeeManager.getV1Orders(params, onSuccess, onError);
  }, []);

  const init = async () => {
    parentRef.current.onScreenPressEvent = () => {
      Keyboard.dismiss();
    };
    let frequentIds = await AsyncStorage.getItem("FREQUENT_IDS");
    let refIds = [];
    if (frequentIds) {
      refIds = JSON.parse(frequentIds).slice(0, 8);
    }
    let params = {
      ref_ids: refIds,
      enableOffline: !isWeb,
      size: 10,
    };
    CategoryDataSources.searchCategoryItem(
      params,
      (response) => {
        const items = _.map(response.hits.hits, "_source");
        // TODO: For Debugging
        console.log(
          "PosManagement->searchCategoryItem->setPopularItems: \n",
          JSON.stringify(response.hits.hits),
        );
        setPopularItems(items);
      },
      (error) => {
        setPopularItems([]);
        console.log("PosManagement->init->error", error);
      }
    );

    setActionHandler("NAVIGATE_TO_CART");
  };

  const updateParentFunctionReferences = () => {
    parentRef.current.onPressTab = onPressTab;
    parentRef.current.onChangeOfHeaderTabs = onChangeOfHeaderTabs;
    parentRef.current.onPressNetworkToggle = (newUserNetworkState) => { };
    parentRef.current.renderRightSideComponent = () => {
      return (
        <View>
          <TabBlockItem
            isActive={true}
            text={I18n.t("screen_messages.returns.start_returns")}
            onPress={() => {
              onPressTab({
                id: "start_returns",
              });
            }}
            onLongPress={(...args) => { }}
            tabisActiveStyles={{
              backgroundColor: theme.secondary,
            }}
            tabisActiveTextExtraStyles={{
              color: theme.white,
            }}
            defaultStyles={{
              backgroundColor: theme.primary,
              paddingHorizontal: 7,
            }}
          />
        </View>
      );
    };
    parentRef.current.onCompleteOrder = (order) => {
      setSearchItems([]);
      resetPopularBar([]);
      if (_.isFunction(extraOptions.current.doQueryReset)) {
        extraOptions.current.doQueryReset();
      }
    };
    parentRef.current.onLongPressTab = (tab) => {
      if (tab.id === "start_new") {
        return;
      }

      let options = [
        I18n.t("screen_messages.common.delete"),
        I18n.t("screen_messages.common.cancel_text"),
      ];

      showActionSheetWithOptions(
        {
          options,
          cancelButtonIndex: options.length,
        },
        (buttonIndex) => {
          if (buttonIndex === 0) {
            removeCart(tab.cartData.id);
          }
        }
      );
    };
  };

  const removeCart = (cartId) => {
    const obj = new DBCartManager();
    if (isWeb) {
      clearCart()
    }
    else {
      obj.exists(`id='${cartId}'`).then((exists) => {
        if (exists) {
          CartDataSources.clearCart(
            { cart_id: cartId },
            (response) => {
              alertBox("", I18n.t("screen_messages.cart.cart_deleted"));
              extraOptions.current.cartIds = _.filter(
                extraOptions.current.cartIds,
                (e) => e !== cartId
              );
              loadMultipleCarts();
              setActionHandler("NAVIGATE_TO_CART");
            },
            (error) => {
              alertBox("", I18n.t("error_messages.cart.failed_to_delete_cart"));
            }
          );
        } else {
          alertBox("", I18n.t("error_messages.cart.cannot_delete_online_cart"));
        }
      });
    }
  };

  const handleActionHandler = () => {
    if (actionHandler === "NAVIGATE_TO_CART") {
      sideNavigationRef?.navigate("CartScreen", {
        onPressRemoveCart: (cart) => {
          prestoConfirm(
            I18n.t("screen_messages.common.btn_confirm"),
            I18n.t("screen_messages.cart.confirm_to_delete"),
            [
              {
                text: I18n.t("screen_messages.common.yes_text"),
                onPress: () => {
                  const cartId = cart.id;
                  removeCart(cartId);
                },
              },
              {
                text: I18n.t("screen_messages.common.no_text"),
              },
            ],
            true,
            () => {
              const cartId = cart.id;
              removeCart(cartId);
            }
          );
        },
      });
      setActionHandler(null);
    }
    if (actionHandler === "REMOVE_CURRENT_CART") {
      setActionHandler(null);
    }
  };

  const unsoldVariations = (product) =>
    _.filter(
      product.variations,
      (variatation) => !(variatation.sold_out || variatation.stock_count === 0)
    );

  const loadMultipleCarts = () => {
    fetchCarts().then(async (carts) => {
      if (isAppOnline()) {
        const [error, response] = await AsyncServerCartManager.fetchCart({});
        if (!error) {
          const exists = _.some(carts, ["id", _.get(response, "data.id")]);

          if (!exists) {
            let onlineCart = response.data;

            let params = {
              user_id: onlineCart.user_id,
            };
            let [error2, response2] = await AsyncClientDataSource.getClient(
              params
            );

            if (!error2) {
              onlineCart.user = response2.data;
            }
            carts = [...carts, onlineCart];
            extraOptions.current.onlineCartExits = true;
          }
        }
      }

      if (carts) {
        for (let index in carts) {
          if (carts[index].user || _.isEmpty(carts[index]?.user_id)) {
            continue;
          }

          let params = {
            user_id: carts[index].user_id,
            enableOffline: !isWeb,
          };

          let [error, response] = await AsyncClientDataSource.getClient(params);

          if (!error) {
            carts[index].user = response.data;
          }
        }
      }
      mergeMultipleCartsWithTabs(carts?.length ? carts : multiCarts);
    });
  };

  const constructTabItem = (id, text, count) => {
    return {
      id: id,
      text: `${_.truncate(text, {
        length: 7,
        omission: "",
      })} - ${count}`,
      tabisActiveTextExtraStyles: {
        color: "#212123",
      },
    };
  };

  const mergeMultipleCartsWithTabs = (carts) => {
    let tempTabs = extraOptions.current.tabs;

    let cartBucket = carts;
    const cartIds = _.map(cartBucket, (e) => e?.id);

    if (cartBucket) {
      for (let index in cartBucket) {
        let cartData = cartBucket[index];
        const cartId = cartData?.id;
        let customerName = _.get(cartData, "user.name");

        if (_.isEmpty(customerName)) {
          customerName = cartId;
        }

        let count = cartData?.items?.length || 0;
        if (existsInCart(cartId)) {
          tempTabs = _.map(tempTabs, (e) => {
            if (e.id !== cartId) {
              return e;
            }

            return {
              ...e,
              ...constructTabItem(cartId, customerName, count),
              cartData: cartData,
            };
          });
        } else {
          pushIdInCart(cartId);
          tempTabs = [
            { ...constructTabItem(cartId, customerName, count), cartData },
            ...tempTabs,
          ];
        }
      }
    }

    if (!_.some(tempTabs, ["id", "start_new"])) {
      tempTabs.push({
        id: "start_new",
        text: I18n.t("screen_messages.common.start_new"),
        isActive: true,
        defaultStyles: {
          backgroundColor: theme.primary,
        },
        tabisActiveStyles: {
          backgroundColor: theme.primary,
        },
        tabisActiveTextExtraStyles: {
          color: theme.white,
        },
      });
    }

    tempTabs = _.filter(
      tempTabs,
      (e) =>
        _.includes(cartIds, e.id) ||
        e.id === "start_new" ||
        e.id === "start_returns"
    );

    let uniqTabs = _.uniqBy(_.compact(tempTabs), "id");
    if (uniqTabs.length > 1) {
      let tabIndex = _.findIndex(
        uniqTabs,
        (e) => e.id === currentCartData.current.id
      );

      if (tabIndex === -1) {
        tabIndex = 0;
      }

      const id = uniqTabs[tabIndex].id;

      uniqTabs = _.map(uniqTabs, (e, index) => {
        if (index === tabIndex) {
          return {
            ...e,
            isActive: true,
            tabisActiveTextExtraStyles: {
              color: "#212123",
            },
          };
        }

        return e;
      });
      setCurrentCartData({ id: id });
    }

    // cart
    refreshCart();
    extraOptions.current.tabs = uniqTabs;
    setTabs(uniqTabs);
  };

  const existsInCart = (id) => _.includes(extraOptions.current.cartIds, id);
  const pushIdInCart = (id) => {
    let items = [...extraOptions.current.cartIds, id];
    extraOptions.current.cartIds = _.uniq(items);
  };

  const onPressTab = async (tab) => {
    let tempTabs = extraOptions.current.tabs;
    tempTabs = _.map(tempTabs, (prevTab, index) => {
      prevTab = { ...prevTab, isActive: false };
      if (
        prevTab.id === tab.id ||
        prevTab.id === "start_new" ||
        prevTab.id === "start_returns"
      ) {
        prevTab = { ...prevTab, isActive: true };
      }
      return prevTab;
    });

    if (tab.id === "start_new") {
      const id = isWeb ? multiCarts[0]?.id : _.random(10000, 100000).toString();
      const cartData = await initNewCart({ id });
      tempTabs = [
        {
          id: id,
          text: `${_.truncate(id, {
            length: 7,
            separator: " ",
          })} - 0`,
          isActive: true,
          tabisActiveTextExtraStyles: {
            color: "#212123",
          },
          cartData,
        },
        ...tempTabs,
      ];
    } else if (tab.id === "start_returns") {
      navigateToOrderReturns();
    } else if (existsInCart(tab.id)) {
      setCurrentCartData({ id: tab.id });
    }
    refreshCart();
    tempTabs = _.uniqBy(tempTabs, "id");
    extraOptions.current.tabs = tempTabs;
    setTabs(tempTabs);
    setSearchItems([]);
    setSearchQuery("");
    if (_.isFunction(extraOptions.current.doQueryReset)) {
      extraOptions.current.doQueryReset();
    }
    setActionHandler("NAVIGATE_TO_CART");
  };

  const initNewCart = ({ id }) => {
    const outletId = null;
    pushIdInCart(id);
    setCurrentCartData({ id });
    return createCart(merchantId, outletId, {
      isOfflineCart: extraOptions.current.onlineCartExits,
    }).then(([error, response]) => {
      return response;
    });
  };

  const resetPopularBar = (items) => {
    if (items.length > 0) {
      setAccordianSection({
        ...accordianSection,
        activeSection: null,
      });
    } else {
      setAccordianSection({
        ...accordianSection,
        activeSection: "POPULAR",
      });
    }
  };
  const displaySearchedItems = (items) => {
    if (!_.isArray(items)) {
      items = [items];
    }
    setSearchItems(items);
    resetPopularBar(items);
  };

  const onAddItemToCart = async (product, variation) => {
    const itemId = product.id;
    const cartId = await _.get(currentCartData, "current.id");
    if (_.isEmpty(cartId)) {
      await onPressTab({
        id: "start_new",
      });
    }
    if (parseInt(product.stock_count) <= 0) {
      alertBox(I18n.t("error_messages.cart.item_not_inventary"));
      return;
    }
    let itemQty = 1;
    let variationId = null;
    if (unsoldVariations(product).length > 0 && variation) {
      variationId = variation.id;
    }
    if (variation) {
      variationId = variation.id;
    }

    const inCartItem = _.find(cart?.items, (e) => {
      if (!_.isEmpty(variationId)) {
        return e.item_id === itemId && e._variation_id === variationId;
      }
      return e.item_id === itemId;
    });

    if (inCartItem) {
      itemQty = inCartItem.quantity + 1;
    }

    if (parseInt(product.stock_count) < itemQty) {
      alertBox(I18n.t("error_messages.cart.item_not_available"));
      return;
    }

    setParentLoading(true);
    const [error, status] = await updateItemInCart(
      itemId,
      variationId,
      itemQty,
      merchantId,
    );

    setParentLoading(false);
    if (!status) {
      alertBox(utils.errorMessage(error));
    }
    updateTabForCurrentCart();
    setTimeout(() => {
      setActionHandler("NAVIGATE_TO_CART");
      if (!isWeb) {
        loadMultipleCarts();
      }
    }, 200);
  };

  const onPressInfo = (item) => {
    if (isMobile || isMobileWeb) openSideNavigation();
    setTimeout(
      () => {
        sideNavigationRef.navigate("ItemDetailScreen", {
          selectedItem: item,
          enableAddToStore: false,
          enableAddToCart: true,
          onPressAddToCart: () => {
            onAddItemToCart(item, null).then(() => {
              if (isMobile || isMobileWeb) {
                sideNavigationRef.goBack();
                return;
              }
              setActionHandler("NAVIGATE_TO_CART");
            });
          },
        });
      },
      (isMobile || isMobileWeb) ? 300 : 0
    );
  };

  const getIsActiveInCart = (item) => {
    const exists = _.some(cart?.items, (e) => e.item_id === item.id);
    return exists;
  };

  const updateTabForCurrentCart = async () => {
    const [error2, response] = await fetchCart();

    if (!error2) {
      const count = _.get(response, "items", []).length;
      let customerName = _.get(response, "_user.name");
      if (_.isEmpty(customerName)) {
        customerName = response?.id;
      }

      setTabs((tempTabs) => {
        tempTabs = _.map(tempTabs, (tabItem) => {
          if (tabItem.id === currentCartData.current.id) {
            return {
              ...tabItem,
              ...constructTabItem(tabItem.id, customerName, count),
            };
          }

          return tabItem;
        });

        return tempTabs;
      });
    }
  };

  const onChangeOfHeaderTabs = (id) => {
    setCurrentCartData({ id: null });
    loadMultipleCarts();
  };

  const openSideNavigation = () => {
    console.log("coming here 1");
    Navigator.emit({
      event: "modal",
      params: {
        screenName: "SIDE_NAVIGATION",
        screenParams: {
          forwardProps: componentProps,
          closeModal: () => Navigator.emit({ event: "goBack" }),
        },
      },
    });
  };

  const navigateToOrderReturns = () => {
    if (isWeb && isDesktop) {
      WebNavigator.emit({
        event: "replace",
        params: {
          screenName: "HOME",
          screenParams: {
            selectedFeature: {
              state: "return_management",
            },
          },
        },
      });
    } else {
      Navigator.emit({
        event: "replace",
        params: {
          screenName: "HOME",
          screenParams: {
            selectedFeature: {
              state: "return_management",
            },
          },
        },
      });
    }
  };

  const isPopularItemsSectionActive = () =>
    accordianSection.activeSection === "POPULAR";

  const getStylesForMainSearchArea = () => {
    let height = !_.isEmpty(popularItems) ? "42%" : "100%";

    if (!_.isEmpty(popularItems) && !isPopularItemsSectionActive()) {
      height = "80%";
    }
    return {
      height,
    };
  };

  const getStylesForPopularAreaContainer = () => {
    return {
      height: isPopularItemsSectionActive()
        ? (300 * Dimensions.get("window").height) / 720
        : 60,
      marginTop: isNoResultsFound ? -20 : isPopularItemsSectionActive() ? 0 : 0,
    };
  };

  return (
    <View
      style={{
        height: "100%",
      }}
    >
      <View style={{ padding: 10 }}>
        <SearchBar
          isOfflineEnabledSearch={true}
          onPressItem={displaySearchedItems}
          onSearchResults={({ searchQuery, autoCompleteItems }) => {
            let val =
              !_.isEmpty(_.trim(searchQuery)) && _.isEmpty(autoCompleteItems);
            setIsNoResultsFound(val);

            if (!_.isEmpty(autoCompleteItems)) {
              setAccordianSection({
                ...accordianSection,
                activeSection: "POPULAR",
              });
            } else {
              setSearchItems([]);
              resetPopularBar([]);
            }
          }}
          doQueryReset={(func) => {
            extraOptions.current.doQueryReset = func;
          }}
        />
      </View>

      {isNoResultsFound ? (
        <View style={{ paddingLeft: 5 }}>
          <PrestoText color={theme.black} fontStyle={"500.medium"} size={16}>
            {_.capitalize(
              I18n.t("screen_messages.products.item_not_available")
            )}
          </PrestoText>
        </View>
      ) : null}
      <View
        style={{
          height: isPopularItemsSectionActive()
            ? (170 * Dimensions.get("window").height) / 720
            : (440 * Dimensions.get("window").height) / 720,
        }}
      >
        <ScrollView style={{ padding: 10 }}>
          <SearchedItems
            items={searchedItems}
            onPress={onAddItemToCart}
            onPressInfo={onPressInfo}
            getIsActive={getIsActiveInCart}
          />
        </ScrollView>
      </View>
      {popularItems ? (
        <View style={getStylesForPopularAreaContainer()}>
          <Pressable
            style={{
              paddingLeft: theme.primaryPadding,
              paddingVertical: theme.primaryPadding,
              flexDirection: "row",
              justifyContent: "space-between",
              // backgroundColor: theme.primary,
              marginBottom: 10,
            }}
            onPress={() => {
              setAccordianSection({
                ...accordianSection,
                activeSection: !isPopularItemsSectionActive()
                  ? "POPULAR"
                  : null,
              });
            }}
          >
            <PrestoText color={theme.dark} fontStyle={"600.bold"} size={16}>
              {_.capitalize(I18n.t("screen_messages.common.popular_products"))}
            </PrestoText>

            <View style={{ paddingRight: theme.primaryPadding }}>
              <PrestoIcon
                style={{
                  color: theme.white,
                  transform: [
                    {
                      rotate: isPopularItemsSectionActive() ? "0deg" : "270deg",
                    },
                  ],
                }}
                color={theme.white}
                icon={<svgs.DownArrow color={theme.dark} />}
              />
            </View>
          </Pressable>
          {isPopularItemsSectionActive() ? (
            <View
              style={{
                paddingHorizontal: 10,
                height: "100%",
                paddingBottom: 10,
              }}
            >
              <SearchedItems
                items={_.uniqBy(popularItems, "id")}
                onPress={onAddItemToCart}
                onPressInfo={onPressInfo}
                getIsActive={getIsActiveInCart}
              />
            </View>
          ) : null}
        </View>
      ) : null}
    </View>
  );
}