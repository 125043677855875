import React, {
  useContext,
  useState,
  useEffect,
  useCallback,
  useRef,
} from "react";
import { View, ScrollView, BackHandler, Platform } from "react-native";
import I18n from "i18n-js";
import ThemeContext from "@presto-contexts/ThemeContext";
import moment from "moment";
import _ from "lodash";
import { usePagination } from "@presto-hooks/appointmentPagination";
import EmployeeManager from "@presto-services/features/employee/EmployeeManager";
import SalesTable from "@presto-screen-components/SalesReports/SalesTable";
import AdvanceSearchBar from "@presto-screen-components/SalesReports/AdvanceSearchBar";
import NavigatorContext from "@presto-contexts/NavigatorContext";
import PrestoText from "@presto-components/PrestoText";
import StylistManager from "@presto-services/features/pos_stylist/StylistManager";
import asyncify from "@presto-common/Asyncify";
import UserContext from "@presto-contexts/UserContext";
import { alertBox } from "@presto-common/Alert";
import utils from "../../utils";
import WebNavigatorContext from "@presto-contexts/WebNavigatorContext";
import PrestoIcon from "@presto-components/PrestoIcon";
import { useAssets } from "presto-react-components";
import FlexRowSpaceAligned from "@presto-components/FlexRowSpaceAligned";

const AsyncStylistManager = asyncify(StylistManager);

export default function SalesReports({
  sideNavigationRef,
  parentRef,
  tabs,
  setTabs,
  parentLoading,
  setParentLoading,
  ...props
}) {
  const { images, svgs } = useAssets();
  const isMobile = utils.isMobile();
  const isMobileWeb = utils.isMobileWeb();
  const isDesktop = utils.isDesktop();
  const isWeb = Platform.OS == 'web'
  const { WebNavigator } = useContext(WebNavigatorContext);
  const { Navigator } = useContext(NavigatorContext);
  const { theme } = useContext(ThemeContext);
  const { user } = useContext(UserContext);
  const extraOptions = useRef({
    canUpdateTotalCount: true,
    tabDateFilterChoosed: false,
    tabId: "today",
  });
  const [totalCount, setTotalCount] = useState(0);
  const [actionHandler, setActionHandler] = useState([]);
  const [activeOrder, setActiveOrder] = useState(null);
  const [currentPageNo, setCurrentPageNo] = useState(1);
  const [currentSortDirection, setCurrentSortDirection] = useState("ASC");
  const [sortField, setSortField] = useState("Purchased On");
  let direction = "ASC"
  const [summaryInfo, setSummaryInfo] = useState({
    orderedCount: 0,
    orderedAmount: 0,
  });

  let fromDate = moment().subtract(3, 'months').startOf('day').toDate(),
    toDate = new Date();
  fromDate.setHours("00", "00", "01");
  toDate.setHours("23", "59", "59");

  const [filterOptions, setFilterOptions] = useState({
    searchValue: "",
    fromDate: fromDate,
    toDate: toDate,
    ...props.filterOptions,
  });

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    //loadSummary();
    loadFirstPage();
  }, []);

  useEffect(() => {
    const backAction = () => {
      let option2 = {
        bgColor: "#3DE7D3",
        state: "customer_management",
        title: "Customers"
      }
      Navigator.emit({
        event: "modal",
        params: {
          screenName: "HOME",
          screenParams: {
            selectedFeature: option2,
          },
        },
      });
      return true;
    };
    const backHandler = BackHandler.addEventListener(
      "hardwareBackPress",
      backAction
    );

    return () => backHandler.remove();
  }, []);


  useEffect(() => {
    if (actionHandler.length) {
      onActionHandle();
    }
  }, [actionHandler, filterOptions]);

  const pushToActionHandler = (obj) => {
    setActionHandler((prev) => {
      return [...prev, { ...obj, id: _.uniqueId() }];
    });
  };

  const onActionHandle = () => {
    const actions = actionHandler;
    let ids = [];

    for (let index in actions) {
      const action = actions[index];
      if (action.type === "LOAD_PAGE") {
        loadFirstPage();
      }

      if (action.type === "ON_PRESS_TAB") {
        onPressTab(action.tab);
      }

      if (action.type === "LOAD_SUMMARY") {
        loadSummary();
      }

      ids.push(action.id);
    }

    setActionHandler((prev) => {
      let items = _.filter(prev, (e) => !_.includes(ids, e.id));
      return items;
    });
  };

  const init = () => {
    parentRef.current.onPressTab = (tab) => {
      pushToActionHandler({ type: "ON_PRESS_TAB", tab });
    };
    const tabStyles = {
      tabisActiveTextExtraStyles: {
        color: "#212123",
      },
    };

    const tempTabs = [
      {
        id: "sales",
        text: I18n.t("screen_messages.sales.sales"),
        isActive: true,
        defaultStyles: {
          backgroundColor: theme.primary,
        },
        tabisActiveStyles: {
          backgroundColor: theme.primary,
        },
        tabisActiveTextExtraStyles: {
          color: theme.white,
        },
      },
    ];

    setTabs(tempTabs);
  };

  const afterPressTab = (tab) => {
    const id = tab.id;
    let fromDate = new Date(),
      toDate = new Date();

    fromDate.setHours("00", "00", "01");
    toDate.setHours("23", "59", "59");

    fromDate = moment().startOf("quarter").startOf("day").toDate();
    toDate = moment().endOf("quarter").endOf("day").toDate();
    console.log(fromDate, toDate, "ljksdfioajsfdiojiewjio")
    if (id === "sales") {
      // fromDate = null;
      // toDate = null;
      navigateToSalesReports();
    }
    // else if (id === "yesterday") {
    //   fromDate = moment().subtract(1, "days").startOf("day").toDate();
    //   toDate = moment().subtract(1, "days").endOf("day").toDate();
    // } else if (id === "this_week") {
    //   fromDate = moment().startOf("week").startOf("day").toDate();
    //   toDate = moment().endOf("week").endOf("day").toDate();
    // } else if (id === "this_month") {
    //   fromDate = moment().startOf("month").startOf("day").toDate();
    //   toDate = moment().endOf("month").endOf("day").toDate();
    // } else if (id === "this_quarter") {
    //   fromDate = moment().startOf("quarter").startOf("day").toDate();
    //   toDate = moment().endOf("quarter").endOf("day").toDate();
    // } else if (id === "custom") {
    //   fromDate = null;
    //   toDate = null;
    //   navigateToAllReports();
    // }

    setFilterOptions({
      ...filterOptions,
      fromDate,
      toDate,
    });

    extraOptions.current.canUpdateTotalCount = true;
    extraOptions.current.tabId = tab.id;

    pushToActionHandler({ type: "LOAD_PAGE" });
  };

  const loadSummary = async () => {
    if (_.isDate(filterOptions.fromDate) && _.isDate(filterOptions.toDate)) {
      const orderedCount = await getTotalOrderedItems();
      const orderedAmount = await getTotalOrderedAmount();

      setSummaryInfo({
        orderedAmount,
        orderedCount,
      });

      sideNavigationRef.navigate("OrderDetailsForReports", {
        initialOrder: null,
        parentLoading,
        setParentLoading,
        summaryInfo: {
          orderedAmount,
          orderedCount,
        },
        generateReport,
      });
    }
  };

  const getTotalOrderedItems = async () => {
    let params = {
      type: "order_delivered",
      start_time: moment(filterOptions.fromDate.toISOString()).format(
        "YYYY-MM-DD"
      ),
      end_time: filterOptions.toDate.toISOString(),
      interval: "day",
      operator_id: "",
    };
    const [error, response] = await AsyncStylistManager.getStylistStats(params);

    if (error) {
      console.log("SalesReport->getTotalOrderedItems->error", error);
      return;
    }

    let data = response.data;
    let values = _.flatMap(data, (item) => {
      return _.map(item, "value");
    });

    let orderedCount = _.sum(values);
    return orderedCount;
  };

  const getTotalOrderedAmount = async () => {
    let params = {
      type: "delivered_revenue",
      start_time: moment(filterOptions.fromDate.toISOString()).format(
        "YYYY-MM-DD"
      ),
      end_time: filterOptions.toDate.toISOString(),
      interval: "day",
      operator_id: "",
    };
    const [error, response] = await AsyncStylistManager.getStylistStats(params);

    if (error) {
      console.log("SalesReport->getTotalOrderedAmount->error", error);
      return;
    }

    let data = response.data;
    let values = _.flatMap(data, (item) => {
      return _.map(item, "value");
    });

    let orderedAmount = _.sum(values);
    return orderedAmount;
  };

  const generateReport = () => {
    let params = {
      reports_task: {
        report_type: "Order",
        status: "",
        date_type: "created_at",
        start_date: filterOptions.fromDate.toISOString(),
        end_date: filterOptions.toDate.toISOString(),
        notification_email_id: user.email,
      },
    };

    EmployeeManager.createReport(
      params,
      (response) => {
        alertBox(
          "Information",
          I18n.t("screen_messages.reports.report_created_success2")
        );
        setParentLoading(false);
      },
      (error) => {
        console.log("Error here", error);
        alertBox(error.title, error.message);
        setParentLoading(false);
      }
    );
  };

  const navigateToAllReports = () => {
    if (isWeb) {
      WebNavigator.emit({
        event: "replace",
        params: {
          screenName: "HOME",
          screenParams: {
            selectedFeature: {
              state: "reports_management",
            },
          },
        },
      });
    }
    Navigator.emit({
      event: "replace",
      params: {
        screenName: "HOME",
        screenParams: {
          selectedFeature: {
            state: "reports_management",
          },
        },
      },
    });
  };

  const navigateToSalesReports = () => {
    Navigator.emit({
      event: "replace",
      params: {
        screenName: "HOME",
        screenParams: {
          selectedFeature: {
            state: "sales_reports_management",
          },
        },
      },
    });
  };

  const onPressTab = (tab) => {
    let tempTabs = [];
    setTabs((prev) => {
      tempTabs = _.cloneDeep(prev);
      return prev;
    });

    tempTabs = _.map(tempTabs, (tabItem) => {
      let ifMainAndSelectedTab = _.includes(["sales", tab.id], tabItem.id);
      let ifOnlyMainTabThanFallback =
        tab.id === "sales" && tabItem.id === "today";
      let isActive = ifMainAndSelectedTab || ifOnlyMainTabThanFallback;
      return {
        ...tabItem,
        isActive,
      };
    });

    setTabs(tempTabs);
    extraOptions.current.tabDateFilterChoosed = true;
    afterPressTab(tab);
  };

  const getInitPageParams = (lastItem) => {
    console.log(lastItem, "rifsdjkafsjiojosifdjoij")
    let params = lastItem
      ? {
        ...(direction == "ASC" && { latest_time: `${_.last(orders).created_at}` }),
        ...(direction == "DESC" && { oldest_time: `${_.last(orders).created_at}` })
      }
      : {};

    if (_.isEmpty(lastItem) || extraOptions.current.tabDateFilterChoosed) {
      if (filterOptions.fromDate) {
        console.log(filterOptions.fromDate, "woiejdsfkjadsfiojsiodfji")
        if (direction !== "ASC") {
          params.latest_time = filterOptions.fromDate.toISOString();
        } else {
          params.oldest_time = filterOptions.toDate.toISOString();
        }
        extraOptions.current.tabDateFilterChoosed = false;
      }
    }

    return params;
  };

  const onInitPageSuccess = (response, success) => {
    let data = _.reverse(_.uniqBy(response.data.orders, "id"));
    if (extraOptions.current.canUpdateTotalCount) {
      extraOptions.current.canUpdateTotalCount = false;
      setTotalCount(response.data.total_count);
    }
    success(data);
  };

  // TODO: need to change the API that will support from to to date filtering
  const initPage = (lastItem, success, failure) => {
    let params = getInitPageParams(lastItem);

    if (!_.isEmpty(filterOptions.searchValue)) {
      let params1 = getInitPageParams(lastItem);
      params1.friendly_id = filterOptions.searchValue;
      console.log(params1, lastItem, "ljkfdsaiosjfaiojidojijdf")
      EmployeeManager.getOrders(
        params1,
        (response) => {
          if (!_.isEmpty(response.data.orders)) {
            onInitPageSuccess(response, success);
          } else {
            let params2 = getInitPageParams(lastItem);
            params2.user_phone = filterOptions.searchValue;
            EmployeeManager.getOrders(
              params2,
              (response) => {
                onInitPageSuccess(response, success);
              },
              (error) => {
                failure(error);
              }
            );
          }
        },
        (error) => {
          failure(error);
        }
      );
    } else {
      EmployeeManager.getOrders(
        params,
        (response) => {
          onInitPageSuccess(response, success);
        },
        (error) => {
          failure(error);
        }
      );
    }
  };

  const onPressPage = (page) => {
    if (page.pageNo === 1) {
      setCurrentPageNo(currentPageNo > 1 ? currentPageNo - 1 : 1);
      const firstItem = _.first(orders);
    } else if (page.pageNo === 2) {
      setCurrentPageNo(currentPageNo + 1);
      loadNextPage();
    }
  };

  const doAdvanceSearch = () => {
    extraOptions.current.canUpdateTotalCount = true;
    loadFirstPage();
  };

  const [orders, loadNextPage, loadFirstPage, load, pageLoaded] = usePagination(
    initPage,
    undefined
  );
  const SortIcon = <svgs.SortIcon color={theme.paragraph} />;

  const navigateToSort = () => {
    Navigator.emit({
      event: "modal",
      params: {
        screenName: "SORT_OPTIONS",
        screenParams: {
          selectedSort: currentSortDirection,
          selectedValue: sortField,
          onSelectSortOptions: onSelectSortOptions,
          goBack,
          currentTab: "Sales",
        },
      },
    });
  };

  const onSelectSortOptions = (value, dir) => {
    setSortField(value);
    setCurrentSortDirection(dir);
    direction = dir;
    // globalStateStore.setKey("SORT_FIELD", value);
    // globalStateStore.setKey("SORT_DIRECTION", direction);
    setTimeout(() => {
      loadFirstPage();
    }, 200);
  };

  const goBack = () => {
    Navigator.emit({ event: "goBack" });
  };

  return (
    <View
      style={{
        backgroundColor: theme.screenBackgroundColor,
      }}
    >
      <FlexRowSpaceAligned styleSet={{ padding: 10, width: "90%" }}>
        <AdvanceSearchBar
          searchValue={filterOptions.searchValue}
          onChange={(text) => {
            setFilterOptions({
              ...filterOptions,
              searchValue: text,
            });
          }}
          onPressIcon={doAdvanceSearch}
        />
        <Pressable
          style={{ width: "10%", height: 36, paddingLeft: 5 }}
          onPress={navigateToSort}
        >
          <PrestoIcon icon={SortIcon} />
        </Pressable>
      </FlexRowSpaceAligned>

      <View style={{ paddingHorizontal: 10, paddingBottom: 200 }}>
        <SalesTable
          activeOrder={activeOrder}
          onPressRow={(order) => {
            if (isMobile || isMobileWeb) {
              Navigator.emit({
                event: "modal",
                params: {
                  screenName: "ORDER_DETAILS_FOR_REPORTS",
                  screenParams: {
                    initialOrder: order.id ? order : null,
                    parentLoading,
                    setParentLoading,
                    summaryInfo,
                    generateReport,
                  },
                },
              });
            } else {
              if (order.id === activeOrder?.id) {
                setActiveOrder(null);
                sideNavigationRef.navigate("OrderDetailsForReports", {
                  initialOrder: null,
                  parentLoading,
                  setParentLoading,
                  summaryInfo,
                  generateReport,
                });
              } else {
                setActiveOrder(order);
                sideNavigationRef.navigate("OrderDetailsForReports", {
                  initialOrder: order,
                  parentLoading,
                  setParentLoading,
                  summaryInfo,
                  generateReport,
                });
              }
            }
          }}
          items={orders}
          onEndReached={() => {
            loadNextPage();
          }}
        />

        <View style={{ paddingTop: 10 }}>
          <View>
            <View
              style={{
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <PrestoText
                color={theme.black}
                fontStyle={"400.normal"}
                size={12}
                extraStyle={{ paddingRight: 5 }}
              >
                Showing
              </PrestoText>

              <PrestoText
                color={theme.black}
                fontStyle={"600.bold"}
                size={12}
                extraStyle={{ paddingRight: 5 }}
              >
                {orders?.length}
              </PrestoText>

              <PrestoText
                color={theme.black}
                fontStyle={"400.normal"}
                size={12}
                extraStyle={{ paddingRight: 5 }}
              >
                of
              </PrestoText>

              <PrestoText
                color={theme.black}
                fontStyle={"600.bold"}
                size={12}
                extraStyle={{ paddingRight: 5 }}
              >
                {totalCount}
              </PrestoText>

              <PrestoText
                color={theme.black}
                fontStyle={"400.normal"}
                size={12}
                extraStyle={{ paddingRight: 5 }}
              >
                Items
              </PrestoText>
            </View>
          </View>
        </View>
      </View>
    </View>
  );
}
