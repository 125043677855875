let itemExpiryMap = {};
import React, { useContext, useState, useReducer, useEffect } from "react";
import {
  View,
  FlatList,
  Pressable,
  Image,
  Platform,
  TouchableOpacity,
  Text,
} from "react-native";
import I18n from "i18n-js";
import ThemeContext from "@presto-contexts/ThemeContext";
import PrestoText from "@presto-components/PrestoText";
import {
  PrestoSolidButton,
  PrestoTextButton,
} from "@presto-components/PrestoButton";
import _ from "lodash";
import moment from "moment";
import { PrestoFramedButton } from "@presto-components/PrestoButton";
import ItemCardModel from "@presto-component-models/ItemCardModel";
import NavigatorContext from "@presto-contexts/NavigatorContext";
import { useActionSheet } from "@expo/react-native-action-sheet";
import EmployeeManager from "@presto-services/features/employee/EmployeeManager";
import asyncify from "@presto-common/Asyncify";
import { alertBox, prestoConfirm } from "@presto-common/Alert";
import { AxiosError } from "axios";
import UserContext from "@presto-contexts/UserContext";
import { useAssets } from "presto-react-components";
import PrestoAmountInput from "@presto-components/AmountInputs/PrestoSmallAmountInput";
import DatePickerComponent from "@presto-components/CalendarComponent/DatePickerComponent";
import CheckBoxWithText from "@presto-components/CheckBoxes/CheckBoxWithText";
import PrestoTextInput from "@presto-components/TextInputs/TextInput";
import PrestoAmountInputWithPlusMinus from "@presto-components/AmountInputs/PrestoAmountInputWithPlusMinus";
import AsyncStorage from "@react-native-community/async-storage";
import ModalMobile from "@presto-components/Modals/Modal.bupos";
import AdvanceSearchBar from "@presto-screen-components/SalesReports/AdvanceSearchBar";
import { usePagination } from "@presto-hooks/pagination";
import { useCatalog } from "@presto-stores/CatalogStore";
import ProductsTable from "@presto-screen-components/PurchaseRequests/ProductsTable.ziphealthPos.js";
import CategoryDataSources from "@presto-datasources/CategoryDataSources";
// import YearMonthSelect from "@presto-components/YearMonthSelect";
import YearMonthSelect from "../../components/yearMonthSelect"
import YearMonthPicker from "@presto-components/yearMonthPicker";
import ItemCard from "@presto-screen-components/ItemCard/ItemCard";
import utils from "../../utils";
import { createNavigationContainerRef } from "@react-navigation/native";
import { OfflineSync } from "@presto-helpers/OfflineSync";
const AsyncEmployeeManager = asyncify(EmployeeManager);

export default function ItemPurchaseRequestTable({
  items,
  onEndReached,
  activeRow,
  onPressDownload,
  sendSelectedToZip,
  selectAll,
  selectZip,
  onSelectAll,
  onSelectZip,
  onPressRow,
  fetchPurchaseRequestItems,
  selectedItemPurchaseRequests,
  setSelectedItemPurchaseRequests,
  navigateToInitialPurchaseRequestDetail,
  // updateItemToPurchaseRequest,
  clearItemPurchaseRequests,
  isIncomingScreen,
  setParentLoading,
  setActiveRow,
  updateItemToPurchaseRequestNetwork,
  addItemToPurchaseRequestViaNetwork,
  draftPoAndAIP,
}) {
  const isWeb = Platform.OS == 'web'
  const sideNavigationRef = createNavigationContainerRef();
  function reducer(state, { payload, type }) {
    switch (type) {
      case "SET_LOADING":
        return { ...state, loading: payload };
      case "SET_RATING":
        return { ...state, selectedRating: payload };
      case "SET_RATING_ID":
        return { ...state, ratingid: payload };
      case "SET_AVAILABLE_RATINGS":
        return { ...state, availableRatings: payload };
    }
  }
  const [state, dispatch] = useReducer(reducer, {
    loading: false,
  });
  const [focusedItem, setFocusedItem] = useState(null);
  const { theme } = useContext(ThemeContext);
  const { user } = useContext(UserContext);
  const { Navigator } = useContext(NavigatorContext);
  const { showActionSheetWithOptions } = useActionSheet();
  const { images: Images, svgs } = useAssets();
  const [modalVisible, setModalVisible] = useState(false);
  const styles = getStyles(theme);
  const [loading, setLoading] = useState(false);
  const [stockForm, setStockForm] = useState({
    batchNumber: "",
    purchasePrice: "",
  });
  const catalog = useCatalog((state) => state.catalog);
  const [selectedYear, setSelectedYear] = useState("2023");
  const [selectedMonth, setSelectedMonth] = useState("5");
  const [picker, setPicker] = useState(null);
  const isMobile = utils.isMobile();
  const isDesktop = utils.isDesktop();
  const isMobileWeb = utils.isMobileWeb();

  const [filterOptions, setFilterOptions] = useState({
    searchValue: "",
    tabId: "all_items",
  });

  const updateItemToPurchaseRequest = async ({ item, purchase_quantity }) => {
    setParentLoading(true);
    let itemPurchaseData = await AsyncStorage.getItem("ITEM_PURCHASE_LIST");
    if (!itemPurchaseData) {
      itemPurchaseData = "[]";
    }
    let itemPurchases = JSON.parse(itemPurchaseData);
    let itemPurchaseRequest = {
      purchase_quantity: item.purchase_quantity,
      quantity:
        parseInt(item.purchase_quantity) *
        parseInt(item.itemPurchaseConfiguration.sale_to_purchase_ratio),
      category_item_id: item?.itemPurchaseRequest?.category_item_id,
    };
    let mIndex = -1;
    _.forEach(itemPurchases, (ipr, index) => {
      if (ipr.category_item_id == itemPurchaseRequest.category_item_id) {
        mIndex = index;
      }
    });
    if (mIndex == -1 && item.purchase_quantity > 0) {
      itemPurchases.push(itemPurchaseRequest);
      item.itemPurchaseRequest = itemPurchaseRequest;
    } else if (item.purchase_quantity > 0) {
      itemPurchases[mIndex] = itemPurchaseRequest;
      item.itemPurchaseRequest = itemPurchaseRequest;
    } else {
      itemPurchases.splice(mIndex, 1);
      item.itemPurchaseRequest = null;
    }

    await AsyncStorage.setItem(
      "ITEM_PURCHASE_LIST",
      JSON.stringify(itemPurchases)
    );
    console.log("----FINAL ------- ");
    console.log(itemPurchases);

    let tempItems = [];
    _.map(selectedItemPurchaseRequests, (selectedItemPurchaseRequest) => {
      console.log("FINAL UPDATED");
      console.log(selectedItemPurchaseRequest);
      if (
        parseInt(
          selectedItemPurchaseRequest.itemPurchaseRequest.purchase_quantity
        ) > 0
      ) {
        tempItems.push(selectedItemPurchaseRequest);
      }
    });
    console.log("FFFF UPDATED");
    console.log(tempItems);
    // setSelectedItemPurchaseRequests([]);
     setParentLoading(false);
    if (selectAll) {
      onSelectAll();
    } else if (selectZip) {
      onSelectZip();
    }
    setParentLoading(false);
    if ((isMobile || isMobileWeb) || item.purchase_quantity <= 0){
      fetchPurchaseRequestItems()
    };
    if (item.purchase_quantity >= 0) {
      console.log("helloPurchase======================================");
      updateItemToPurchaseRequestNetwork({ item: item, purchase_quantity: itemPurchaseRequest.purchase_quantity }, { callback: () => { draftPoAndAIP(item) } })
    }
    fetchPurchaseRequestItems();
  };

  const addItemToPurchaseRequest = async ({ item, purchase_quantity }) => {
    //setParentLoading(true);
    dispatch({ type: "SET_LOADING", payload: true });
    let itemPurchaseData = await AsyncStorage.getItem("ITEM_PURCHASE_LIST");
    if (!itemPurchaseData || itemPurchaseData === "undefined") {
      itemPurchaseData = "[]";
    }
    console.log("Item======================",item);
      let itemPurchases = JSON.parse(itemPurchaseData);
      let itemPurchaseRequest = {
        purchase_quantity: item.purchase_quantity,
        quantity:
          parseInt(item.purchase_quantity) *
          parseInt(item?.itemPurchaseConfiguration?.sale_to_purchase_ratio),
        category_item_id: item.id,
      };
      let mIndex = -1;
      _.forEach(itemPurchases, (ipr, index) => {
        if (ipr.category_item_id == itemPurchaseRequest.category_item_id) {
          mIndex = index;
        }
      });
      if (mIndex == -1 && item.purchase_quantity > 0) {
        itemPurchases.push(itemPurchaseRequest);
        item.itemPurchaseRequest = itemPurchaseRequest;
      } else if (item.purchase_quantity > 0) {
        itemPurchases[mIndex] = itemPurchaseRequest;
        item.itemPurchaseRequest = itemPurchaseRequest;
      } else {
        itemPurchases.splice(mIndex, 1);
        item.itemPurchaseRequest = null;
      }
       await AsyncStorage.setItem(
         "ITEM_PURCHASE_LIST",
         JSON.stringify(itemPurchases)
       );
       console.log("----FINAL ------- ");
       console.log(itemPurchases);
       //setParentLoading(false);
       dispatch({ type: "SET_LOADING", payload: false });
       if (mIndex == -1 && item.purchase_quantity > 0) {
         addItemToPurchaseRequestViaNetwork(
           {
             item: item,
             purchase_quantity: itemPurchaseRequest.purchase_quantity,
           },
           {
             callback: () => {
               draftPoAndAIP(item);
             },
           }
         );
       }
  };

  const searchPage = async (pageNumber, success, failure) => {
    let text = filterOptions.searchValue?.replace(/\//g, "//");
    let params = {
      category_id: "",
      name_asc: true,
      page: pageNumber,
      //catalog_id: catalog?.id,
      search_string: text,
      size: 25,
      or_reference_id: text,
      or_therapy_area_simple: text,
      or_molecule: text,
      or_company_name: text,
      enableOffline: !isWeb,
    };

    if (filterOptions.tabId === "low_stock") {
      params.stockCountRange = {
        lte: 10,
      };
    }

    CategoryDataSources.fuzzySearchCategoryItem(
      params,
      async (response) => {
        let array = _.map(response.hits.hits, (item) => item?._source);
        if (filterOptions.tabId === "low_stock") {
          //array = _.filter(array, (e) => e.stock_count <= 10);
        }
        let itemPurchaseData = await AsyncStorage.getItem("ITEM_PURCHASE_LIST");
        if (!itemPurchaseData) {
          itemPurchaseData = "[]";
        }
        if(itemPurchaseData!=="undefined"){

        let itemPurchases = JSON.parse(itemPurchaseData);
        console.log("Item purchases ==== ");
        console.log(itemPurchaseData);

        array.forEach((arrayItem) => {
          arrayItem.itemPurchaseConfiguration = {
            sale_unit_display_name: arrayItem.other_data["sale unit"],
            purchase_unit_display_name: arrayItem.other_data["purchase unit"],
            sale_to_purchase_ratio: parseInt(
              arrayItem.other_data["sale to purchase ratio"]
            ),
          };
          if (
            arrayItem.other_data.zip_supplied == "1" ||
            arrayItem.other_data.zip_supplied == 1
          ) {
            arrayItem.itemPurchaseConfiguration.supplier_prices = [
              {
                name: "Zip",
              },
            ];
          } else {
            arrayItem.itemPurchaseConfiguration.supplier_prices = [
              {
                name: "Others",
              },
            ];
          }
          _.forEach(itemPurchases, (itemPurchaseRequest) => {
            if (itemPurchaseRequest.category_item_id == arrayItem.id) {
              arrayItem.itemPurchaseRequest = itemPurchaseRequest;
            }
          });
        });
        }
        console.log(array[0]);
        success(array);
      },
      (error) => {
        failure(error);
      }
    );
  };

  const [
    results,
    loadNextPage,
    loadFirstPage,
    load,
    pageLoaded,
  ] = usePagination(searchPage);

  const doAdvanceSearch = () => {
    loadFirstPage();
  };

  const openAddNewModal = function () {
    setModalVisible(true);
  };

  useEffect(() => {
    fetchPurchaseRequestItems();
  }, []);

  const renderModal = () => {
    return (
      <ModalMobile
        ModelHeight={"70%"}
        // ModelWidth={"99%"}
        visible={modalVisible}
        onDismiss={() => setModalVisible(false)}
        modalBackgroundColor="#FFF"
        modalContainerStyles={styles.modalContainer}
        content={
          <>
            <View
              style={{
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  flexWrap: "wrap",
                  marginBottom: 20,
                }}
              >
                <PrestoText
                  size={28}
                  color={theme.black}
                  fontStyle={"600.bold"}
                >
                  {I18n.t("screen_messages.inventory.choose_inventory")}
                </PrestoText>
                <PrestoText
                  size={28}
                  color={theme.black}
                  fontStyle={"600.bold"}
                ></PrestoText>
                <View
                  style={{
                    alignItems: "flex-end",
                  }}
                >
                  <PrestoSolidButton
                    size="extra-large"
                    buttonStyle="secondary"
                    titleColor={theme.white}
                    title={I18n.t("screen_messages.common.add_selected")}
                    paddingLeft="20"
                    titleExtraStyle={{
                      textTransform: "capitalize",
                      width: 200,
                    }}
                    onPress={() => {
                      fetchPurchaseRequestItems();
                      setModalVisible(false);
                    }}
                  />
                </View>
              </View>
              <View
                style={{
                  backgroundColor: theme.screenBackgroundColor,
                  flex: 1,
                  flexDirection: "column",
                  height: "100%",
                  padding: 10,
                }}
              >
                <View style={{ padding: 10 }}>
                  {(isMobile || isMobileWeb) && (
                    <PrestoText
                      size={10}
                      color={theme.black}
                      fontStyle={"400.regular"}
                      extraStyle={{ paddingVertical: 10 }}
                    >
                      {I18n.t("screen_messages.common.search_by2")}
                    </PrestoText>
                  )}
                  <AdvanceSearchBar
                    isModal={(isMobile || isMobileWeb) && true}
                    placeHolder={
                      (isMobile || isMobileWeb)
                        ? I18n.t("screen_messages.common.search")
                        : I18n.t("screen_messages.common.search_by2")
                    }
                    searchValue={filterOptions.searchValue}
                    onChange={(text) => {
                      setFilterOptions({
                        ...filterOptions,
                        searchValue: text,
                      });
                    }}
                    onPressIcon={doAdvanceSearch}
                  />
                </View>
                <View style={{ ...(isWeb) && { height: "80%" } }}>
                  <ProductsTable
                    activeRow={activeRow}
                    onPressActionButton={({ item }) => {
                      let tempItem = {
                        product: item,
                        supplierPrice: null,
                        itemPurchaseRequest: null,
                      };
                      onPressActionButton({ item: tempItem });
                    }}
                    onAmountChange={({ item, quantity }) => {
                      //To store the count which can be used to update
                      item.purchase_quantity = quantity;
                    }}
                    addItemToPurchaseRequest={addItemToPurchaseRequest}
                    updateItemToPurchaseRequest={updateItemToPurchaseRequest}
                    onPressRow={(product) => {
                      let tempItem = null;
                      if (product.id !== activeRow?.id) {
                        tempItem = product;
                      }
                      setActiveRow(tempItem);

                      if (tempItem) {
                        let isAddedToBucket = false;
                        sideNavigationRef.navigate("ItemDetailScreen", {
                          selectedItem: tempItem,
                          onClickAdd: () => { },
                          enableAddToStore: false,
                          enableAddOrderToRequest: !isAddedToBucket,
                          enableAddOrderToRequest: !isAddedToBucket,
                          onAddOrderToRequest: (item) => {
                            let tempItem = {
                              product: item,
                              supplierPrice: null,
                              itemPurchaseRequest: null,
                            };
                            return onPressActionButton({ item: tempItem });
                          },
                          enablePurchaseDetailButton: isAddedToBucket,
                          onPressPurchaseDetailButton: (item) => {
                            setActionHandler({
                              type: "NAVIGATE_TO_INTIAL_PURCHASE_REQUEST_DETAIL",
                            });
                          },
                        });
                      } else {
                        setActionHandler({
                          type: "NAVIGATE_TO_INTIAL_PURCHASE_REQUEST_DETAIL",
                        });
                      }
                    }}
                    onPressDownload={onPressDownload}
                    sendSelectedToZip={sendSelectedToZip}
                    items={results}
                    onEndReachedThreshold={0.0}
                    onEndReached={
                      results.length > 0
                        ? () => {
                          loadNextPage();
                        }
                        : undefined
                    }
                  />
                </View>
              </View>
            </View>
          </>
        }
      />
    );
  };
  const inwardSelected = function (items) {
    console.log("Received");
    console.log(JSON.stringify(items));
    let completed = 0;
    let enteredCount = 0;
    let valid = true;
    let message = "";
    items.forEach((itemObject) => {
      let item = itemObject.product;
      if (item.purchase_quantity) {
        enteredCount += 1;
        item.expiry = item.expiry || itemExpiryMap[item.id];
        if (!item.expiry) {
          valid = false;
          message = I18n.t("screen_messages.products.add_expiry");
        } else if (!item.purchase_price_value) {
          valid = false;
          message = I18n.t("screen_messages.products.add_purchase_price");
        }
      }
    });

    if (enteredCount == 0) {
      alert(I18n.t("screen_messages.products.add_purchase_qty"));
      return;
    }

    if (!valid) {
      alert(message);
      return;
    }
    setLoading(true);
    setParentLoading(true);
    items.forEach((itemObject) => {
      let item = itemObject.product;
      if (item.purchase_quantity) {
        console.log("-- For Item -- ");
        console.log(itemObject);
        let itemPurchaseRequest = itemObject.itemPurchaseRequest;
        const params = {
          inventory_batch: {
            item_id: item.id,
            batch_number: item.batch_number || "NO_BATCH",
            expiry_date: item.expiry,
            initial_quantity:
              parseInt(item.purchase_quantity) *
              parseInt(
                itemObject.itemPurchaseConfiguration.sale_to_purchase_ratio
              ),
            remaining_quantity:
              parseInt(item.purchase_quantity) *
              parseInt(
                itemObject.itemPurchaseConfiguration.sale_to_purchase_ratio
              ),
            purchase_price: item.purchase_price_value,
          },
        };
        console.log("ITEM");
        console.log(params);
        let other_data = item.other_data;
        if (other_data.avg_purchase_price && item.purchase_price_value) {
          let avgpp =
            (parseFloat(other_data.avg_purchase_price) +
              parseFloat(item.purchase_price_value) /
              (parseInt(item.purchase_quantity) *
                parseInt(
                  itemObject.itemPurchaseConfiguration.sale_to_purchase_ratio
                ))) /
            2;
          other_data.avg_purchase_price = avgpp.toFixed(2);
        } else {
          let avgpp =
            parseFloat(item.purchase_price_value) /
            (parseInt(item.purchase_quantity) *
              parseInt(
                itemObject.itemPurchaseConfiguration.sale_to_purchase_ratio
              ));
          other_data.avg_purchase_price = avgpp.toFixed(2);
        }
        if (item.expiry) {
          other_data.nearest_expiry = moment(item.expiry).format("YYYY-MM-DD");
        }
        other_data.last_purchase_price = (
          parseFloat(item.purchase_price_value) /
          (parseInt(item.purchase_quantity) *
            parseInt(
              itemObject.itemPurchaseConfiguration.sale_to_purchase_ratio
            ))
        ).toFixed(2);
        delete other_data["merchant_data"];
        let params1 = {
          category_item: {
            is_available: true,
            name: item.name,
            other_data: JSON.stringify(other_data),
          },
          price: {
            base_price: item.price.base_price * 100,
          },
        };
        let params2 = {};
        params2.id = item.id;
        params2.body = params1;
        console.log(JSON.stringify(params2));
        console.log("Update ==== ");
        console.log(params);
        EmployeeManager.updateCategoryItem(
          params2,
          (res) => {
            console.log("Result");
            console.log(res);
          },
          (err) => {
            console.log(err);
            console.log("Error In Update");
          }
        );

        AsyncEmployeeManager.inventoryBatchCreate(params).then(
          async ([error, response]) => {
            console.log("Error - ");
            console.log(error);
            console.log(response);

            /*AsyncEmployeeManager.itemPurchaseRequestDelete({
              id: itemPurchaseRequest.id,
            }).then(([error, response]) => {
              completed = completed + 1;
              console.log(
                "=== Completed -=== " + completed + ":: " + items.length
              );
              if (completed == enteredCount) {
                console.log("Sending request now");
                fetchPurchaseRequestItems();
              }
            });*/
            completed = completed + 1;
            let itemPurchaseData = await AsyncStorage.getItem(
              "ITEM_PURCHASE_LIST"
            );
            if (!itemPurchaseData) {
              itemPurchaseData = "[]";
            }
            let itemPurchases = JSON.parse(itemPurchaseData);
            let mIndex = -1;
            _.forEach(itemPurchases, (ipr, index) => {
              if (
                ipr.category_item_id == itemPurchaseRequest.category_item_id
              ) {
                mIndex = index;
              }
            });
            itemPurchases.splice(mIndex, 1);
            await AsyncStorage.setItem(
              "ITEM_PURCHASE_LIST",
              JSON.stringify(itemPurchases)
            );

            let itemIds = await AsyncStorage.getItem("ITEM_IDS");
            if (!itemIds) {
              itemIds = "[]";
            }
            let ids = JSON.parse(itemIds);
            if (ids.indexOf(itemPurchaseRequest.category_item_id) == -1) {
              ids.push(itemPurchaseRequest.category_item_id);
              await AsyncStorage.setItem("ITEM_IDS", JSON.stringify(ids));
            }

            if (completed == enteredCount) {
              console.log("Sending request now");
              fetchPurchaseRequestItems();
              setParentLoading(false);
              setLoading(false);
              setTimeout(function () {
                const offlineSync = new OfflineSync(user);
                const force = true;
                offlineSync.syncCatalog(force);
                console.log("Sync in progress");
              }, 20000);
            }
          }
        );
      }
    });
  };

  const hasPermissionToAccess = (key) =>
    _.get(user, `other_data.permissions.${key}`) === true;

  const hasPurchaseOrderManagementPermission = hasPermissionToAccess(
    "purchase_order_management"
  );

  const tableColumns = [
    {
      title: I18n.t("screen_messages.products.sku_1"),
      width: hasPurchaseOrderManagementPermission ? "23%" : "23%",
      textAlign: "left",
      thType: "highlighted",
      viewStyles: {
        alignItems: "center",
        paddingLeft: theme.primaryPadding,
      },
    },
    {
      title: I18n.t("screen_messages.orders.in_stock"),
      width: "11%",
      textAlign: "center",
      thType: "highlighted",
      viewStyles: {
        alignItems: "center",
        paddingLeft: theme.primaryPadding,
      },
    },
    {
      title: I18n.t("screen_messages.purchase_configurations.last_month_sale"),
      width: "11%",
      textAlign: "center",
      thType: "highlighted",
      viewStyles: {
        alignItems: "center",
        paddingLeft: theme.primaryPadding,
      },
    },
    {
      title: I18n.t("screen_messages.purchase_configurations.supply_unit"),
      width: "11%",
      textAlign: "center",
      thType: "highlighted",
      viewStyles: {
        alignItems: "center",
        paddingLeft: theme.primaryPadding,
      },
    },
    {
      title: I18n.t(
        "screen_messages.purchase_configurations.purchase_supply_unit"
      ),
      width: "11%",
      textAlign: "center",
      thType: "highlighted",
      viewStyles: {
        alignItems: "center",
        paddingLeft: theme.primaryPadding,
      },
    },
    {
      title: I18n.t("screen_messages.purchase_configurations.available_stock"),
      width: "11%",
      textAlign: "center",
      thType: "highlighted",
      viewStyles: {
        alignItems: "center",
        paddingLeft: theme.primaryPadding,
      },
    },
    {
      title: I18n.t("screen_messages.purchase_configurations.sold_by"),
      width: "11%",
      textAlign: "center",
      thType: "highlighted",
      viewStyles: {
        alignItems: "center",
        paddingLeft: theme.primaryPadding,
      },
    },
    {
      title: I18n.t("screen_messages.purchase_configurations.actions"),
      width: "11%",
      textAlign: "center",
      thType: "highlighted",
      viewStyles: {
        alignItems: "center",
        paddingLeft: theme.primaryPadding,
      },
    },
  ];

  const tableColumnsForIncoming = [
    {
      title: I18n.t("screen_messages.products.sku_1"),
      width: hasPurchaseOrderManagementPermission ? "15%" : "15%",
      textAlign: "left",
      thType: "highlighted",
      viewStyles: {
        alignItems: "center",
        paddingLeft: 10,
      },
    },
    {
      title: I18n.t("screen_messages.products.expiry"),
      width: "10%",
      textAlign: "center",
      thType: "highlighted",
      viewStyles: {
        alignItems: "center",
        paddingLeft: theme.primaryPadding,
      },
    },
    {
      title: I18n.t("screen_messages.products.batch_invoice"),
      width: "11%",
      textAlign: "center",
      thType: "highlighted",
      viewStyles: {
        alignItems: "center",
        paddingLeft: theme.primaryPadding,
      },
    },
    {
      title: I18n.t("screen_messages.products.product_cost"),
      width: "10%",
      textAlign: "center",
      thType: "highlighted",
      viewStyles: {
        alignItems: "center",
        paddingLeft: theme.primaryPadding,
      },
    },
    {
      title: I18n.t("screen_messages.products.product_tax"),
      width: "10%",
      textAlign: "center",
      thType: "highlighted",
      viewStyles: {
        alignItems: "center",
        paddingLeft: theme.primaryPadding,
      },
    },
    {
      title: I18n.t("screen_messages.products.quantity_ordered"),
      width: "10%",
      textAlign: "center",
      thType: "highlighted",
      viewStyles: {
        alignItems: "center",
        paddingLeft: theme.primaryPadding,
      },
    },
    {
      title: I18n.t("screen_messages.products.quantity_received"),
      width: "10%",
      textAlign: "center",
      thType: "highlighted",
      viewStyles: {
        alignItems: "center",
        paddingLeft: theme.primaryPadding,
      },
    },
    {
      title: I18n.t("screen_messages.products.sold_by"),
      width: "9%",
      textAlign: "center",
      thType: "highlighted",
      viewStyles: {
        alignItems: "center",
        paddingLeft: theme.primaryPadding,
      },
    },
    {
      title: I18n.t("screen_messages.purchase_configurations.actions"),
      width: "11%",
      textAlign: "center",
      thType: "highlighted",
      viewStyles: {
        alignItems: "center",
        paddingLeft: theme.primaryPadding,
      },
    },
  ];

  const showActionSheet = (title, options, callback) => {
    let txt = _.map(options, "label");
    showActionSheetWithOptions(
      {
        options: [...txt, I18n.t("screen_messages.common.cancel_text")],
        message: title,
        cancelButtonIndex: options.length ? options.length + 1 : 0,
      },
      (buttonIndex) => {
        let item = _.get(options, buttonIndex);
        if (item) {
          callback(item);
        }
      }
    );
  };

  const isProductSelected = (product) =>
    _.some(selectedItemPurchaseRequests, (e) => e.product.id === product.id);

  const getItemPurchaseRequest = (item) =>
    _.find(_.map(items, "itemPurchaseRequest"), ["category_item_id", item.id]);

  const onSelectSupplierForProduct = (
    product,
    itemPurchaseConfiguration,
    supplierPrice
  ) => {
    let isSelected = isProductSelected(product);
    let tempItems = _.cloneDeep(selectedItemPurchaseRequests);
    tempItems = isSelected
      ? _.filter(tempItems, (e) => e.product.id !== product.id)
      : _.concat(tempItems, {
        supplierPrice: supplierPrice?.supplierPrice,
        itemPurchaseConfiguration,
        product,
        itemPurchaseRequest: getItemPurchaseRequest(product),
      });
    setSelectedItemPurchaseRequests(tempItems);
  };

  const onSelectPurchaseRequestItem = ({ item }) => {
    console.log(item);

    console.log("=====ITEM===");
    if (isProductSelected(item)) {
      onSelectSupplierForProduct(item, null);
      if (_.isFunction(navigateToInitialPurchaseRequestDetail)) {
        navigateToInitialPurchaseRequestDetail();
      }
    } else {
      setParentLoading(true);
      AsyncEmployeeManager.itemPurchaseConfigurationShow({ id: item.id }).then(
        ([error, response]) => {
          setParentLoading(false);
          if (error) {
            let message =
              error instanceof AxiosError
                ? _.get(error, "response.data.message")
                : error.message;
            return alertBox("", message);
          }

          let itemPurchaseConfiguration = _.get(response, "data", null);
          let supplierPrices = _.get(
            itemPurchaseConfiguration,
            "supplier_prices",
            []
          );
          if (supplierPrices) {
            let options = _.map(supplierPrices, (supplierPrice) => {
              return {
                label: `${supplierPrice.name} - ${supplierPrice.purchase_price}`,
                supplierPrice,
              };
            });
            let supplierPrice = options[0];
            options.forEach((opt) => {
              if (
                opt.supplierPrice.name?.indexOf("Zip") >= 0 ||
                opt.supplierPrice.supplier_id == "64c8ff28179fb06bfbe3b6f9" ||
                opt.supplierPrice.supplier_id == "65845fdd179fb0a4e0de327e"
              ) {
                supplierPrice = opt;
              }
            });
            onSelectSupplierForProduct(
              item,
              itemPurchaseConfiguration,
              supplierPrice
            );
            if (_.isFunction(navigateToInitialPurchaseRequestDetail)) {
              navigateToInitialPurchaseRequestDetail();
            }
          }
        }
      );
    }
  };

  const onPressActionButton = ({ item }) => {
    if (isProductSelected(item)) {
      onSelectSupplierForProduct(item, null);
      if (_.isFunction(navigateToInitialPurchaseRequestDetail)) {
        navigateToInitialPurchaseRequestDetail();
      }
    } else {
      setParentLoading(true);
      AsyncEmployeeManager.itemPurchaseConfigurationShow({ id: item.id }).then(
        ([error, response]) => {
          setParentLoading(false);
          if (error) {
            let message =
              error instanceof AxiosError
                ? _.get(error, "response.data.message")
                : error.message;
            return alertBox("", message);
          }

          let itemPurchaseConfiguration = _.get(response, "data", null);
          let supplierPrices = _.get(
            itemPurchaseConfiguration,
            "supplier_prices",
            []
          );

          if (supplierPrices) {
            let options = _.map(supplierPrices, (supplierPrice) => {
              return {
                label: `${supplierPrice.name} - ${supplierPrice.purchase_price}`,
                supplierPrice,
              };
            });
            let title = I18n.t("screen_messages.common.choose_one");
            let callback = (supplierPrice) => {
              onSelectSupplierForProduct(
                item,
                itemPurchaseConfiguration,
                supplierPrice
              );
              if (_.isFunction(navigateToInitialPurchaseRequestDetail)) {
                navigateToInitialPurchaseRequestDetail();
              }
            };
            showActionSheet(title, options, callback);
          }
        }
      );
    }
  };

  const onPressDelete = ({ item, itemPurchaseRequest }) => {
    const cb = (proceed = true) => {
      if (proceed) {
        setParentLoading(true);
        AsyncEmployeeManager.itemPurchaseRequestDelete({
          id: itemPurchaseRequest.id,
        }).then(([error, response]) => {
          setParentLoading(false);
          if (error) {
            let message =
              error instanceof AxiosError
                ? _.get(error, "response.data.message")
                : error.message;
            return alertBox("", message);
          }

          if (_.isFunction(fetchPurchaseRequestItems)) {
            fetchPurchaseRequestItems();
          }

          alertBox(
            "",
            I18n.t("success_messages.item_purchase_requests.removed")
          );
        });
      }
    };

    const title = I18n.t("screen_messages.common.btn_confirm");
    const message = I18n.t(
      "error_messages.item_purchase_requests.confirm_remove"
    );
    const buttons = [
      {
        text: I18n.t("screen_messages.clear_gift_cart.yes_text"),
        onPress: cb,
      },
      {
        text: I18n.t("screen_messages.clear_gift_cart.no_text"),
        onPress: () => { },
      },
    ];
    const isCancelable = false;
    const callBack = cb;

    prestoConfirm(title, message, buttons, isCancelable, callBack);
  };

  const RenderItemHeader = () => {
    return (
      <View
        style={{
          width: "100%",
          height: 60,
          paddingVertical: 5,
          flexDirection: "row",
          justifyContent: "space-between",
          backgroundColor: theme.screenBackgroundColor,
        }}
      >
        {_.map(tableColumns, (column, index) => {
          let viewStyles = {
            height: 50,
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            ...column?.viewStyles,
          };

          let textProps = {
            extraStyle: { paddingLeft: 20 },
            color: theme.paragraph,
            fontStyle: "400.bold",
            size: 10,
          };

          if (column.thType === "highlighted") {
            viewStyles = {
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            };
            textProps = {
              extraStyle: {
                paddingLeft: theme.primaryPadding,
                textAlgin: column.textAlign,
                width: "100%",
              },
              color: theme.black,
              fontStyle: "400.bold",
              size: 10,
            };
          }

          return (
            <View
              key={index}
              style={{
                ...viewStyles,
                width: column.width,
              }}
            >
              <PrestoText
                {...textProps}
                extraStyle={{
                  textAlign: textProps.extraStyle.textAlgin,
                  width: "100%",
                }}
              >
                {column.title}
              </PrestoText>
            </View>
          );
        })}
      </View>
    );
  };

  const RenderIncomingItemHeader = () => {
    return (
      <View
        style={{
          width: "100%",
          height: 60,
          paddingVertical: 5,
          flexDirection: "row",
          justifyContent: "space-between",
          backgroundColor: theme.screenBackgroundColor,
        }}
      >
        {_.map(tableColumnsForIncoming, (column, index) => {
          let viewStyles = {
            height: 50,
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "flex-start",
            ...column?.viewStyles,
          };

          let textProps = {
            extraStyle: { paddingLeft: 20 },
            color: theme.paragraph,
            fontStyle: "400.bold",
            size: 10,
          };

          if (column.thType === "highlighted") {
            viewStyles = {
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            };
            textProps = {
              extraStyle: {
                paddingLeft: column?.viewStyles.primaryPadding,
                textAlgin: column.textAlign,
                width: "100%",
              },
              color: theme.black,
              fontStyle: "400.bold",
              size: 10,
            };
          }

          return (
            <View
              key={index}
              style={{
                ...viewStyles,
                width: column.width,
                paddingLeft: column.viewStyles.paddingLeft,
              }}
            >
              <PrestoText
                {...textProps}
                extraStyle={{
                  textAlign: textProps.extraStyle.textAlgin,
                  width: "100%",
                }}
              >
                {column.title}
              </PrestoText>
            </View>
          );
        })}
      </View>
    );
  };

  const defaultPicUrl =
    "https://images.unsplash.com/photo-1584308666744-24d5c474f2ae?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1660&q=80";

  const getPicUrl = (item) => (item.pic_url ? item.pic_url : defaultPicUrl);

  const renderItem = ({
    item,
    itemPurchaseRequest,
    itemPurchaseConfiguration,
    index,
  }) => {
    const product = new ItemCardModel({ product: item });
    let id = product.productId;
    let name = product.name;
    let molecules = _.get(item, "other_data.molecule");
    let manufacturer = _.get(item, "other_data.company_name");
    let quantity = itemPurchaseRequest.purchase_quantity;

    const supplierPrice = _.get(
      _.find(
        selectedItemPurchaseRequests,
        (e) => _.get(e, "product.id") == item.id
      ),
      "supplierPrice"
    );

    const isActive = _.get(activeRow, "id") === item.id;
    const isAddedToBucket = !_.isEmpty(supplierPrice);
    let bgColor = isActive ? "#6D707B" : theme.white;
    if (isAddedToBucket) {
      bgColor = theme.primary;
    }
    const textColor2 =
      isActive || isAddedToBucket ? theme.white : theme.darkText;
    const textColor4 = isActive || isAddedToBucket ? theme.white : "#666666";
    const selectBtnColor = hasPurchaseOrderManagementPermission
      ? isAddedToBucket || isActive
        ? theme.white
        : theme.primary
      : isActive || isAddedToBucket
        ? theme.white
        : theme.error;
    const selectBtnTitle = hasPurchaseOrderManagementPermission
      ? isAddedToBucket
        ? I18n.t("screen_messages.common.remove")
        : I18n.t("screen_messages.common.select")
      : I18n.t("screen_messages.common.delete");

    const isZipSupplied = function (purchaseConfig) {
      let zipFound = false;
      purchaseConfig?.supplier_prices?.forEach((sp) => {
        if (
          sp.supplier_id ||
          sp.name?.indexOf("Zip") >= 0 ||
          sp.supplier_id == "64c8ff28179fb06bfbe3b6f9" ||
          sp.supplier_id == "65845fdd179fb0a4e0de327e" ||
          sp.supplier_id == "65cc70fa179fb01ce6ad8745" ||
          sp.supplier_id == "65cdd88d179fb03655ed2eea" ||
          sp.supplier_id == "65cddd19179fb03655ed4c75" ||
          sp.supplier_id == "65cd9e96179fb02ff0a255ef"
        ) {
          zipFound = true;
        }
      });
      return zipFound;
    };
    // if (parseInt(itemPurchaseRequest.purchase_quantity) == 0) {
    //   return null;
    // }
    // return (
    //   <Pressable
    //     key={`${item.id}-${index}`}
    //     onPress={() => onPressRow(item)}
    //     style={{
    //       width: "100%",
    //       backgroundColor: bgColor,
    //       borderRadius: 12,
    //       paddingHorizontal: theme.primaryPadding,
    //       paddingVertical: 10,
    //       flexDirection: "row",
    //       marginBottom: 5,
    //     }}
    //   >
    //     <View
    //       style={{
    //         width: hasPurchaseOrderManagementPermission ? "23%" : "23%",
    //         flexDirection: "column",
    //         borderRightWidth: 1,
    //         borderRightColor: "#DBDBDB",
    //       }}
    //     >
    //       <PrestoText color={textColor2} fontStyle="400.normal">
    //         {name}
    //       </PrestoText>

    //       <PrestoText size={10} color={textColor4} fontStyle="400.normal">
    //         {molecules}
    //       </PrestoText>
    //     </View>

    //     <View
    //       style={{
    //         width: hasPurchaseOrderManagementPermission ? "11%" : "11%",
    //         flexDirection: "column",
    //         borderRightWidth: 1,
    //         borderRightColor: "#DBDBDB",
    //         alignItems: "center",
    //         justifyContent: "center",
    //       }}
    //     >
    //       <PrestoText
    //         extraStyle={{ textAlign: "right" }}
    //         color={textColor4}
    //         fontStyle="600.semibold"
    //       >
    //         {item.stock_count}
    //       </PrestoText>
    //       <PrestoText size={10} color={textColor4} fontStyle="400.normal">
    //         {itemPurchaseConfiguration?.sale_unit_display_name}
    //       </PrestoText>
    //     </View>

    //     <View
    //       style={{
    //         width: "11%",
    //         flexDirection: "column",
    //         borderRightWidth: 1,
    //         borderRightColor: "#DBDBDB",
    //         marginRight: 0,
    //         alignItems: "center",
    //         justifyContent: "center",
    //       }}
    //     >
    //       <PrestoText
    //         extraStyle={{ textAlign: "center" }}
    //         color={textColor4}
    //         fontStyle="600.semibold"
    //       ></PrestoText>
    //     </View>

    //     <View
    //       style={{
    //         width: "11%",
    //         flexDirection: "column",
    //         borderRightWidth: 1,
    //         borderRightColor: "#DBDBDB",
    //         marginRight: 0,
    //         alignItems: "center",
    //         justifyContent: "center",
    //       }}
    //     >
    //       <PrestoText
    //         extraStyle={{ textAlign: "center" }}
    //         color={textColor4}
    //         fontStyle="600.semibold"
    //       >
    //         {itemPurchaseConfiguration?.purchase_unit_display_name}
    //       </PrestoText>
    //       <PrestoText size={10} color={textColor4} fontStyle="400.normal">
    //         Contains {itemPurchaseConfiguration?.sale_to_purchase_ratio}{" "}
    //         {itemPurchaseConfiguration?.sale_unit_display_name}
    //       </PrestoText>
    //     </View>
    //     <View
    //       style={{
    //         width: hasPurchaseOrderManagementPermission ? "11%" : "11%",
    //         flexDirection: "column",
    //         borderRightWidth: 1,
    //         borderRightColor: "#DBDBDB",
    //         marginRight: 10,
    //         alignItems: "center",
    //         justifyContent: "center",
    //       }}
    //     >
    //       <PrestoAmountInputWithPlusMinus
    //         value={quantity}
    //         selected={isAddedToBucket}
    //         clearTextOnFocus={false}
    //         onAmountChange={(quantity) => {
    //           if (quantity == "" && quantity != 0 && quantity != "0") {
    //             return;
    //           }
    //           itemPurchaseRequest.purchase_quantity = quantity;
    //           //update quantity now
    //           let item = {};
    //           item.itemPurchaseRequest = itemPurchaseRequest;
    //           item.itemPurchaseConfiguration = itemPurchaseConfiguration;
    //           item.purchase_quantity = quantity;
    //           item.id = id;
    //           updateItemToPurchaseRequest({ item }, { quantity });
    //         }}
    //         placeholder=""
    //         keyboardType="numeric"
    //       ></PrestoAmountInputWithPlusMinus>
    //     </View>

    //     <View
    //       style={{
    //         width: "11%",
    //         flexDirection: "column",
    //         borderRightWidth: 1,
    //         borderRightColor: "#DBDBDB",
    //         marginRight: 0,
    //         alignItems: "center",
    //         justifyContent: "center",
    //       }}
    //     >
    //       <PrestoText
    //         extraStyle={{ textAlign: "center" }}
    //         color={textColor4}
    //         fontStyle="600.semibold"
    //       >
    //         {parseInt(item.stock_count) +
    //           (itemPurchaseRequest.purchase_quantity || quantity) *
    //           parseInt(itemPurchaseConfiguration?.sale_to_purchase_ratio)}
    //       </PrestoText>
    //       <PrestoText size={10} color={textColor4} fontStyle="400.normal">
    //         {itemPurchaseConfiguration?.sale_unit_display_name}
    //       </PrestoText>
    //     </View>
    //     <View
    //       style={{
    //         width: "11%",
    //         flexDirection: "column",
    //         borderRightWidth: 1,
    //         borderRightColor: "#DBDBDB",
    //         marginRight: 0,
    //         alignItems: "center",
    //         justifyContent: "center",
    //       }}
    //     >
    //       {isZipSupplied(itemPurchaseConfiguration) ? (
    //         <Image
    //           source={Images.ZipIcon}
    //           style={{ width: 30, height: 30, alignSelf: "center" }}
    //         />
    //       ) : (
    //         ""
    //       )}
    //     </View>

    //     <View
    //       style={{
    //         width: "11%",
    //         flex: 1,
    //         flexDirection: "row",
    //         justifyContent: "center",
    //         alignContent: "center",
    //         alignItems: "center",
    //         marginLeft: 15,
    //       }}
    //     >
    //       <View style={{ paddingRight: 0 }}>
    //         {hasPurchaseOrderManagementPermission ? (
    //           <PrestoFramedButton
    //             size="small"
    //             buttonStyle="primary"
    //             title={
    //               isAddedToBucket
    //                 ? I18n.t("screen_messages.common.remove")
    //                 : "Select"
    //             }
    //             titleColor={isAddedToBucket ? theme.white : theme.primary}
    //             titleFontStyle={"400.normal"}
    //             titleSize={11}
    //             height={29}
    //             width={isAddedToBucket ? 60 : 60}
    //             borderRadius={6}
    //             borderColor={isAddedToBucket ? theme.white : theme.primary}
    //             onPress={() =>
    //               //onPressActionButton({ item, itemPurchaseRequest })
    //               onSelectPurchaseRequestItem({ item, itemPurchaseRequest })
    //             }
    //           />
    //         ) : (
    //           <PrestoFramedButton
    //             size="small"
    //             buttonStyle="primary"
    //             title={I18n.t("screen_messages.common.delete")}
    //             titleColor={
    //               isActive || isAddedToBucket ? theme.white : theme.error
    //             }
    //             titleFontStyle={"400.normal"}
    //             titleSize={11}
    //             height={29}
    //             width={50}
    //             borderRadius={6}
    //             borderColor={
    //               isActive || isAddedToBucket ? theme.white : theme.error
    //             }
    //             onPress={() => onPressDelete({ item, itemPurchaseRequest })}
    //           />
    //         )}
    //       </View>
    //     </View>
    //   </Pressable>
    // );

    const onAmountChange = (quantity) => {
      if (quantity === "") {
        return;
      }
      //update quantity now
      let items = {};
      items.itemPurchaseRequest = itemPurchaseRequest;
      items.itemPurchaseConfiguration = itemPurchaseConfiguration;
      items.purchase_quantity = quantity;
      items.product = item;
      updateItemToPurchaseRequest({ item: { ...item, itemPurchaseRequest, itemPurchaseConfiguration, purchase_quantity: quantity } }, { quantity });
    };

    if (isMobile || isMobileWeb) {
      return (
        <ItemCard
          key={id}
          item={item}
          forPurchases={true}
          onPress={() => {}}
          onAmountChange={onAmountChange}
          onSubtract={onAmountChange}
          onPressAdd={() => addItemToPurchaseRequest({ item })}
          value={quantity}
          selected={isActive || isAddedToBucket}
          selectBtnProps={{
            selectBtnTitle,
            selectBtnColor,
          }}
          onPressSelectBtn={() => {
            if (hasPurchaseOrderManagementPermission) {
              onSelectPurchaseRequestItem({
                item,
                itemPurchaseRequest,
              });
            } else {
              onPressDelete({ item, itemPurchaseRequest });
            }
          }}
        ></ItemCard>
      );
    } else {
      return (
        <Pressable
          key={`${item.id}-${index}`}
          onPress={() => onPressRow(item)}
          style={{
            width: "100%",
            backgroundColor: bgColor,
            borderRadius: 12,
            paddingHorizontal: theme.primaryPadding,
            paddingVertical: 10,
            flexDirection: "row",
            marginBottom: 5,
          }}
        >
          <View
            style={{
              width: hasPurchaseOrderManagementPermission ? "23%" : "23%",
              flexDirection: "column",
              borderRightWidth: 1,
              borderRightColor: "#DBDBDB",
            }}
          >
            <PrestoText color={textColor2} fontStyle="400.normal">
              {name}
            </PrestoText>

            <PrestoText size={10} color={textColor4} fontStyle="400.normal">
              {molecules}
            </PrestoText>
          </View>

          <View
            style={{
              width: hasPurchaseOrderManagementPermission ? "11%" : "11%",
              flexDirection: "column",
              borderRightWidth: 1,
              borderRightColor: "#DBDBDB",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <PrestoText
              extraStyle={{ textAlign: "right" }}
              color={textColor4}
              fontStyle="600.semibold"
            >
              {item.stock_count}
            </PrestoText>
            <PrestoText size={10} color={textColor4} fontStyle="400.normal">
              {itemPurchaseConfiguration?.sale_unit_display_name}
            </PrestoText>
          </View>

          <View
            style={{
              width: "11%",
              flexDirection: "column",
              borderRightWidth: 1,
              borderRightColor: "#DBDBDB",
              marginRight: 0,
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <PrestoText
              extraStyle={{ textAlign: "center" }}
              color={textColor4}
              fontStyle="600.semibold"
            >
              {manufacturer}
            </PrestoText>
          </View>

          <View
            style={{
              width: "11%",
              flexDirection: "column",
              borderRightWidth: 1,
              borderRightColor: "#DBDBDB",
              marginRight: 0,
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <PrestoText
              extraStyle={{ textAlign: "center" }}
              color={textColor4}
              fontStyle="600.semibold"
            >
              {itemPurchaseConfiguration?.purchase_unit_display_name}
            </PrestoText>
            <PrestoText size={10} color={textColor4} fontStyle="400.normal">
              {I18n.t("screen_messages.common.contains")}{" "}
              {itemPurchaseConfiguration?.sale_to_purchase_ratio}{" "}
              {itemPurchaseConfiguration?.sale_unit_display_name}
            </PrestoText>
          </View>
          <View
            style={{
              width: hasPurchaseOrderManagementPermission ? "11%" : "11%",
              flexDirection: "column",
              borderRightWidth: 1,
              borderRightColor: "#DBDBDB",
              marginRight: 10,
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <PrestoAmountInputWithPlusMinus
              value={quantity}
              selected={isAddedToBucket}
              clearTextOnFocus={false}
              onAmountChange={onAmountChange}
              placeholder=""
              keyboardType="numeric"
            ></PrestoAmountInputWithPlusMinus>
          </View>

          <View
            style={{
              width: "11%",
              flexDirection: "column",
              borderRightWidth: 1,
              borderRightColor: "#DBDBDB",
              marginRight: 0,
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <PrestoText
              extraStyle={{ textAlign: "center" }}
              color={textColor4}
              fontStyle="600.semibold"
            >
              {parseInt(item.stock_count) +
                (quantity || 0) *
                  parseInt(itemPurchaseConfiguration?.sale_to_purchase_ratio)}
            </PrestoText>
            <PrestoText size={10} color={textColor4} fontStyle="400.normal">
              {itemPurchaseConfiguration?.sale_unit_display_name}
            </PrestoText>
          </View>
          <View
            style={{
              width: "11%",
              flexDirection: "column",
              borderRightWidth: 1,
              borderRightColor: "#DBDBDB",
              marginRight: 0,
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {isZipSupplied(itemPurchaseConfiguration) ? (
              <Image
                source={Images.ZipIcon}
                style={{ width: 30, height: 30, alignSelf: "center" }}
              />
            ) : (
              ""
            )}
          </View>

          <View
            style={{
              width: "11%",
              flex: 1,
              flexDirection: "row",
              justifyContent: "center",
              alignContent: "center",
              alignItems: "center",
              marginLeft: 15,
            }}
          >
            <View style={{ paddingRight: 0 }}>
              {hasPurchaseOrderManagementPermission ? (
                <PrestoFramedButton
                  size="small"
                  buttonStyle="primary"
                  title={
                    isAddedToBucket
                      ? I18n.t("screen_messages.common.remove")
                      : I18n.t("screen_messages.common.select")
                  }
                  titleColor={isAddedToBucket ? theme.white : theme.primary}
                  titleFontStyle={"400.normal"}
                  titleSize={11}
                  height={29}
                  width={isAddedToBucket ? 60 : 60}
                  borderRadius={6}
                  borderColor={isAddedToBucket ? theme.white : theme.primary}
                  onPress={() =>
                    //onPressActionButton({ item, itemPurchaseRequest })
                    onSelectPurchaseRequestItem({
                      item,
                      itemPurchaseRequest,
                    })
                  }
                />
              ) : (
                <PrestoFramedButton
                  size="small"
                  buttonStyle="primary"
                  title={I18n.t("screen_messages.common.delete")}
                  titleColor={
                    isActive || isAddedToBucket ? theme.white : theme.error
                  }
                  titleFontStyle={"400.normal"}
                  titleSize={11}
                  height={29}
                  width={50}
                  borderRadius={6}
                  borderColor={
                    isActive || isAddedToBucket ? theme.white : theme.error
                  }
                  onPress={() => onPressDelete({ item, itemPurchaseRequest })}
                />
              )}
            </View>
          </View>
        </Pressable>
      );
    }
  };

  const renderReceivedItem = ({
    item,
    itemPurchaseRequest,
    itemPurchaseConfiguration,
    index,
    allItemObj
  }) => {
    const product = new ItemCardModel({ product: item });
    let id = product.productId;
    let name = product.name;
    let molecules = _.get(item, "other_data.molecule");
    let quantity = itemPurchaseRequest.purchase_quantity;

    const supplierPrice = _.get(
      _.find(
        selectedItemPurchaseRequests,
        (e) => _.get(e, "product.id") == item.id
      ),
      "supplierPrice"
    );

    const isActive = _.get(activeRow, "id") === item.id;
    const isAddedToBucket = !_.isEmpty(supplierPrice);
    let bgColor = isActive ? "#6D707B" : theme.white;
    if (isAddedToBucket || item.purchase_quantity > 0) {
      bgColor = theme.primary;
    }
    const textColor2 =
      isActive || isAddedToBucket ? theme.white : theme.darkText;
    const textColor4 = isActive || isAddedToBucket ? theme.white : "#666666";

    const isZipSupplied = function (purchaseConfig) {
      let zipFound = false;
      purchaseConfig?.supplier_prices?.forEach((sp) => {
        if (
          sp.supplier_id ||
          sp.name?.indexOf("Zip") >= 0 ||
          sp.supplier_id == "64c8ff28179fb06bfbe3b6f9" ||
          sp.supplier_id == "65845fdd179fb0a4e0de327e" ||
          sp.supplier_id == "65cc70fa179fb01ce6ad8745" ||
          sp.supplier_id == "65cdd88d179fb03655ed2eea" ||
          sp.supplier_id == "65cddd19179fb03655ed4c75" ||
          sp.supplier_id == "65cd9e96179fb02ff0a255ef"
        ) {
          zipFound = true;
        }
      });
      return zipFound;
    };

    const updateItems = function (item) {
      console.log(item);
      console.log("----");
      let tempItems = _.cloneDeep(items);
      tempItems = _.filter(tempItems, (e) => e.product.id !== item.item.id);
      tempItems = _.concat(tempItems, {
        itemPurchaseConfiguration,
        product: item.item,
        itemPurchaseRequest: getItemPurchaseRequest(product),
      });
      setSelectedItemPurchaseRequests(tempItems, item);
      console.log(tempItems);
    };
    const onBatchNumberChange = function (item) {
      console.log(item);
      console.log("-------");
      item.item.batch_number = item.batch_number;
      updateItems(item);
    };

    const onPurchasePriceChange = function (item, purchase_price) {
      item.item.purchase_price =
        parseFloat(item.item.purchase_price_value) +
        parseFloat(item.item.purchase_tax_value || 0);
      console.log("Item ---- ");
      console.log(parseFloat(item.item.purchase_price_value));
      console.log(parseFloat(item.item.purchase_tax_value || 0));
      console.log(item);
      updateItems(item);
    };

    const onPurchaseQuantityChange = function (item, purchase_quantity) {
      item.item.purchase_quantity = item.purchase_quantity;
      updateItems(item);
    };

    const onExpiryDateChange = function (item, value) {
      console.log(item, "ijsdfiojasdiofjio");
      console.log(value);
      item.item.expiry = item?.item?.expiry;
      itemExpiryMap[item.item.id] = item.item.expiry || item.expiry;
      updateItems(item);
    };

    const onDateChange = (value) => {
      console.log(value, "oidsjfoiadjsiofjiodsjiof")
      setStockForm({
        ...stockForm,
        expiry: value.toISOString(),
      });
      item.expiry = value.toISOString();
      console.log("Item --- ");
      console.log(item);
      onExpiryDateChange({ item, value });
    };

    const onPressRowMobile = (item) => {
      Navigator.emit({
        event: "modal",
        params: {
          screenName: "NON_ZIP_PURCHASES",
          screenParams: {
            item: item,
            goBack: () => {
              Navigator.emit({ event: "goBack" });
            },
            onDateChange: onDateChange,
            onBatchNumberChange: (batch_number) => {
              item.batch_number = batch_number;
              onBatchNumberChange({ item, batch_number });
            },
            onPurchasePriceChange: (purchase_price) => {
              item.purchase_price_value = purchase_price;
              onPurchasePriceChange({ item, purchase_price });
            },
            onPurchaseTaxChange: (purchase_tax_value) => {
              item.purchase_tax_value = purchase_tax_value;
              onPurchasePriceChange({ item, purchase_tax_value });
            },
            onPurchaseQuantityChange: (purchase_quantity) => {
              item.purchase_quantity = purchase_quantity;
              onPurchaseQuantityChange({ item, purchase_quantity });
            },
            onPressButton: () => {
              Navigator.emit({ event: "goBack" });
              inwardSelected(items);
            },
          },
        },
      });
    };

    if (isMobile || isMobileWeb) {
      return (
        <ItemCard
          key={id}
          item={item}
          forMasterCatalog={true}
          forOrders={true}
          value={quantity}
          onPress={() => {
            onPressRow(item);
            onPressRowMobile(item);
          }}
          selected={isActive || isAddedToBucket}
          zipImage={Images.ZipIcon}
        ></ItemCard>
      );
    }

    return (
      <Pressable
        key={`${item.id}-${index}`}
        onPress={() => onPressRow(item)}
        style={{
          width: "100%",
          backgroundColor: bgColor,
          borderRadius: 12,
          paddingHorizontal: theme.primaryPadding,
          paddingVertical: 10,
          flexDirection: "row",
          marginBottom: 5,
        }}
      >
        <View
          style={{
            width: hasPurchaseOrderManagementPermission ? "15%" : "15%",
            flexDirection: "column",
            borderRightWidth: 1,
            borderRightColor: "#DBDBDB",
            marginRight: 10,
            paddingRight: 10,
          }}
        >
          <PrestoText color={textColor2} fontStyle="400.normal">
            {name}
          </PrestoText>

          <PrestoText size={10} color={textColor4} fontStyle="400.normal">
            {molecules}
          </PrestoText>
        </View>

        <View
          style={{
            width: "10%",
            flexDirection: "column",
            borderRightWidth: 1,
            borderRightColor: "#DBDBDB",
            marginRight: 0,
            alignItems: "center",
            justifyContent: "center",
            paddingRight: 20,
            marginRight: 0,
          }}
        >
          <View
            style={{
              flex: 1,
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "#fff",
            }}
          >
            {
              isWeb
                ? <YearMonthSelect onDateChange={onDateChange}></YearMonthSelect>
                : <YearMonthPicker onDateChange={onDateChange}></YearMonthPicker>
            }
          </View>
        </View>

        <View
          style={{
            width: "11%",
            flexDirection: "column",
            borderRightWidth: 1,
            borderRightColor: "#DBDBDB",
            marginRight: 0,
            alignItems: "center",
            justifyContent: "center",
            marginLeft: 10,
            paddingRight: 10,
          }}
        >
          <PrestoTextInput
            value={item.batch_number}
            clearTextOnFocus={false}
            onAmountChange={(batch_number) =>
              onBatchNumberChange({ item, batch_number })
            }
            onChange={({ nativeEvent: { text } }) => {
              let batch_number = text;
              onBatchNumberChange({ item, batch_number });
            }}
            style={{
              textInputHeight: 32,
              borderRadius: 6,
              marginLeft: 10,
            }}
            backgroundColor={theme.white}
            theme={theme}
            placeholder=""
            textInputStyle={{
              borderWidth: 1,
              borderRadius: 4,
              paddingLeft: 10,
              paddingVertical: 5,
              borderColor: "#aaaaaa",
              backgroundColor: "#ffffff",
            }}
          ></PrestoTextInput>
        </View>
        <View
          style={{
            width: hasPurchaseOrderManagementPermission ? "10%" : "10%",
            flexDirection: "column",
            borderRightWidth: 1,
            borderRightColor: "#DBDBDB",
            marginRight: 10,
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <PrestoAmountInput
            value={item.purchase_price_value}
            clearTextOnFocus={false}
            onAmountChange={(purchase_price) => {
              item.purchase_price_value = purchase_price;
              onPurchasePriceChange({ item, purchase_price });
            }}
            placeholder=""
            keyboardType="numeric"
            textInputStyle={{
              width: 100,
              height: 30,
              borderWidth: 1,
              borderRadius: 6,
              paddingLeft: 10,
              paddingVertical: 5,
              borderColor: "#cccccc",
              backgroundColor: "#ffffff",
            }}
          ></PrestoAmountInput>
        </View>

        <View
          style={{
            width: hasPurchaseOrderManagementPermission ? "10%" : "10%",
            flexDirection: "column",
            borderRightWidth: 1,
            borderRightColor: "#DBDBDB",
            marginRight: 10,
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <PrestoAmountInput
            value={item.purchase_tax_value}
            clearTextOnFocus={false}
            onAmountChange={(purchase_tax_value) => {
              item.purchase_tax_value = purchase_tax_value;
              onPurchasePriceChange({ item, purchase_tax_value });
            }}
            placeholder=""
            keyboardType="numeric"
            textInputStyle={{
              width: 100,
              height: 30,
              borderWidth: 1,
              borderRadius: 6,
              paddingLeft: 10,
              paddingVertical: 5,
              borderColor: "#cccccc",
              backgroundColor: "#ffffff",
            }}
          ></PrestoAmountInput>
        </View>

        <View
          style={{
            width: "10%",
            flexDirection: "column",
            borderRightWidth: 1,
            borderRightColor: "#DBDBDB",
            marginRight: 0,
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <PrestoText
            extraStyle={{ textAlign: "center" }}
            color={textColor4}
            fontStyle="600.semibold"
          >
            {quantity}
          </PrestoText>
        </View>

        <View
          style={{
            width: "10%",
            flexDirection: "column",
            borderRightWidth: 1,
            borderRightColor: "#DBDBDB",
            marginRight: 0,
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <PrestoAmountInput
            value={item.purchase_quantity}
            clearTextOnFocus={false}
            onAmountChange={(purchase_quantity) =>
              onPurchaseQuantityChange({ item, purchase_quantity })
            }
            textInputStyle={{
              width: 100,
              height: 30,
              borderWidth: 1,
              borderRadius: 6,
              paddingLeft: 10,
              paddingVertical: 5,
              borderColor: "#cccccc",
              backgroundColor: "#ffffff",
            }}
            placeholder=""
            keyboardType="numeric"
          ></PrestoAmountInput>
        </View>

        <View
          style={{
            width: "10%",

            flexDirection: "row",
            justifyContent: "center",
            alignContent: "center",
            alignItems: "center",
          }}
        >
          {isZipSupplied(itemPurchaseConfiguration) ? (
            <Image
              source={Images.ZipIcon}
              style={{ width: 30, height: 30, alignSelf: "center" }}
            />
          ) : (
            ""
          )}
        </View>
        <View
          style={{
            width: "10%",
            flexDirection: "column",
            borderRightWidth: 1,
            borderColor: "#DBDBDB",
            borderLeftWidth: 1,
            marginRight: 0,
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <PrestoSolidButton
            size="extra-small"
            buttonStyle="secondary"
            titleColor={theme.white}
            disable={loading}
            title={I18n.t("screen_messages.common.inward_selected")}
            paddingLeft="20"
            titleExtraStyle={{ textTransform: "capitalize" }}
            onPress={() => {
              inwardSelected([allItemObj]);
            }}
          />
        </View>
      </Pressable>
    );
  };

  return (
    <View style={{ width: "100%" }}>
      {isIncomingScreen ? (
        <View
          style={{
            height: "92%",
          }}
        >
          {(isMobile || isMobileWeb) ? null : (
            <View
              style={{
                paddingTop: 30,
                flexDirection: "row",
                justifyContent: "flex-end",
              }}
            >
              <View
                style={{
                  width: 200,
                  marginBottom: 20,
                  marginRight: 20,
                  paddingHorizontal: 0,
                }}
              >
                <PrestoSolidButton
                  size="extra-large"
                  buttonStyle="secondary"
                  titleColor={theme.white}
                  title={I18n.t("screen_messages.common.add_new")}
                  paddingLeft="20"
                  titleExtraStyle={{ textTransform: "capitalize" }}
                  onPress={() => {
                    openAddNewModal();
                  }}
                />
              </View>
              <View
                style={{
                  width: isWeb ? "auto" : 200,
                  marginBottom: 20,
                  marginRight: 20,
                  paddingHorizontal: 0,
                }}
              >
                <PrestoSolidButton
                  size="extra-large"
                  buttonStyle="secondary"
                  extraStyle={{ paddingHorizontal: isWeb ? 20 : 0 }}
                  titleColor={theme.white}
                  disable={loading}
                  title={I18n.t("screen_messages.common.inward_selected")}
                  paddingLeft="20"
                  titleExtraStyle={{ textTransform: "capitalize" }}
                  onPress={() => {
                    inwardSelected(items);
                  }}
                />
              </View>
            </View>
          )}
          <View style={{ ...(isWeb && { height: "80%" }) }}>
            <FlatList
              stickyHeaderIndices={[0]}
              keyExtractor={(item, index) => `${item.id}-${index}`}
              data={items}
              renderItem={({ item, index }) =>
                renderReceivedItem({
                  item: item.product,
                  itemPurchaseRequest: item.itemPurchaseRequest,
                  itemPurchaseConfiguration: item.itemPurchaseConfiguration,
                  index,
                  allItemObj: item,
                })
              }
              ListHeaderComponent={() => {
                if (isMobile || isMobileWeb) return;
                return <RenderIncomingItemHeader />;
              }}
              onEndReachedThreshold={0.0}
              onEndReached={onEndReached}
            />
          </View>
          {isMobile || isMobileWeb ? (
            <View
              style={{
                width: "100%",
                position: "absolute",
                bottom: 0,
                paddingBottom: 20,
              }}
            >
              <PrestoSolidButton
                size="large"
                buttonStyle="primary"
                titleColor={theme.white}
                title={I18n.t("screen_messages.common.add_new")}
                height={35}
                width={"95%"}
                extraStyle={{ alignSelf: "center" }}
                titleExtraStyle={{ textTransform: "capitalize" }}
                onPress={() => {
                  openAddNewModal();
                }}
              />
            </View>
          ) : null}
          {_.isEmpty(items) ? (
            <View
              style={
                isMobile || isMobileWeb
                  ? { position: "absolute", top: 20, alignSelf: "center" }
                  : {}
              }
            >
              <PrestoText
                extraStyle={{
                  textAlign: "center",
                  width: "100%",
                }}
                color={theme.darkText}
                fontStyle="600.semibold"
              >
                {I18n.t("screen_messages.purchase_requests.no_open_requests")}
              </PrestoText>
            </View>
          ) : null}
          <View>{modalVisible && renderModal()}</View>
        </View>
      ) : (
        <View
          style={{
            height: "100%",
          }}
        >
          <View
            style={{
              paddingTop: isMobile || isMobileWeb ? 0 : 30,
              flexDirection: isMobile || isMobileWeb ? "column" : "row",
              justifyContent: "flex-end",
            }}
          >
            <View
              style={{
                flexDirection: "row",
                justifyContent: isMobile || isMobileWeb ? "center" : "flex-end",
                paddingRight: 30,
              }}
            >
              <View
                style={{
                  flexDirection: "column",
                  justifyContent: "flex-end",
                  paddingRight: 30,
                }}
              >
                <CheckBoxWithText
                  state={selectAll}
                  theme={theme}
                  text={I18n.t("screen_messages.orders.select_all_non_zip")}
                  fontColor={theme.black}
                  fontStyle={"400.regular"}
                  checkBoxUnSelectedColor={theme.primary}
                  onPress={(state) => {
                    if (!state) {
                      onSelectAll(state, items);
                      //Remove from selected items
                      let tempItems = [];
                      _.map(
                        selectedItemPurchaseRequests,
                        (selectedItemPurchaseRequest) => {
                          if (
                            selectedItemPurchaseRequest.product.other_data
                              .zip_supplied == 1 ||
                            selectedItemPurchaseRequest.product.other_data
                              .zip_supplied == "1"
                          ) {
                            tempItems.push(selectedItemPurchaseRequest);
                          }
                        }
                      );
                      setSelectedItemPurchaseRequests(tempItems);
                      return;
                    }
                    onSelectAll(state, items);
                    /*if (state)
                    {
                      _.map(items, (item, index) => {
                        onSelectPurchaseRequestItem({ item: item.product });
                      });
                    }*/
                    let tempItems = [];
                    _.map(
                      selectedItemPurchaseRequests,
                      (selectedItemPurchaseRequest) => {
                        if (
                          selectedItemPurchaseRequest.product.other_data
                            .zip_supplied == 1 ||
                          selectedItemPurchaseRequest.product.other_data
                            .zip_supplied == "1"
                        ) {
                          tempItems.push(selectedItemPurchaseRequest);
                        }
                      }
                    );
                    _.map(items, (itemObject, index) => {
                      let itemPurchaseConfiguration =
                        itemObject.itemPurchaseConfiguration;
                      let product = itemObject.product;
                      let supplierPrices = _.get(
                        itemPurchaseConfiguration,
                        "supplier_prices",
                        []
                      );
                      let options = _.map(supplierPrices, (supplierPrice) => {
                        return {
                          label: `${supplierPrice.name} - ${supplierPrice.purchase_price}`,
                          supplierPrice,
                        };
                      });
                      let supplierPrice = options[0];
                      let zipSupplied = false;
                      options.forEach((opt) => {
                        if (
                          opt.supplierPrice.name?.indexOf("Zip") >= 0 ||
                          opt.supplierPrice.supplier_id ==
                            "64c8ff28179fb06bfbe3b6f9" ||
                          opt.supplierPrice.supplier_id ==
                            "65845fdd179fb0a4e0de327e"
                        ) {
                          supplierPrice = opt;
                          zipSupplied = true;
                        }
                      });
                      console.log("Setting for -- " + index);
                      console.log(zipSupplied);
                      console.log(product.id + "----");
                      let isSelected = isProductSelected(product);
                      if (!zipSupplied) {
                        tempItems = isSelected
                          ? _.filter(
                              tempItems,
                              (e) => e.product.id !== product.id
                            )
                          : _.concat(tempItems, {
                              supplierPrice: supplierPrice?.supplierPrice,
                              itemPurchaseConfiguration,
                              product,
                              itemPurchaseRequest: getItemPurchaseRequest(
                                product
                              ),
                            });
                      }
                      console.log(tempItems);
                      console.log("-----");
                    });
                    setSelectedItemPurchaseRequests(tempItems);
                  }}
                  paddingHorizontal={theme.containerPadding}
                  defaultTickedColor={theme.primary}
                  reverse={true}
                  checkBoxSize={10}
                  containerStyles={{
                    paddingHorizontal: 0,
                    border: 0,
                  }}
                />
              </View>
              <View
                style={{
                  flexDirection: "column",
                  justifyContent: "flex-end",
                }}
              >
                <CheckBoxWithText
                  state={selectZip}
                  theme={theme}
                  text={I18n.t("screen_messages.orders.select_all_zip")}
                  fontColor={theme.black}
                  fontStyle={"400.regular"}
                  checkBoxUnSelectedColor={theme.primary}
                  onPress={(state) => {
                    console.log(state);
                    console.log("----- IIIPPP -----");
                    if (!state) {
                      onSelectZip(state, items);
                      let tempItems = [];
                      _.map(
                        selectedItemPurchaseRequests,
                        (selectedItemPurchaseRequest) => {
                          if (
                            selectedItemPurchaseRequest.product.other_data
                              .zip_supplied != 1 &&
                            selectedItemPurchaseRequest.product.other_data
                              .zip_supplied != "1"
                          ) {
                            tempItems.push(selectedItemPurchaseRequest);
                          }
                        }
                      );
                      setSelectedItemPurchaseRequests(tempItems);
                      return;
                    }

                    if (items?.length) {
                      setParentLoading(true);
                    }
                    let tempItems = []; //_.cloneDeep(selectedItemPurchaseRequests);
                    _.map(
                      selectedItemPurchaseRequests,
                      (selectedItemPurchaseRequest) => {
                        if (
                          selectedItemPurchaseRequest.product.other_data
                            .zip_supplied != 1 &&
                          selectedItemPurchaseRequest.product.other_data
                            .zip_supplied != "1"
                        ) {
                          tempItems.push(selectedItemPurchaseRequest);
                        }
                      }
                    );
                    let zipSuppliedItems = [];
                    _.map(items, (itemObject, index) => {
                      let product = itemObject.product;
                      if (
                        product.other_data.zip_supplied == 1 ||
                        product.other_data.zip_supplied == "1"
                      ) {
                        zipSuppliedItems.push(itemObject);
                      }
                    });

                    const finalCount = zipSuppliedItems.length;
                    let completedCount = 0;
                    _.map(zipSuppliedItems, (itemObject, index) => {
                      if (
                        parseInt(
                          itemObject.itemPurchaseRequest.purchase_quantity
                        ) != 0
                      ) {
                        let item = itemObject.product;
                        let product = itemObject.product;
                        AsyncEmployeeManager.itemPurchaseConfigurationShow({
                          id: item.id,
                        }).then(([error, response]) => {
                          let itemPurchaseConfiguration = _.get(
                            response,
                            "data",
                            null
                          );
                          let supplierPrices = _.get(
                            itemPurchaseConfiguration,
                            "supplier_prices",
                            []
                          );
                          if (supplierPrices) {
                            let options = _.map(
                              supplierPrices,
                              (supplierPrice) => {
                                return {
                                  label: `${supplierPrice.name} - ${supplierPrice.purchase_price}`,
                                  supplierPrice,
                                };
                              }
                            );
                            let supplierPrice = options[0];
                            options.forEach((opt) => {
                              if (
                                opt.supplierPrice.name?.indexOf("Zip") >= 0 ||
                                opt.supplierPrice.supplier_id ==
                                  "64c8ff28179fb06bfbe3b6f9" ||
                                opt.supplierPrice.supplier_id ==
                                  "65845fdd179fb0a4e0de327e"
                              ) {
                                supplierPrice = opt;
                              }
                            });
                            tempItems = _.concat(tempItems, {
                              supplierPrice: supplierPrice?.supplierPrice,
                              itemPurchaseConfiguration,
                              product,
                              itemPurchaseRequest: getItemPurchaseRequest(
                                product
                              ),
                            });
                            console.log(
                              supplierPrice,
                              "supplierPrice#####################"
                            );
                          }
                          completedCount += 1;
                          if (completedCount >= finalCount - 1) {
                            setSelectedItemPurchaseRequests(tempItems);
                            setTimeout(function () {
                              onSelectZip(state, items);
                              setParentLoading(false);
                            }, 500);
                          }
                        });
                      }
                    });
                  }}
                  paddingHorizontal={theme.containerPadding}
                  defaultTickedColor={theme.primary}
                  reverse={true}
                  checkBoxSize={10}
                  containerStyles={{
                    paddingHorizontal: 0,
                    border: 0,
                  }}
                />
              </View>
            </View>
            <View
              style={{
                flexDirection: "row",
                justifyContent: "space-between",
                paddingBottom: isMobile || isMobileWeb ? 10 : 0,
              }}
            >
              <View
                style={{
                  width: isMobile || isMobileWeb ? "49%" : 200,
                }}
              >
                <PrestoSolidButton
                  size={isMobile || isMobileWeb ? "large" : "extra-large"}
                  titleSize={16}
                  buttonStyle="secondary"
                  titleColor={theme.white}
                  title="Download"
                  paddingLeft="20"
                  titleExtraStyle={{ textTransform: "capitalize" }}
                  onPress={() => {
                    onPressDownload(selectedItemPurchaseRequests);
                  }}
                />
              </View>
              <View
                style={{
                  width: isMobile || isMobileWeb ? "49%" : 300,
                  paddingLeft: isMobile || isMobileWeb ? 0 : 5,
                }}
              >
                <PrestoSolidButton
                  size={(isMobile || isMobileWeb) ? "large" : "extra-large"}
                  titleSize={15}
                  buttonStyle="primary"
                  titleColor={theme.white}
                  title={I18n.t("screen_messages.orders.send_selected_to_zip")}
                  titleExtraStyle={{ textTransform: "capitalize" }}
                  onPress={() => {
                    sendSelectedToZip(selectedItemPurchaseRequests);
                  }}
                />
              </View>
            </View>
          </View>
          <View style={{ ...(isWeb && { height: "80%" }) }}>
            <FlatList
              stickyHeaderIndices={[0]}
              keyExtractor={(item, index) => `${item.id}-${index}`}
              data={items}
              renderItem={({ item, index }) =>
                renderItem({
                  item: item.product,
                  itemPurchaseRequest: item.itemPurchaseRequest,
                  itemPurchaseConfiguration: item.itemPurchaseConfiguration,
                  index,
                })
              }
              ListHeaderComponent={() => {
                if (isMobile || isMobileWeb) return;
                return <RenderItemHeader />;
              }}
              onEndReachedThreshold={0.0}
              onEndReached={onEndReached}
            />
          </View>

          {_.isEmpty(items) ? (
            <View
              style={{
                display: "flex",
                height: "100%",
                alignItems: "center",
                justifyContent: "flex-start",
                marginTop: 40,
              }}
            >
              <PrestoText
                extraStyle={{
                  textAlign: "center",
                  width: "100%",
                }}
                color={theme.darkText}
                fontStyle="600.semibold"
              >
                {I18n.t("screen_messages.purchase_requests.no_open_requests")}
              </PrestoText>
            </View>
          ) : null}
          <View
            style={{
              width: "100%",
              flexDirection: "row",
              alignItems: "flex-end",
            }}
          >
            <View
              style={{
                width: 350,
                flex: 1,
                alignItems: "flex-end",
              }}
            >
              <PrestoSolidButton
                size="extra-large"
                buttonStyle="secondary"
                titleColor={theme.white}
                title={I18n.t("screen_messages.common.clear_all")}
                paddingLeft="20"
                titleExtraStyle={{
                  textTransform: "capitalize",
                  paddingLeft: 20,
                  paddingRight: 20,
                }}
                onPress={() => {
                  clearItemPurchaseRequests();
                }}
              />
            </View>
          </View>
        </View>
      )}
    </View>
  );
}
function getStyles(theme) {
  return {
    container: {},
    modalContainer: {
      alignSelf: "center",
      padding: 20,
      justifyContent: "center",
      borderTopLeftRadius: 20,
      borderTopRightRadius: 20,
      borderBottomRightRadius: 20,
      borderBottomLeftRadius: 20,
    },
  };
}
